import { Typography } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import SouthProp from './SP';
// import CreatSidebar from "./AppSidbar";
// import BuildBootStrapMenu from "../DisplayMenu";
// import * as Icon from 'react-bootstrap-icons';
//import DisplayQuetions from "../components/HomepageFeatures/DisplayText";
import LocAnswerFooter from './AnswersFooter';
import LocPageFooter from './PageFooter';
//import { LocalGroceryStore } from "@mui/icons-material";
import { Link } from 'react-router-dom';
import LocGoogleMap from './LocGoogleMap';

function LocAnswersAnnex() {
	// <BuildBootStrapMenu />
	const FacList = [
		{
			id: 'div5',
			quest: 'How many floors?',
			answer: 'Seven levels, including the cellar and the subcellar'
		},
		{
			id: 'div18',
			quest: 'Does the garage have a passenger elevator?',
			answer:
				'Not within the garage. Elevators are accessed from the Annex building.'
		},
		{
			id: 'div18',
			quest: 'What do I need to access the garage?',
			answer:
				'Cars will be issued an adhesive tag that should be affixed to the rear-view mirror. Garage has license plate recognition technology that identifies account based on license plate capture.'
		},
		{
			id: 'div18',
			quest: 'How are the parking floors accessed?',
			answer: 'The floors are ramp accessible. No elevators.'
		},
		{
			id: 'div6',
			quest: 'Does the garage have gates at entrances and exits?',
			answer: 'Yes. Garage uses License Plate Recognition and RFID.'
		},
		{
			id: 'div21',
			quest: 'Does the garage have car stackers or lifts?',
			answer: 'No lifts or stackers.'
		},
		{
			id: 'div9',
			quest: 'How do I retrieve my car?',
			answer:
				'If you self-parked your car and kept the keys, you can retrieve your own car. If the parking staff valet your car, please the parking staff before attempting to locate your car.'
		},
		{
			id: 'div10',
			quest: 'Can I park my own car, or do I have to valet?',
			answer:
				'If self-parking spaces are available, you can self-park your car and take the keys. If all self-park spaces are occupied, valet service will be offered on certain levels.'
		},
		{
			id: 'div10',
			quest: 'Can I choose where I park?',
			answer:
				"If self-parking spaces aren't available, valet staff will choose where to park your car."
		},
		{
			id: 'div11',
			quest: 'Can I have more than one car at the same time in the garage?',
			answer: 'No, you can only park one car at a time.'
		},
		{
			id: 'div8',
			quest: 'Who issues the RFID tag?',
			answer: 'RFID tags (access tags) are issued by the Parking Office.'
		}
	];

	const opsList = [
		{
			id: 'div12',
			quest: 'Can I have more than one access tag (RFID tag)?',
			answer:
				'Yes. No more than 2 tags can be issued. Please contact the parking office for more details?'
		},
		{
			id: 'div13',
			quest:
				'What if I lose my RFID tag, change my car or the tag stops working?',
			answer:
				'Contact the parking office to request a new access tag. There is a charge for a tag replacement.'
		},
		{
			id: 'div14',
			quest:
				'Can I store my car in the garage (storage parking is parking a car in the garage for more than 24 hours)?',
			answer:
				'NYP Housing buildings residents who have storage monthly accounts are permitted to store their cars. Commuters must exit the garage after their work shift ends.'
		},
		{
			id: 'div15',
			quest: 'Can I apply for parking if I live in neighboring zip codes?',
			answer:
				'No. Employees who reside in buildings located in 10103, 10020, 10112, 10111,  10152, 10154, 10029, 10128, 10028, 10075, 10162, 10021, 10022, 10065 and 10153 zip codes are not allowed to park in NYP parking garages.'
		},
		{
			id: 'div17',
			quest: 'What do I do when my RFID tag (access tag) does not work?',
			answer:
				'If your tag is valid and your account in good standing, the staff will issue you a replacement ticket.'
		},
		{
			id: 'div16',
			quest: 'Is there space available for new monthly accounts?',
			answer:
				'No. Currently we have a waiting list and the average wait is over 1 year. You must apply for parking to be added to the waiting list,'
		},
		{
			id: 'div18',
			quest: "Can I park if I don't have a monthly account?",
			answer: 'Yes. You must take a ticket. Transient daily charges will apply.'
		},
		{
			id: 'div19',
			quest: 'Does the garage accept SUVs and small vans?',
			answer:
				'Yes, but there is height restriction. Please remove all racks from your car’s roof before entering the garage.'
		},
		{
			id: 'div20',
			quest: 'Does the garage accept shuttle size vans?',
			answer:
				'No. Large vans cannot be accommodated due to height limits and parking space sizes.'
		},
		{
			id: 'div22',
			quest: 'Do you have discounted daily rates for employees?',
			answer:
				'No. Employees who do not have an active monthly account cannot access the garage.'
		}
	];
	// const [isShown, setIsShown] = useState(false, { id: 'divOne' });
	// const hideShowDiv = (e) => {
	//     setIsShown({ id: e.target.id }, !isShown);
	//     divName = e.target.id;
	//     console.log (divName);

	//  };
	const FinalList = FacList.map((targetDivs, divId) => (
		<SouthProp key={targetDivs.id} targetDivs={targetDivs} divId={divId} />
	));
	const OperationList = opsList.map((targetDivs) => (
		<SouthProp key={targetDivs.id} targetDivs={targetDivs} />
	));

	var targetLat = 40.76554249303864;
	var targetLng = -73.95372690268916;

	return (
		<div style={{ backgroundColor: 'white' }} id="topDiv">
			<div className="container-fluid">
				<div>
					<Typography
						variant="h6"
						style={{ backgroundColor: 'white', textAlign: 'start' }}
						sx={{ marginTop: '2px', paddingTop: '2px', paddingBottom: '5px' }}
					>
						Click <Link to="/CornellAnswersList">here</Link> for information
						that applies to all Cornell Campus parking facilities.
					</Typography>
				</div>
				<div className="row">
					<div>
						<LocGoogleMap locLat={targetLat} locLng={targetLng} />
					</div>
					<div>
						<table
							className="table table-striped table-bordered"
							style={{ border: 'ridge', marginLeft: '-0px' }}
						>
							<thead>
								<tr>
									<th colSpan="2" id="thID">
										<span style={{ color: 'blue' }}>
											{' '}
											Annex Garage - 519 East 70th Street New York NY 10021
										</span>
									</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td
										className="firsttd text-start"
										style={{ fontWeight: 'bold' }}
									>
										Garage Address:
									</td>
									<td className="text-start">
										<span style={{ color: 'blue' }}>
											{' '}
											519 East 70th Street New York NY 10021
										</span>
									</td>
								</tr>
								<tr>
									<td className="text-start" style={{ fontWeight: 'bold' }}>
										Garage Name:
									</td>
									<td className="text-start">
										<span style={{ color: 'blue' }}>
											Annex Buidling Garage{' '}
										</span>
									</td>
								</tr>
								<tr>
									<td className="text-start" style={{ fontWeight: 'bold' }}>
										Also Known As:
									</td>
									<td className="text-start">
										<span style={{ color: 'blue' }}>Annex Garage</span>
									</td>
								</tr>
								<tr>
									<td className="text-start" style={{ fontWeight: 'bold' }}>
										License:
									</td>
									<td className="text-start">
										<span style={{ color: 'blue' }}>
											Employees only. No public parking allowed.
										</span>
									</td>
								</tr>
								<tr>
									<td className="text-start" style={{ fontWeight: 'bold' }}>
										Hours:
									</td>
									<td className="text-start">
										<span style={{ color: 'blue' }}>
											24/7 accessible. Attnded up to 10PM daily.
										</span>
									</td>
								</tr>
								<tr>
									<td className="text-start" style={{ fontWeight: 'bold' }}>
										Entrance:
									</td>
									<td className="text-start">
										<span style={{ color: 'blue' }}>
											Entrance on 70th Street between York and FDR Drive.
										</span>
									</td>
								</tr>
								<tr>
									<td className="text-start" style={{ fontWeight: 'bold' }}>
										Exits:
									</td>
									<td className="text-start">
										<span style={{ color: 'blue' }}>
											Exit on 70th Street between York and FDR Drive..
										</span>
									</td>
								</tr>
								<tr>
									<td className="text-start" style={{ fontWeight: 'bold' }}>
										Contact:
									</td>
									<td className="text-start">
										<span style={{ color: 'blue' }}>(212) 746-1978</span>
									</td>
								</tr>
								<tr>
									<td className="text-start" style={{ fontWeight: 'bold' }}>
										Operation Type:
									</td>
									<td className="text-start">
										<span style={{ color: 'blue' }}>Self-Park and Valet.</span>
									</td>
								</tr>
							</tbody>
						</table>
						<LocPageFooter list1={FinalList} />
						<LocAnswerFooter list1={OperationList} />
					</div>
				</div>
			</div>
		</div>
	);
}

export default LocAnswersAnnex;
