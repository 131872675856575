import React from "react";
import { GoogleMap,useLoadScript,Marker} from '@react-google-maps/api'
// import { useMemo } from "react";

function LocGoogleMap(props) {
   const center = {lat: props.locLat, lng: props.locLng};
  // return (
  //   <div>
  //     {/* <Map
  //       apiKey="top secret"
  //     /> */}
  //   </div>
  // );
  
// key is in the .env file. Key is assigned to the isLoadded variable
//For key to be read, prefix with REACT_APP then key or url 
  const { isLoaded } = useLoadScript({
     googleMapsApiKey: process.env.REACT_APP_SECRET
    
  });
  if(!isLoaded){
    return (
    <div>
      Loading ...
    </div>
    )
  }
  
  return (
    //  <LocationMap  latitude={props.LocLat} longtitue={props.locLng}/>
    <GoogleMap zoom={15} center={center} mapContainerClassName="mapContainer">
      <Marker position={center} />
    </GoogleMap>
  );
}

// function LocationMap(latitude,longtitue) {
//   const center = useMemo(() => ({ lat: {latitude}, lng: {longtitue} }), []);

//   return (
//     <GoogleMap zoom={17} center={center} mapContainerClassName="mapContainer">
//       <Marker position={center} />
//     </GoogleMap>
//   );
// }
export default LocGoogleMap;