import CardTemp from "./CardTemplate";
import allenTabStyle from "../css/policiesStyle.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faParking } from "@fortawesome/free-solid-svg-icons";
library.add(faParking);

function AllenTabContent() {
  return (
    <div className={allenTabStyle.cardsDiv}>
      <div className={allenTabStyle.homeCard}>
        <CardTemp
          cardLink="/AllenAnswersList"
          cardIcon={<FontAwesomeIcon icon={faParking} />}
          imgSrc={require("../images/Allen.jpg")}
          imgTitle=" Allen Hospital Employees Lot"
          def1=<span className={allenTabStyle.defSpan}>
            <span>Address: </span>
            <span style={{ color: "blue" }}>
              4168 9th Avenue New York NY 10034
            </span>
          </span>
          def2=<span className={allenTabStyle.defSpan}>
            <span> Hours: </span>
            <span style={{ color: "blue" }}>24 Hours 7 Days</span>
          </span>
          def3=<span className={allenTabStyle.defSpan}>
            <span>Parking: </span>
            <span style={{ color: "blue" }}>Valet and Self-Park</span>
          </span>
        />
      </div>

      <div className={allenTabStyle.homeCard}>
        <CardTemp
          cardLink="/CrescentAnswersList"
          cardIcon={<FontAwesomeIcon icon={faParking} />}
          imgSrc={require("../images/Crescent.jpg")}
          imgTitle=" Allen Hospital Crescent Lot"
          def1=<span className={allenTabStyle.defSpan}>
            <span>Address: </span>
            <span style={{ color: "blue" }}>
              4141 9th Avenue New York NY 10034
            </span>
          </span>
          def2=<span className={allenTabStyle.defSpan}>
            <span>Hours: </span>
            <span style={{ color: "blue" }}>24 Hours Accessible</span>
          </span>
          def3=<span className={allenTabStyle.defSpan}>
            <span>Parking: </span>
            <span style={{ color: "blue" }}>Valet and Self-Park</span>
          </span>
        />
      </div>
    </div>
  );
}
export default AllenTabContent;
