// import logo from './logo.svg';
import React from 'react';
import './css/App.css';
// import styles from './styles.module.css';
import 'bootstrap/dist/css/bootstrap.css';
// import {Typography } from '@mui/material';
// import { useState } from 'react';
// import GarageIcon from '@mui/icons-material/Garage';
// import PolicyIcon from '@mui/icons-material/Policy';
// import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp';
// import RemoveCircleSharpIcon from '@mui/icons-material/RemoveCircleSharp';
// import Stack from '@mui/material/Stack';
// import UpdateIcon from '@mui/icons-material/Update';
// import RuleIcon from '@mui/icons-material/Rule';
// import AddBusinessIcon from '@mui/icons-material/AddBusiness';
// import ReceiptLongTwoToneIcon from '@mui/icons-material/ReceiptLongTwoTone';
// import AddIcCallTwoToneIcon from '@mui/icons-material/AddIcCallTwoTone';
// import LocalParkingSharpIcon from '@mui/icons-material/LocalParkingSharp';
// import DatasetIcon from '@mui/icons-material/Dataset';
import { Route, Routes } from 'react-router-dom';
import ColumbiaTabContent from './pages/ColumbiaLocTab';
import NestedNavBar from './pages/ColumbiaNav';
import CornellTabContent from './pages/CornellLocTab';
import MethodistTabContent from './pages/MethodistLocTab';
// import BuildBootStrapMenu from './DisplayMenu';
import LocAnswers from './AnswersList';
import LocAnswers160 from './pages/160Answers';
import LocAnswers166 from './pages/166Answers';
import LocAnswersAriLot from './pages/469WAnswersList';
import LocAnswersAllen from './pages/AllenAnswersList';
import AllenTabContent from './pages/AllenLocTab';
import AltParkingTabContent from './pages/AltParkingTab';
import LocAnswersAnnex from './pages/AnnexAnswersList';
import Applications from './pages/Applications';
import LocAnswersCCH from './pages/CCHAnswersList';
import CreateCarousel from './pages/Carousel';
import LocAnswersColeman from './pages/ColemanAnswersList';
import ColumbiaBasicRateTable from './pages/ColumbiaRateTable';
import LocAnswersCornell from './pages/CornellAnswersList';
import CornellBasicRateTable from './pages/CornellRateTable';
import LocAnswersCrescent from './pages/CrescentAnswersList';
import LocAnswersDHK from './pages/DHKAnswersList';
import LocAnswersDoctors from './pages/Doctors';
import LocAnswersGreenberg from './pages/GreenbergAnswersList';
import LocAnswersHelmsley from './pages/HelmsleyAnswersList';
import MainTabContent from './pages/HomeCards';
import LocAnswersMethodist from './pages/MethodistAnswersList';
import MethodistBasicRateTable from './pages/MethodistlRateTable';
import CalcUI from './pages/ParkingCost';
import LocAnswersPayson from './pages/PaysonAnswersList';
import GeneralRules from './pages/Policies';
import RateStructure from './pages/RateCalculator';
import LocAnswersSixSt from './pages/SixStAnswersList';
import LocAnswersSpruce from './pages/SpruceAnswersList';
import SpruceTabContent from './pages/SpruceLocTab';
import MainTable from './pages/TopInfo';
//import DisplayRateTables from './pages/LocationsRateTable';
import './css/mainAppStyle.module.css';
import AllenBasicRateTable from './pages/AllenRateTable';
import LowerManhattanBasicTable from './pages/LowerManhattanRateTable';
import BasicTable from './pages/MainRateTable';
import ProrateForm from './pages/MonthlyProrateForm';
import RatesNavBar from './pages/NestedNav';
// import TopPageSlider from './pages/TopPageSlider';
// import LocGoogleMap from "./pages/LocGoogleMap";

function App() {
	return (
		// <BrowserRouter>

		<div className="mainDiv">
			{/* <CreateButtons /> */}
			<div style={{ margin: '1rem auto' }}>
				<CreateCarousel />
				{/* <TopPageSlider /> */}
			</div>
			{/* <div style={{marginTop:'20px'}}>
        <LocGoogleMap />
      </div> */}
			<div>
				<NestedNavBar />
			</div>
			{/* <ol>
          <li>
              All NYP employees are eligible to apply for a parking monthly account. Campuses may have a waiting list.
          </li>
          <li>
              The NYP Parking Department has a "One Employee One Account" policy, 
              which means that only the registered owner of the account can park in the garage.
          </li>
          <li>
              Contractors, travel nurses and vendors must be approved by the Parking Operation 
              Director before applying for a parking account.
          </li>
          <li>
              It is the employee's responsibility to notify the Parking Office staff if the 
              employee decides to cancel the account.
          </li>
      </ol> */}
			<Routes>
				<Route path="/" element={<MainTabContent />}></Route>
				<Route path="ColumbiaLocTab" element={<ColumbiaTabContent />}></Route>
				<Route path="AllenLocTab" element={<AllenTabContent />}></Route>
				<Route path="CornellLocTab" element={<CornellTabContent />}></Route>
				<Route path="MethodistLocTab" element={<MethodistTabContent />}></Route>
				<Route path="SpruceLocTab" element={<SpruceTabContent />}></Route>
				<Route path="applications" element={<Applications />}></Route>
				<Route path="AnswersList" element={<LocAnswers />}></Route>
				<Route path="160Answers" element={<LocAnswers160 />}></Route>
				<Route path="166Answers" element={<LocAnswers166 />}></Route>
				<Route path="Doctors" element={<LocAnswersDoctors />}></Route>
				<Route path="AllenAnswersList" element={<LocAnswersAllen />}></Route>
				<Route
					path="CrescentAnswersList"
					element={<LocAnswersCrescent />}
				></Route>
				<Route path="469WAnswersList" element={<LocAnswersAriLot />}></Route>
				<Route path="AnnexAnswersList" element={<LocAnswersAnnex />}></Route>
				<Route
					path="ColemanAnswersList"
					element={<LocAnswersColeman />}
				></Route>
				<Route
					path="CornellAnswersList"
					element={<LocAnswersCornell />}
				></Route>
				<Route path="DHKAnswersList" element={<LocAnswersDHK />}></Route>
				<Route
					path="GreenbergAnswersList"
					element={<LocAnswersGreenberg />}
				></Route>
				<Route
					path="HelmsleyAnswersList"
					element={<LocAnswersHelmsley />}
				></Route>
				<Route path="PaysonAnswersList" element={<LocAnswersPayson />}></Route>
				<Route
					path="MethodistAnswersList"
					element={<LocAnswersMethodist />}
				></Route>
				<Route path="CCHAnswersList" element={<LocAnswersCCH />}></Route>
				<Route path="SixStAnswersList" element={<LocAnswersSixSt />}></Route>
				<Route path="SpruceAnswersList" element={<LocAnswersSpruce />}></Route>
				<Route path="RateCalculator" element={<RateStructure />}></Route>
				<Route path="ParkingCost" element={<CalcUI />}></Route>
				<Route path="AltParkingTab" element={<AltParkingTabContent />}></Route>
				<Route path="TopInfo" element={<MainTable />}></Route>
				<Route path="Policies" element={<GeneralRules />}></Route>
				{/* The below routes were created ( before the start of Nested Routes)
        and are repeated because of I had to create a rates submenu for mobile
        devises
         Start Here
         */}
				<Route
					path="ColumbiaRateTable"
					element={<ColumbiaBasicRateTable />}
				></Route>
				<Route
					path="CornellRateTable"
					element={<CornellBasicRateTable />}
				></Route>
				<Route
					path="MethodistRateTable"
					element={<MethodistBasicRateTable />}
				></Route>
				<Route
					path="LowerManhattanRateTable"
					element={<LowerManhattanBasicTable />}
				></Route>
				<Route path="AllenRateTable" element={<AllenBasicRateTable />}></Route>

				{/* End Here */}
				<Route path="NestedNav" element={<RatesNavBar />}>
					<Route index element={<BasicTable />} />
					<Route
						path="ColumbiaRateTable"
						element={<ColumbiaBasicRateTable />}
					></Route>
					<Route
						path="CornellRateTable"
						element={<CornellBasicRateTable />}
					></Route>
					<Route
						path="MethodistRateTable"
						element={<MethodistBasicRateTable />}
					></Route>
					<Route
						path="LowerManhattanRateTable"
						element={<LowerManhattanBasicTable />}
					></Route>
					<Route
						path="AllenRateTable"
						element={<AllenBasicRateTable />}
					></Route>
					<Route path="ParkingCost" element={<CalcUI />}></Route>
					<Route path="MonthlyProrateForm" element={<ProrateForm />}></Route>
				</Route>
				<Route path="MonthlyProrateForm" element={<ProrateForm />}></Route>
				<Route path="*" element={<MainTabContent />}></Route>
			</Routes>
		</div>
		// </BrowserRouter>
	);
}

export default App;
