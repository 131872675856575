import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
//import { useState } from "react";
import LocAnswerFooter from './AnswersFooter';
import LocGoogleMap from './LocGoogleMap';
import LocPageFooter from './PageFooter';
import SouthProp from './SP';
// import { Typography } from "@mui/material";
//import LocalParkingSharpIcon from '@mui/icons-material/LocalParkingSharp';
//import { Key } from "@mui/icons-material";
// import BuildBootStrapMenu from "./DisplayMenu";
//import CreateButtons from "../components/MenuButtons";
// import * as Icon from 'react-bootstrap-icons';

function LocAnswersDoctors() {
	// <BuildBootStrapMenu />
	const FacList = [
		{
			id: 'div5',
			quest: 'How many floors?',
			answer: 'Surface lot.'
		},
		{
			id: 'div6',
			quest: 'Does the lot have gates at entrances and exits?',
			answer:
				'Yes. A shared entrance and exit lane. To enter you must scan your access card.'
		},
		{
			id: 'div7',
			quest: ' Where can I park?',
			answer:
				' Parking levels starting from the third floor and through the eighth are designated for employees. Lot, first, and second levels are reserved for patients and visitors.'
		},
		{
			id: 'div8',
			quest: ' Who issues the access cards?',
			answer:
				'Proxy cards (access cards) issued by the Parking Office or the Manager’s Office.'
		},
		{
			id: 'div9',
			quest: ' What do I do when I arrive?',
			answer:
				'Drive into the lot, scan your card against the card reader to enter. The parking team will then issue you a ticket and valet your car.'
		},
		{
			id: 'div9',
			quest: 'How do I retrieve my car?',
			answer:
				'Hand over the ticket that was issued when you entered to the parking location staff, and they will retrieve the car.'
		},
		{
			id: 'div10',
			quest: 'Can I park my own car, or do I have to valet?',
			answer:
				'No. The lot offers valet service only. Please do not attempt to valet your car and/or take your car ignitions key.'
		},
		{
			id: 'div11',
			quest: 'Can I have more than one car at the same time on the lot?',
			answer:
				'No, you can only park one car at a time. You must exit the garage and then enter. Two consecutive exits or entrances will be rejected. In other words, you must exit before you enter again, and you must enter before you can exit. A cycle of Exit followed by another Exit or Enter by followed by another Enter is not allowed.'
		}
	];

	const opsList = [
		{
			id: 'div12',
			quest: 'Can I have more than one access card?',
			answer: 'No, only one card can be assigned to an account.'
		},
		{
			id: 'div13',
			quest: 'What do I do if I lose my card or my card stops working?',
			answer:
				'Contact the parking office to request a new card. There is a charge for a new card.'
		},
		{
			id: 'div14',
			quest: 'Can I store my car in the garage?',
			answer:
				'No storage parking allowed. Parking is for commuters only and you must exit the garage after your shift ends. Storage parking is parking for period longer than 24 hours.'
		},
		{
			id: 'div15',
			quest:
				'Can I park my car if I live in NYP housing or neighboring zip codes?',
			answer:
				'No. NYP housing residents are not allowed to use NYP parking. This restriction also includes employees living in neighboring zip codes.'
		},
		{
			id: 'div16',
			quest: 'Is there space available for new monthly accounts?',
			answer:
				'Yes, you may, however, be assigned to 115 Fort Washington if the lot is full.'
		},
		{
			id: 'div17',
			quest: 'What do I do when my card does not open the gate?',
			answer:
				'If your account in good standing, the attendant will open the gate to let you in. You must contact the parking office before you exit.'
		},
		{
			id: 'div18',
			quest: "Can I park if I don't have a monthly account?",
			answer:
				'No. The lot is reserved for employees with active monthly account.'
		},
		{
			id: 'div18',
			quest: "Can I park if I don't have a monthly account?",
			answer:
				'No. The lot is reserved for employees with active monthly account.'
		},
		{
			id: 'div19',
			quest: 'Can I choose where I park?',
			answer: 'No.'
		},
		{
			id: 'div20',
			quest: 'Does the garage accept shuttle size vans?',
			answer: 'No, the lot is full valet.'
		},
		{
			id: 'div22',
			quest: 'Do you have discounted daily rates for employees?',
			answer:
				'	No. Employees who do not have an active monthly account must pay the full transient rate'
		}
	];

	const FinalList = FacList.map((targetDivs, divId) => (
		<SouthProp key={targetDivs.id} targetDivs={targetDivs} divId={divId} />
	));
	const OperationList = opsList.map((targetDivs) => (
		<SouthProp key={targetDivs.id} targetDivs={targetDivs} />
	));

	var targetLat = 40.840552557551874;
	var targetLng = -73.94426280481252;

	return (
		<div style={{ backgroundColor: 'white' }} id="topDiv">
			<div className="container-fluid">
				<div className="row">
					<div>
						<LocGoogleMap locLat={targetLat} locLng={targetLng} />
					</div>
					<div>
						<table
							className="table table-striped table-bordered"
							style={{ border: 'ridge', marginLeft: '-0px' }}
						>
							<thead>
								<tr>
									<th id="thID" colSpan="2">
										<span style={{ color: 'blue' }}>
											Doctors Lot - 650 West 165th Street New York NY 10032{' '}
										</span>
									</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td
										className="firsttd text-start"
										style={{ fontWeight: 'bold' }}
									>
										Lot Address:
									</td>
									<td className="text-start">
										<span style={{ color: 'blue' }}>
											650 West 165th Street New York NY 10032{' '}
										</span>
									</td>
								</tr>
								<tr>
									<td className="text-start" style={{ fontWeight: 'bold' }}>
										Lot Name:
									</td>
									<td className="text-start">
										<span style={{ color: 'blue' }}>Doctors Lot </span>
									</td>
								</tr>
								<tr>
									<td className="text-start" style={{ fontWeight: 'bold' }}>
										Also Known As:
									</td>
									<td className="text-start">
										<span style={{ color: 'blue' }}>Doctors Lot</span>
									</td>
								</tr>
								<tr>
									<td className="text-start" style={{ fontWeight: 'bold' }}>
										License:
									</td>
									<td className="text-start">
										<span style={{ color: 'blue' }}>
											Employees only. No public parking allowed.
										</span>
									</td>
								</tr>
								<tr>
									<td className="text-start" style={{ fontWeight: 'bold' }}>
										Hours:
									</td>
									<td className="text-start">
										<span style={{ color: 'blue' }}>
											Monday through Friday 6A to 10P. Closed on weekends and
											NYP holidays. On weekends, after 3P on weekdays and during
											NYP holidays, employees can park at 115 Fort Washington
											Avenue garage.
										</span>
									</td>
								</tr>
								<tr>
									<td className="text-start" style={{ fontWeight: 'bold' }}>
										Entrance:
									</td>
									<td className="text-start">
										<span style={{ color: 'blue' }}>
											Entrance is on 165th Street between Riverside and Fort
											Washington Avenue.
										</span>
									</td>
								</tr>
								<tr>
									<td className="text-start" style={{ fontWeight: 'bold' }}>
										Exits:
									</td>
									<td className="text-start">
										<span style={{ color: 'blue' }}>
											165th Street between Riverside and Fort Washington Avenue.
											Please use caution as you are making a left turn out of
											the lot onto 165th Street heading to Riverside.
										</span>
									</td>
								</tr>
								<tr>
									<td className="text-start" style={{ fontWeight: 'bold' }}>
										Operation Type:
									</td>
									<td className="text-start">
										<span style={{ color: 'blue' }}>
											Valet only. Please do not attempt to park your car or/and
											take the ignition keys.
										</span>
									</td>
								</tr>
								<tr>
									<td className="text-start" style={{ fontWeight: 'bold' }}>
										Contact:
									</td>
									<td className="text-start">
										<span style={{ color: 'blue' }}>(212) 305-2718</span>
									</td>
								</tr>
							</tbody>
						</table>
						<LocPageFooter list1={FinalList} />
						<LocAnswerFooter list1={OperationList} />
					</div>
				</div>
			</div>
		</div>
	);
}

export default LocAnswersDoctors;
