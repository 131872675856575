import { Paper } from "@mui/material";
import { useState } from "react";

function PaperTemp(props) {
  const [isPaperVisible, setPaperVisible] = useState({ id: "paper" }, true);
  const showPaper = (e) => {
    setPaperVisible({ id: props.paperId }, !isPaperVisible);
    console.log(props.paperId);
  };

  return (
    <Paper
      hidden={props.showR1}
      id="locPaper"
      elevation={15}
      style={{ paddingTop: "10px", paddingBottom: "10px" }}
    >
      <span style={{ color: "blue" }}>{props.rule1}</span>
    </Paper>
  );
}
export default PaperTemp;
