import 'bootstrap/dist/css/bootstrap.css';
import { Carousel } from 'react-bootstrap';
import carouselStyles from '../css/carouselStyle.module.css'
import { Link } from 'react-router-dom';
function CreateCarousel() {
    return (
      <div className="container fluid" style={{ marginTop: "15px" }}>
        <Carousel>
          <Carousel.Item id="carouselitem">
            {/* <figure className='figure'> */}
            <img
              src={require("../images/South Property Front.jpg")}
              alt="South Property Garage"
              className={carouselStyles.img1}
            />

            {/* <figcaption className='figure-caption text-center' >115 Fort Washington Garage (South Property)</figcaption> */}
            {/* </figure> */}
            <img
              src={require("../images/166 Street.jpg")}
              alt="166th Street Garage"
              className={carouselStyles.img1}
            />
            <img
              src={require("../images/Ari Lot.jpg")}
              alt="Columbia Campus"
              className={carouselStyles.img1}
            />
            <img
              src={require("../images/DoctorsLot.jpg")}
              alt="Columbia Campus"
              className={carouselStyles.img1}
            />
            <img
              src={require("../images/Allen.jpg")}
              alt="Columbia Campus"
              className={carouselStyles.img1}
            />
            <img
              src={require("../images/Crescent.jpg")}
              alt="Columbia Campus"
              className={carouselStyles.img1}
            />
            <Carousel.Caption>
              <Link to="./ColumbiaLocTab">
                <h6 className={carouselStyles.caption}>
                  Columbia Campus Parking
                </h6>
              </Link>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item id="carouselitem" style={{ backgroundColor: "black" }}>
            <img
              src={require("../images/DHK.jpg")}
              alt="DHK Garage"
              className="d-block rounded float-start"
              style={{ width: "16.66%" }}
            />
            <img
              src={require("../images/Greenberg.jpg")}
              alt="Greenberg Garage"
              className={carouselStyles.img1}
            />
            <img
              src={require("../images/Helmsley.jpg")}
              alt="Helmsley Garage"
              className={carouselStyles.img1}
            />
            <img
              src={require("../images/Payson.jpg")}
              alt="Payson Garage"
              className={carouselStyles.img1}
            />
            <img
              src={require("../images/Coleman.jpg")}
              alt="Coleman Garage"
              className={carouselStyles.img1}
            />

            <img
              src={require("../images/Annex.jpg")}
              alt="Annex Garage"
              className={carouselStyles.img1}
            />
            <Carousel.Caption>
              <Link to="./CornellLocTab">
                <h6 className={carouselStyles.caption}>
                  Cornell Campus Parking
                </h6>
              </Link>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item id="carouselitem" style={{ backgroundColor: "black" }}>
            <img src="" alt="" className={carouselStyles.borderImg} />

            <img
              src={require("../images/CCH.jpg")}
              alt="Methodist Parking"
              className={carouselStyles.img2}
            />
            <img
              src={require("../images/6th Street.jpg")}
              alt="Methodist Parking"
              className={carouselStyles.img2}
            />
            <img
              src={require("../images/8 Spruce.jpg")}
              alt="Lower Manhattan Parking"
              className={carouselStyles.img2}
            />
            <img src="" alt="" className={carouselStyles.borderImg} />
            <Carousel.Caption>
              <Link to="./MethodistLocTab">
                <h6 className={carouselStyles.caption}> Methodist Campus</h6>
              </Link>
              <Link to="./SpruceLocTab">
                <h6 className={carouselStyles.caption}> Lower Manhattan</h6>
              </Link>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>
      // <div className='container carousel slide w-100 align-items-center' id='nypcampuses' data-bs-ride='carousel' style={{marginTop:'10px'}}>
      //     <div className='carousel-indicators'>
      //         <button type='button' data-bs-target='#nypcampuses' data-bs-slide-to='0' className='active'></button>
      //         <button type='button' data-bs-target='#nypcampuses' data-bs-slide-to='1'></button>
      //         <button type='button' data-bs-target='#nypcampuses' data-bs-slide-to='2'></button>
      //     </div>
      //     <div className='carousel-inner'>

      //         <div className='carousel-item active'>
      //             <img src={require('./South Property Front.png')} alt='Columbia Campus' className='d-block rounded float-start' />
      //             <img src={require('./166 Street.jpg')} alt='Columbia Campus' className='d-block rounded float-start' />
      //             <img src={require('./Ari Lot.jpg')} alt='Columbia Campus' className='d-block rounded float-start' />
      //             <img src={require('./Allen.jpg')} alt='Columbia Campus' className='d-block rounded float-start' />
      //             <img src={require('./Crescent.jpg')} alt='Columbia Campus' className='d-block rounded float-start' />
      //             <div className='carousel-caption'>
      //                 <h6>Columbia Campus</h6>
      //             </div>
      //         </div>
      //         <div className='carousel-item'>
      //             <img src={require('./Parking Symbol1.jpg')} alt='Cornell Campus' className='d-block w-100' />
      //             <div className='caroursel-caption'>
      //                 <h6>Cornell Campus</h6>
      //             </div>
      //         </div>
      //     </div>
      //     <button className='carousel-control-prev' data-bs-target='#nypcampuses' data-bs-slide='prev'>
      //         <span className='carousel-control-prev-icon'></span>
      //     </button>
      //     <button type='button' className='carousel-control-next' data-bs-target='#nypcampuses' data-bs-slide='next'>
      //         <span className='carousel-control-next-icon'></span>
      //     </button>

      // </div>
    );
}
export default CreateCarousel;