
import React from "react";
import columbiaTabStyle from '../css/policiesStyle.module.css'
import CardTemp from "./CardTemplate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core"
import { faParking } from "@fortawesome/free-solid-svg-icons";
library.add(faParking)

function ColumbiaTabContent() {
    return (
      <div className={columbiaTabStyle.cardsDiv}>
        <div className={columbiaTabStyle.homeCard}>
          <CardTemp
            cardLink="/AnswersList"
            cardIcon={<FontAwesomeIcon icon={faParking} />}
            imgSrc={require("../images/South Property Front.jpg")}
            imgTitle=" South Property Garage"
            def1=<span className={columbiaTabStyle.defSpan}>
              <span>Address: </span>
              <span style={{ color: "blue" }}>
                115 Fort Washington Avenue New York NY 10032
              </span>
            </span>
            def2=<span className={columbiaTabStyle.defSpan}>
              <span>Hours: </span>
              <span style={{ color: "blue" }}>
                24 Hours 7 Days <br /> Click for details
              </span>
            </span>
            def3=<span className={columbiaTabStyle.defSpan}>
              <span>Parking: </span>
              <span style={{ color: "blue" }}>Valet and Self-Park</span>
            </span>
          />
        </div>
        <div className={columbiaTabStyle.homeCard}>
          <CardTemp
            cardLink="/160Answers"
            cardIcon={<FontAwesomeIcon icon={faParking} />}
            imgSrc={require("../images/Parking Symbol1.jpg")}
            imgTitle=" 160th Street Garage"
            def1=<span className={columbiaTabStyle.defSpan}>
              <span>Address: </span>
              <span style={{ color: "blue" }}>
                630 West 160th Street New York NY 10032
              </span>
            </span>
            def2=<span className={columbiaTabStyle.defSpan}>
              <span>Hours: </span>
              <span style={{ color: "blue" }}>
                Mon-Fri 5:45A to 10P <br /> Click for details
              </span>
            </span>
            def3=<span className={columbiaTabStyle.defSpan}>
              <span>Parking: </span>
              <span style={{ color: "blue" }}>Valet Only</span>
            </span>
          />
        </div>
        <div className={columbiaTabStyle.homeCard}>
          <CardTemp
            cardLink="/166Answers"
            cardIcon={<FontAwesomeIcon icon={faParking} />}
            imgSrc={require("../images/166 Street.jpg")}
            imgTitle=" 166th Street Garage"
            def1=<span className={columbiaTabStyle.defSpan}>
              <span>Address: </span>
              <span style={{ color: "blue" }}>
                503 West 166th Street New York NY 10032
              </span>
            </span>
            def2=<span className={columbiaTabStyle.defSpan}>
              <span>Hours: </span>
              <span style={{ color: "blue" }}>
                Mon-Fri 5:45A to 1A <br /> Click for details
              </span>
            </span>
            def3=<span className={columbiaTabStyle.defSpan}>
              <span>Parking: </span>
              <span style={{ color: "blue" }}>Valet Only</span>
            </span>
          />
        </div>
        <div className={columbiaTabStyle.homeCard}>
          <CardTemp
            cardIcon={<FontAwesomeIcon icon={faParking} />}
            cardLink="/469WAnswersList"
            imgSrc={require("../images/Ari Lot.jpg")}
            imgTitle=" 165th Street Lot"
            def1=<span className={columbiaTabStyle.defSpan}>
              <span>Address: </span>
              <span style={{ color: "blue" }}>
                469 West 165th Street New York NY 10032
              </span>
            </span>
            def2=<span className={columbiaTabStyle.defSpan}>
              <span>Hours: </span>
              <span style={{ color: "blue" }}>
                Mon 6A-Fri 12A. <br /> Click for details
              </span>
            </span>
            def3=<span className={columbiaTabStyle.defSpan}>
              <span>Parking: </span>
              <span style={{ color: "blue" }}>Valet Only</span>
            </span>
          />
        </div>
        <div className={columbiaTabStyle.homeCard}>
          <CardTemp
            cardIcon={<FontAwesomeIcon icon={faParking} />}
            cardLink="/Doctors"
            imgSrc={require("../images/DoctorsLot.jpg")}
            imgTitle=" Doctors Lot"
            def1=<span className={columbiaTabStyle.defSpan}>
              <span>Address: </span>
              <span style={{ color: "blue" }}>
                650 West 165th Street New York NY 10032
              </span>
            </span>
            def2=<span className={columbiaTabStyle.defSpan}>
              <span>Hours: </span>
              <span style={{ color: "blue" }}>
                Mon-Fri 6A to 10P <br /> Click for details
              </span>
            </span>
            def3=<span className={columbiaTabStyle.defSpan}>
              <span>Parking: </span>
              <span style={{ color: "blue" }}>Valet Only</span>
            </span>
          />
        </div>
        <br />
        <br />
      </div>
    );
}
export default ColumbiaTabContent;