
import ProrateWeeklyCalc from "./ProrateWeeklyResult";

function WeeklyResult(inDate,ratesData,campusName,loc,parkType) {
  var result;
  
  if (campusName === "Columbia") {
    result = ProrateWeeklyCalc(
      inDate,
      ratesData,
      campusName,
      loc,
      parkType
    );
  } else if (campusName === "Cornell") {
    result = ProrateWeeklyCalc(
      inDate,
      ratesData,
      campusName,
      loc,
      parkType
    );
  } else if (campusName === "Methodist") {
    result = ProrateWeeklyCalc(
      inDate,
      ratesData,
      campusName,
      loc,
      parkType
    );
  } else if (campusName === "LMH") {
     result = ProrateWeeklyCalc(
      inDate,
      ratesData,
      campusName,
      loc,
      parkType
    );
  } else if (campusName === "Allen") {
     result = ProrateWeeklyCalc(
      inDate,
      ratesData,
      campusName,
      loc,
      parkType
    );
  }

  return result;
}
export default WeeklyResult;
