import { ColumnFilter } from "./ColumnFilter";
const COLUMNS = [
  {
    Header: "Campus",
    accessor: "campus",
    Filter: ColumnFilter
    // disableFilters:true
  },
  {
    Header: "Description",
    accessor: "desc",
    Filter: ColumnFilter
    // disableFilters:true
  },
  {
    Header: "Locations",
    accessor: "locations",
    Filter: ColumnFilter,
    // disableFilters:true
    //Cell: ({value}) => <button className='btn btn-link btn-sm btn-primary' onClick={()=>{alert("Test")}}> Test</button> 
  },
  {
    Header: "Rate",
    accessor: "rate",
    Filter: ColumnFilter,
    // disableFilters:true,
    Cell: ({ value }) =>
      new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD"
      }).format(value),
  },
  {
    Header: "Remarks",
    accessor: "Remark",
    Filter: ColumnFilter
    // disableFilters:true
  }
];
export default COLUMNS;
