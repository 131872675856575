import React, {useState} from "react";
import 'bootstrap/dist/css/bootstrap.css';
//import { useState } from "react";
import SouthProp from './SP';
import { Typography } from "@mui/material";
//import LocalParkingSharpIcon from '@mui/icons-material/LocalParkingSharp'; 
//import { Key } from "@mui/icons-material";
// import BuildBootStrapMenu from "./DisplayMenu";
//import CreateButtons from "../components/MenuButtons";
import * as Icon from 'react-bootstrap-icons';


   
function HideQuestions() {
    var hideDiv = document.getElementById('questsDiv');
    
    if (hideDiv.style.display === 'none') {
        hideDiv.style.display = 'block';
    } else {
        hideDiv.style.display = 'none';
    }
}

const CampusList = [

    {
        id: 'div1',
        quest: 'How do I apply for a parking account, cancel my account, or update account information?',
        answer: 'Select Applications from the above menu and click any of the blue buttons or scan the QR codes.'

    },
    {
        id: 'div2',
        quest: 'Is there a waiting list',
        answer: 'Yes, employees who apply for parking are placed on the waiting list. Inquire with the parking staff about the average waiting list wait time.'
    },
    {
        id: 'div3',
        quest: 'Can I have more than one car at the same time in the garage',
        answer: 'No, you can only park one car at a time.'
    },
    {
        id: 'div4',
        quest: 'What if I lose my car identification tag or access card, change my car or the car identification tag stops working?',
        answer: 'Contact the parking office to request a new tag. There is a charge for a new tag.'
    },
    {
        id: 'div5',
        quest: 'Can I remove the access tag and place on another car?',
        answer: 'No, when the tag is peeled off, it will immediately stop working. Contact the parking office staff when you need to add a second car to your account or when you change your car'
    },
    {
        id: 'div6',
        quest: 'Can I store my car in the garage?',
        answer: 'No. Methodist Campus parking is for commuters only.'
    },
    {
        id: 'div7',
        quest: 'Can I park my car if I live in buildings located in neighboring zip codes?',
        answer: 'No. Employees who reside in buildings located in neighborhood zip codes are not allowed to park in NYP parking garages.'
    },
    {
        id: 'div8',
        quest: 'Do the garages accept SUVs and small vans?',
        answer: 'Yes, but there is height restriction. Please remove all racks from your car’s roof before entering the garage. Please inquire with the parking location manager and the parking office staff regarding the additional cost and whether your vehicle can access the garage.'
    },
    {
        id: 'div9',
        quest: 'Do the garages accept shuttle size vans?',
        answer: 'No. Large vans are not accepted due to height limits and parking space sizes.'
    },

]

function LocAnswersMethodist (){
    // <BuildBootStrapMenu />
    const [isClicked, setIsClicked] = useState(false);

    // const [isShown, setIsShown] = useState(false, { id: 'divOne' });
    // const hideShowDiv = (e) => {
    //     setIsShown({ id: e.target.id }, !isShown);
    //     divName = e.target.id;
    //     console.log (divName);
        
    //  };
    const MainList = CampusList.map((targetDivs,divId) => (
        <SouthProp key={targetDivs.id}  targetDivs={targetDivs} divId={divId} />
    ));
    // const OperationList = opsList.map((targetDivs) => (
    //     <SouthProp key={targetDivs.id} targetDivs={targetDivs} />
    // ));

    return(
        <div className='container col-10' id="topDiv">
            {/* <div className="container"> */}
            {/* <BuildBootStrapMenu /> */}
            {/* < CreateButtons /> */}
            {/* </div> */}
            {/* <div>
                <Typography variant='h6' style={{ backgroundColor: 'white', textAlign: 'start' }} sx={{ marginTop: 2, paddingTop: '2px' }}>
                    Click <a href="/" >here</a> for information that applies to all parking facilities.
                </Typography>
            </div> */}
            {/* <div>
                <table className="table table-striped table-bordered">
                    <thead>
                        <tr>
                            <td  style={{ fontWeight: 'bold' }}>
                                How do I apply for a parking account, cancel my account, or update account information?
                            </td>
                            <td>
                                <span style={{ color: 'blue' }}>Select Forms from the menue and then click New Account Applications,
                                 Account Cancellation or Update Account information</span>
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td  style={{fontWeight:'bold'}}>
                                Is there a waiting list?
                            </td>
                            <td>
                                <span style={{ color: 'blue' }}>Yes, employees who apply for parking are placed on the waiting list.
                                                         Inquire with the parking staff about the average waiting list wait time.
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td  style={{ fontWeight: 'bold' }}>
                                Can I have more than one car at the same time in the garage?
                            </td>
                            <td>
                                <span style={{ color: 'blue' }}>No, you can only park one car at a time. </span>
                            </td>
                        </tr>
                        <tr>
                            <td  style={{ fontWeight: 'bold' }}>
                                What if I lose my car identification tag or access card, change my car or the car identification tag stops working?
                            </td>
                            <td>
                                <span style={{ color: 'blue' }}>Contact the parking office to request a new tag. 
                                                            There is a charge for a new tag.</span>
                            </td>
                        </tr>
                        <tr>
                            <td  style={{ fontWeight: 'bold' }}> 
                                Can I remove the RFID tag and place on another car?
                            </td>
                            <td>
                                <span style={{ color: 'blue' }}>No, when the tag is peeled off, it will immediately stop working.
                                                                Contact the parking office staff when you need 
                                                                to add a second car to your account or when you change your car.
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td  style={{ fontWeight: 'bold' }}>
                                Can I store my car in the garage?
                            </td>
                            <td> 
                                <span style={{ color: 'blue' }}>
                                    No. Methodist Campus parking is for commuters only.
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td  style={{ fontWeight: 'bold' }}>
                                Do the garages accept SUVs and small vans?
                            </td>
                            <td>
                                <span style={{ color: 'blue' }}>
                                    Yes, but there is height restriction. Please remove all racks from your car’s
                                    roof before entering the garage.
                                    Please inquire with the parking location manager and the parking office staff
                                    regarding the additional cost and whether your vehicle can access the garage.

                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td  style={{ fontWeight: 'bold' }}>
                                Can I park my car if I live in buildings located in neighboring zip codes?
                            </td>
                            <td>
                                <span style={{ color: 'blue' }}>
                                    No. Employees who reside in buildings located in neighboring zip codes
                                    are not allowed to park in NYP parking garages.

                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td  style={{ fontWeight: 'bold' }}>
                                Do the garages accept shuttle size vans?
                            </td>
                            <td>
                                <span style={{ color: 'blue' }}>
                                    No. Large vans are not accepted due to height limits and parking space sizes.
                                </span>
                            </td>
                        </tr>
                    </tbody>                  
                </table>
            </div> */}
            <br />
            <br />
            <div style={{ marginLeft: '15px' }} onClick={() => {setIsClicked(!isClicked)}}>
                <div>
                    <Typography variant='h6'
                        sx={{ paddingTop: '2px', paddingLeft: '2px', textAlign: 'start' }}>
                        For anwers to more questions, click the links below. Click <a href="/" >here</a> for
                        parking policies that applies to all locations.
                    </Typography>
                    <p>
                        <Typography variant="h6" sx={{ paddingLeft: '0px', backgroundColor: 'lightgreen', marginTop: '1px' }}>
                            <button className="btn btn-link" onClick={HideQuestions}>
                                Click for Answers to question that apply to Methodist parking locations
                                {isClicked ? <Icon.CaretUpFill /> : <Icon.CaretDownFill />}
                            </button>
                        </Typography>
                    </p>
                    <div id='questsDiv' style={{ marginLeft: '0px', display: 'none' }}>
                        {MainList}
                    </div>
                </div>
            </div>
        </div>
        
    );
   
}

export default LocAnswersMethodist;