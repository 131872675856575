
function RateStructure (date1,date2,ratesList) {


    var rateCur = new Intl.NumberFormat('en-US',{
        style:'currency',
        currency:'USD'
    });

    var firstInterval=0;
    var secondInterval=0;
    var dayRate = 0;
    var r=0;

    var dateOptions = {weekday:'long',year:'numeric', month:'short',day:'numeric'}

    for (let i=0;i<ratesList.length;i++){
        // console.log('stay3', date1, date2)
        // console.log(myRate.los)
        // var stayDays = new Date (date1.getYear(),date1.getMonth() + 1,0); //to get end of month
        // console.log(stayDays.toString())
        var stay = ((date1) - (date2))/(1000*60) //to get to minutes
        // var stayHrsMin = (stay - parseInt(stay))*60 //stay per minutes 

        // console.log(stay,stayHrsMin, 'Final Stay: ' + parseInt(stay) + ':' + Math.round(stayHrsMin))
        //stay <=30 ? console.log(`${rate.rate}`) : console.log('Cost is much higher')
        // console.log('stay2',ratesList[i])
        
        if ((stay >= ratesList[i].start && stay <= ratesList[i].los) && ratesList[i].max === 0){

            // console.log( 'Cost is: ' +  rateCur.format(ratesList[i].rate))
            r = 'Enter on ' + new Date(date2).toLocaleString('en-US', dateOptions) + ' ' + new Date(date2).toLocaleTimeString() +
                '. Leave on ' + new Date(date1).toLocaleString('en-US', dateOptions) + ' ' + new Date(date1).toLocaleTimeString() +
                '. LOS: ' + parseInt(stay / 60 / 24) + ' Days, ' +
                parseInt(stay / 60) + ' Hours and ' +
                Math.round((stay / 60 - parseInt(stay / 60)) * 60) +
                ' Minutes. Final Rate: ' + rateCur.format(dayRate * firstInterval + ratesList[i].rate)
            // console.log(r,'r1')
            break
        } else if (ratesList[i].max===1) {
            dayRate = ratesList[i].rate 
            firstInterval = parseInt(stay/1440); //days
            // console.log(stay, 'stay', date1, date2)
            // console.log(firstInterval, 'Interval 3', 'Stay', date1, date2)
            if(firstInterval >= 1) {
                secondInterval = (stay % 1440);
                // console.log(secondInterval, 'Interval 2')
                for (let j=0;j<ratesList.length;j++){
                    if (secondInterval >= ratesList[j].start && secondInterval <= ratesList[j].los) {
                        // rateCur = rateCur.format(dayRate + myRate.rate)
                        r = 'Enter on ' + new Date(date2).toLocaleString('en-US', dateOptions) + ' ' + new Date(date2).toLocaleTimeString() +  
                            '. Leave on ' + new Date(date1).toLocaleString('en-US', dateOptions) + ' ' + new Date(date1).toLocaleTimeString() +
                        '. Stay: ' + parseInt(stay / 60 / 24) + ' Days, ' + 
                        parseInt(secondInterval / 60) + ' Hours and ' + 
                        Math.round((secondInterval / 60 - parseInt(secondInterval / 60))*60) +   
                        ' Minutes. Parking Fee: ' + rateCur.format(dayRate*firstInterval + ratesList[j].rate)
                        break
                    }
                };
                
            }else {
                r = 'Enter on ' + new Date(date2).toLocaleString('en-US',dateOptions)  + ' ' +  new Date(date2).toLocaleTimeString() + 
                    '. Leave on ' + new Date(date1).toLocaleString('en-US', dateOptions) + ' ' + new Date(date1).toLocaleTimeString() + 
                '. LOS: ' + parseInt(stay / 60 / 24) + ' Days, ' + 
                        parseInt(stay / 60) + ' Hours and ' + 
                        Math.round((stay / 60 - parseInt(stay / 60))*60) +   
                        ' Minutes. Final Rate: ' + rateCur.format(dayRate*firstInterval + ratesList[i].rate)
                break
            } 
        }
        // console.log(r,'r2')
    };
   return(r)
    
}

export default RateStructure;