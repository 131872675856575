import { Typography } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import prorateStyle from '../css/prorateCalcStyle.module.css';
import AllenRateTableData from './AllenRateTableData.json';
import BiWeeklyResult from './BiWeeklyProrateCalc';
import ColumbiaRateTableData from './ColumbiaRateTableData.json';
import CornellRateTableData from './CornellRateTableData.json';
import CreateLocSelect from './CreateLocationSelect';
import CreateSelect from './CreateParkTypeSelect';
import LowerManhattanRateTableData from './LowerManhattanRateTableData.json';
import MethodistRateTableData from './MethodistRateTableData.json';
import WeeklyResult from './WeeklyProrateCalc';

var selectedCampusList;
var selectedSelectValue;
function SelectCampusRateList() {
	if (document.getElementById('campusname').value === 'Columbia') {
		selectedCampusList = ColumbiaRateTableData;
	} else if (document.getElementById('campusname').value === 'Cornell') {
		selectedCampusList = CornellRateTableData;
	} else if (document.getElementById('campusname').value === 'Methodist') {
		selectedCampusList = MethodistRateTableData;
	} else if (
		document.getElementById('campusname').value === 'Lower Manhattan'
	) {
		selectedCampusList = LowerManhattanRateTableData;
	} else if (document.getElementById('campusname').value === 'Allen') {
		selectedCampusList = AllenRateTableData;
	}
	return selectedCampusList;
}

const ProrateForm = () => {
	// const [show, setShow] = useState(false);

	// const handleClose = () => setShow(false);
	// const handleShow = () => setShow(true);

	var areLocationsLoaded = false;
	var areParkTypesLoaded = false;
	var areCampusesLoaded = false;
	var curr = new Date();
	curr.setDate(curr.getDate());
	var currDate = curr.toISOString().substring(0, 10);

	return (
		<div className={prorateStyle.container}>
			<h5 className={prorateStyle.heading}>
				The calculator below determines the amount owed before the employee
				begins parking. If and when the start date changes, the amount owed will
				vary. The parking office will provide a rate breakdown that includes the
				prorated amount owed, the date of the first payroll deduction, and the
				card or RFID tag charge, which is not included in this rate. The
				calculator below is only for employees who are eligible for payroll
				deduction and wish to enroll. If you are not eligible for the payroll
				deduction program or wish to be billed monthly, please contact the
				parking office for more information.
			</h5>
			<textarea
				className={prorateStyle.result}
				rows={15}
				id="result"
				name="result"
				disabled
			></textarea>

			<span id="basic-addon1" className={prorateStyle.radioBtn1}>
				<input
					type="radio"
					id="biweekly"
					name="cycle"
					value={'biweekly'}
					defaultChecked="true"
				/>{' '}
				Bi-Weekly Pay Cycle
			</span>

			<span id="basic-addon1" className={prorateStyle.radioBtn2}>
				<input
					type="radio"
					id="weekly"
					name="cycle"
					value={'weekly'}
					defaultChecked="false"
				/>{' '}
				Weekly Pay Cycle
			</span>

			{/* <div style={{ marginLeft: "20px" }}>
            <span className="input-group-text" id="basic-addon1">
              <input
                type="radio"
                id="billing"
                name="billing"
                value="billing"
                style={{ marginRight: "10px" }}
                disabled
              />
              Monthly Billing Cycle
            </span>
          </div> */}

			<select
				className={prorateStyle.dropdown}
				id="campusname"
				name="campusname"
				onChange={() => {
					// console.log(document.getElementById.innerHTML);
					//if (document.getElementById("campusname").value === "Select") {
					SelectCampusRateList();
					// console.log(e.target.value)
					// CreateLocSelect(e.target.value, selectedCampusList, "loc");
					//}
					selectedSelectValue = document.getElementById('campusname').value;
					document.getElementById('loc').innerHTML = 'Select Location';
					areLocationsLoaded = false;
					areParkTypesLoaded = false;
					areCampusesLoaded = true;
				}}
			>
				<option value="Select">Select Campus</option>
				<option value="Columbia">Columbia</option>
				<option value="Cornell">Cornell</option>
				<option value="Methodist">Methodist</option>
				<option value="Lower Manhattan">Lower Manhattan</option>
				<option value="Allen">Allen Hospital</option>
			</select>
			<p className={prorateStyle.campusTooltip}>Select Campus</p>

			<select
				className={prorateStyle.locDropdown}
				id="loc"
				placeholder="Select Location"
				name="loc"
				onClick={() => {
					// console.log(
					//   selectedSelectValue,
					//   document.getElementById("campusname").value,
					//   1
					// );
					if (areLocationsLoaded === false && areCampusesLoaded === true) {
						SelectCampusRateList();
						// console.log(
						//   document.getElementById("campusname").value,
						//   2,
						//   selectedCampusList
						// );
						CreateLocSelect(selectedSelectValue, selectedCampusList, 'loc');
						areLocationsLoaded = true;
						areParkTypesLoaded = false;
						document.getElementById('parktype').innerHTML =
							'Select Parking Type';
					}
				}}
			>
				<option value="SelectLoc">Select Location</option>
			</select>

			<span className={prorateStyle.locTooltip}>Select Location</span>

			{/* <div className="input-group mb-3 col"> */}
			{/* <span className="input-group-text" id="basic-addon1">
						Select Location
					</span> */}

			{/* </div> */}

			{/* <span className="input-group-text" id="basic-addon1">
						Select Parking Type
					</span> */}
			<select
				className={prorateStyle.parkTypeDropdown}
				placeholder="Select Parking Type"
				id="parktype"
				name="parktype"
				onClick={() => {
					if (areParkTypesLoaded === false && areLocationsLoaded === true) {
						SelectCampusRateList();
						CreateSelect(
							document.getElementById('loc').value,
							selectedCampusList,
							'parktype'
						);
						areParkTypesLoaded = true;
						areLocationsLoaded = false;
					}
				}}
			>
				<option value="selectparktype">Select Parking Type</option>
			</select>
			<span className={prorateStyle.parkTooltip}>Select Parking Type</span>
			<input
				className={prorateStyle.dateInput}
				type="date"
				defaultValue={currDate}
				id="dateIn"
				name="dateIn"
			/>
			<span className={prorateStyle.inputTooltip}>Enter Start Date</span>

			{/* <div className="input-group mb-3 col"> */}
			{/* <span className="input-group-text" id="basic-addon1">
						Parking Start Date
					</span> */}

			<button
				className={prorateStyle.btn}
				onClick={() => {
					var d1 = new Date(document.getElementById('dateIn').value);
					var campusName = document.getElementById('campusname').value;
					SelectCampusRateList();
					if (document.getElementById('biweekly').checked) {
						// console.log(d1);
						document.getElementById('result').value = BiWeeklyResult(
							d1,
							selectedCampusList,
							campusName,
							document.getElementById('loc').value,
							document.getElementById('parktype').value
						);
					} else if (document.getElementById('weekly').checked) {
						document.getElementById('result').value = WeeklyResult(
							d1,
							selectedCampusList,
							campusName,
							document.getElementById('loc').value,
							document.getElementById('parktype').value
						);
					}
				}}
			>
				Calculate
			</button>
		</div>
	);
};

export default ProrateForm;
