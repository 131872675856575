import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
//import { useState } from "react";
import SouthProp from './SP';
// import { Typography } from "@mui/material";
import LocPageFooter from './PageFooter';
//import LocalParkingSharpIcon from '@mui/icons-material/LocalParkingSharp';
// import { Key } from "@mui/icons-material";
// import BuildBootStrapMenu from "./DisplayMenu";
//import CreateButtons from "../components/MenuButtons";
// import * as Icon from 'react-bootstrap-icons';
import LocAnswerFooter from './AnswersFooter';
import LocGoogleMap from './LocGoogleMap';

function LocAnswersAriLot() {
	// <BuildBootStrapMenu />
	const FacList = [
		{
			id: 'div5',
			quest: 'How many floors?',
			answer: 'Surface Lot'
		},
		{
			id: 'div6',
			quest: 'Does the lot have gates at entrances and exits?',
			answer:
				'No. Lot uses license plate recognition (LPR) to validate accounts and issues a ticket. All cars must have an RFID tag as well.'
		},
		{
			id: 'div21',
			quest: 'Does the lot have car stackers or lifts?',
			answer: 'No lifts or stackers.'
		},
		{
			id: 'div7',
			quest: 'What do I do when I arrive?',
			answer:
				'Drive into the lot and the parking team will issue a ticket and valet your car.'
		},
		{
			id: 'div9',
			quest: 'How do I retrieve my car?',
			answer:
				'Hand over the ticket that was issued when you entered to the parking location staff, and they will retrieve the car.'
		},
		{
			id: 'div10',
			quest: 'Can I park my own car, or do I have to valet?',
			answer:
				'No. The lot offers valet service only. Please do not park your car and/or take the ignition keys.'
		},
		{
			id: 'div10',
			quest: 'Can I choose where I park?',
			answer: 'No. Valet staff will valet  and park your car.'
		},
		{
			id: 'div11',
			quest: 'Can I have more than one car at the same time in the lot?',
			answer: 'No, you can only park one car at a time.'
		},
		{
			id: 'div11',
			quest: 'What do I need to access the lot?',
			answer:
				'You must have your license plate update in our access control system. Lot uses LPR to read and validate your account. RFID tags are also issued to identify the cars.'
		},
		{
			id: 'div11',
			quest: 'How do I update my license plate?',
			answer:
				'To update your license plate, select Forms from the menu and then click Update Account Information.'
		},
		{
			id: 'div8',
			quest: 'Who issues the RFID tag?',
			answer:
				'RFID tags (access tags) are issued by the Parking Office or the Manager’s Office.'
		}
	];

	const opsList = [
		{
			id: 'div12',
			quest: 'Can I have more than one access tag (RFID tag)?',
			answer: 'No, only one access tag can be assigned to an account.'
		},
		{
			id: 'div12',
			quest: 'Can I register more than one car in access system?',
			answer: 'Yes. You can have up to 2 cars registered.'
		},
		{
			id: 'div13',
			quest: 'What do I do if I need a new RFID tag?',
			answer:
				'Contact the parking office to request a new access tag. There is a charge for a tag replacement.'
		},
		{
			id: 'div14',
			quest:
				'Can I store my car in the lot (storage parking is parking a car in the lot for more than 24 hours)?',
			answer:
				'No storage parking allowed. Parking is for commuters only and you must exit the lot after your shift ends.'
		},
		{
			id: 'div15',
			quest:
				'Can I park my car if I live in NYP housing or neighboring zip codes?',
			answer:
				'No. NYP housing residents are not allowed to use NYP parking. This restriction also includes employees living in neighboring zip codes.'
		},
		{
			id: 'div17',
			quest:
				'What happens if my car license plate is not recognized or read by the License Plate Recognition (LPR) software?',
			answer:
				'If your tag is valid and your account in good standing, the staff will issue you a replacement ticket.'
		},
		{
			id: 'div16',
			quest: 'Is there space available for new monthly accounts?',
			answer:
				'No, new monthly accounts will be assigned to a remote location (offsite) that may require shuttle service.'
		},
		{
			id: 'div18',
			quest: "Can I park if I don't have a monthly account?",
			answer: 'No. You must have a valid monthly account to use the lot.'
		},
		{
			id: 'div19',
			quest: 'Does the lot accept SUVs and small vans?',
			answer:
				'Yes, but there is height restriction. Please remove all racks from your car’s roof before entering the lot.'
		},
		{
			id: 'div20',
			quest: 'Does the lot accept shuttle size vans?',
			answer:
				'No. Large vans cannot be accommodated due to height limits and parking space sizes.'
		},

		{
			id: 'div22',
			quest: 'Do you have discounted daily rates for employees?',
			answer:
				'No. Employees who do not have an active monthly account cannot access the lot.'
		}
	];

	// const [isShown, setIsShown] = useState(false, { id: 'divOne' });
	// const hideShowDiv = (e) => {
	//     setIsShown({ id: e.target.id }, !isShown);
	//     divName = e.target.id;
	//     console.log (divName);

	//  };
	const FinalList = FacList.map((targetDivs, divId) => (
		<SouthProp key={targetDivs.id} targetDivs={targetDivs} divId={divId} />
	));
	const OperationList = opsList.map((targetDivs) => (
		<SouthProp key={targetDivs.id} targetDivs={targetDivs} />
	));

	var targetLat = 40.837756098042234;
	var targetLng = -73.93723318919413;
	return (
		<div style={{ backgroundColor: 'white' }} id="topDiv">
			<div className="container-fluid">
				<div className="row">
					{/* <div className="col-auto"> */}
					{/* <BuildBootStrapMenu /> */}
					{/* <CreatSidebar /> */}
					{/* < CreateButtons /> */}
					{/* </div> */}
					<div>
						<LocGoogleMap locLat={targetLat} locLng={targetLng} />
					</div>
					<div>
						<table
							className="table table-striped table-bordered"
							style={{ border: 'ridge', marginLeft: '-0px' }}
						>
							<thead>
								<tr>
									<th id="thID" colSpan="2">
										<span style={{ color: 'blue' }}>
											{' '}
											165th Street Lot - 469 West 165th Street New York NY 10032{' '}
										</span>
									</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td className="text-start" style={{ fontWeight: 'bold' }}>
										Lot Address:
									</td>
									<td className="text-start">
										<span style={{ color: 'blue' }}>
											469 West 165th Street New York NY 10032{' '}
										</span>
									</td>
								</tr>
								<tr>
									<td className="text-start" style={{ fontWeight: 'bold' }}>
										Lot Name:
									</td>
									<td className="text-start">
										<span style={{ color: 'blue' }}>165th Street lot </span>
									</td>
								</tr>
								<tr>
									<td className="text-start" style={{ fontWeight: 'bold' }}>
										Also Known As:
									</td>
									<td className="text-start">
										<span style={{ color: 'blue' }}>
											Ari's Lot or CHONY'S LOT
										</span>
									</td>
								</tr>
								<tr>
									<td className="text-start" style={{ fontWeight: 'bold' }}>
										License:
									</td>
									<td className="text-start">
										<span style={{ color: 'blue' }}>
											Employees only. No public parking allowed.
										</span>
									</td>
								</tr>
								<tr>
									<td className="text-start" style={{ fontWeight: 'bold' }}>
										Hours:
									</td>
									<td className="text-start">
										<span style={{ color: 'blue' }}>
											Opens Monday 6A. CLoses Friday 12A. CLosed Saturday and
											Sunday
										</span>
									</td>
								</tr>
								<tr>
									<td className="text-start" style={{ fontWeight: 'bold' }}>
										Entrance:
									</td>
									<td className="text-start">
										<span style={{ color: 'blue' }}>
											Entrance is on 165th Street between Amsterdam Avenue and
											Edgecombe Avenue.
										</span>
									</td>
								</tr>
								<tr>
									<td className="text-start" style={{ fontWeight: 'bold' }}>
										Exits:
									</td>
									<td className="text-start">
										<span style={{ color: 'blue' }}>
											Exit on 165th Street between Amsterdam Avenue and
											Edgecombe Avenue.
										</span>
									</td>
								</tr>
								<tr>
									<td className="text-start" style={{ fontWeight: 'bold' }}>
										Operation Type:
									</td>
									<td className="text-start">
										<span style={{ color: 'blue' }}>Valet only.</span>
									</td>
								</tr>
								<tr>
									<td className="text-start" style={{ fontWeight: 'bold' }}>
										Contact:
									</td>
									<td className="text-start">
										<span style={{ color: 'blue' }}>(212) 305-2718.</span>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<LocPageFooter list1={FinalList} />
				<LocAnswerFooter list1={OperationList} />
			</div>
		</div>
	);
}

export default LocAnswersAriLot;
