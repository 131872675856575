import React from "react";
import { Typography } from "@mui/material";
import * as Icon from 'react-bootstrap-icons';
import { useState } from "react";
// import HideQuestions from "./ShowHideDivs";

const HideQuestions = () => {
    var hideDiv = document.getElementById('questsDiv');
    if (hideDiv.style.display === 'none') {
        hideDiv.style.display = 'block';
    } else {
        hideDiv.style.display = 'none';
    }
}

function LocPageFooter({list1}){
    const [isClicked, setIsClicked] = useState(false);
    return (
        <div className="col-10" style={{ width: '90%' }}>
            <div style={{marginBottom:'15px'}} id="footerDiv">
                <div  id='main' onClick={() => { setIsClicked(!isClicked) }}>
                    <Typography variant='h6'
                        sx={{ marginTop: 2, paddingTop: '2px', paddingLeft: '2px', textAlign: 'start' }}>
                        For anwers to more questions, click the links below. Click <a href="/" >here</a> for
                        parking policies that applies to all locations.
                    </Typography>
                    <p>
                        <Typography variant="h6" sx={{ backgroundColor: 'lightgreen', marginTop: '1px' }}>
                            <button className="btn btn-link" onClick={HideQuestions}>
                                Click for Answers to Facility Questions 
                                {isClicked ? <Icon.CaretUpFill /> : <Icon.CaretDownFill />}
                            </button>
                        </Typography>    
                    </p>
                </div>
                <div id='questsDiv' style={{ display: 'none' }}>
                    {list1}
                </div>
            </div>
        </div>
    );
}

export default LocPageFooter;