import React, { useState} from "react";
import 'bootstrap/dist/css/bootstrap.css';
// import FAQ from "../components/MainComp";
import Stack from '@mui/material/Stack';
// import { Typography, dividerClasses } from "@mui/material";
import * as Icon from 'react-bootstrap-icons';


function SouthProp({targetDivs,divId}) {
    const [isShown, setIsShown] = useState(false, { id: 'divOne' });
    // const [isClicked,setIsClicked] = useState(false);

    const ShowDiv = (e) => {

        setIsShown({ id: e.target.id }, !isShown);
        divId = e.target.id;
    };

    const HideDiv = (e) => {

        setIsShown({ id: e.target.id }, !isShown);
    };
    const [isClicked, setIsClicked] = useState(false);
    return (
        
        <Stack variant='primary' sx={{ backgroundColor: 'lightcyan' }} className="container">
            <div onClick={() => {setIsClicked(!isClicked)}}>             
                <button id={targetDivs.id} className="btn btn-link" onClick={(e) =>
                    // {isClicked ? ShowDiv(e) : HideDiv(e)}}>
                    ShowDiv(e)} >
                    {targetDivs.quest}
                     {/* {isClicked ? <Icon.CaretUpFill /> : <Icon.CaretDownFill />} */}
                </button>
                <div className={isShown.id === targetDivs.id ? divId : 'd-none'} >
                    <p style={{ backgroundColor: 'whitesmoke', textAlign: 'left',marginLeft:'15px' }}>
                        {targetDivs.answer}
                        <button type="button" className="btn btn-link" onClick={(e) => HideDiv(e) }>
                            {<Icon.CaretUpFill />}
                        </button>
                    </p>
                </div>
            </div>
        </Stack>
    );
}



// class SouthProp extends Component {
//     constructor(props) {
//         super(props);

//         this.state = {
//             isShown: false 
//         };
//         this.ShowDiv = this.ShowDiv.bind(this);
//     }
   
//     render() {
//         return (
//             <div>           
//             <button className="btn btn-link" onClick={() => this.ShowDiv}>
//                 Where is the garage located?
//             </button>
//             {this.isShown && <div>located</div>}
//             </div>
//         );
//     }
// }

// function SouthProp() {
    // const [isShown, setIsShown] = useState(false,{id:'divOne'});
    
    // const hideShowDiv = (e) => {
    //     setIsShown({id: e.target.id},!isShown);
    // };
    //return (
        // <div className="container" >
        //     <div className="col-4">
                // {/* <div>
                //     <button id='divOne' className="btn btn-link" onClick={(e) => {
                //         hideShowDiv(e)
                //         }}>
                //         Where is the garage located?
                //     </button>
                // </div>
                // <div>
                //     <div className={isShown.id === 'divOne' ? `divOne`: 'divOne d-none'} style={{paddingLeft:'20px'}} >
                //         <>The garage is located on Fort Washington Avenue
                //             between 165th and 164th Streets.</>
                //     </div>
                // </div>

                // <div>
                //     <button  id='divTwo' className="btn btn-link" onClick={(e) => {
                //         hideShowDiv(e)
                //     }}>
                //         Where are the entrances and exits?
                //     </button>
                // </div>
                // <div>
                //     <div className={isShown.id === 'divTwo' ? `divTwo`: 'divTwo d-none'}  style={{paddingLeft:'20px'}} >
                //         <>Entrances are on Fort Washington between 165th and 164th Streets.</>
                //     </div>
                // </div>
                // <div>
                //     <button id='divThree' className="btn btn-link" onClick={(e) => {
                //         hideShowDiv(e)
                //     }}>
                //         Hours of Operations?
                //     </button>
                // </div>
                // <div>
                //     <div className={isShown.id === 'divThree' ? `divThree` : 'divThree d-none'} style={{ paddingLeft: '20px' }}>
                //         <>24 hours 7 days</>
                //     </div>
                // </div>
                // <div>
                //     <button id='divFour' className="btn btn-link" onClick={(e) => {
                //         hideShowDiv(e)
                //     }}>
                //         How many floors?
                //     </button>
                // </div>
                // <div>
                //     <div className={isShown.id === 'divFour' ? 'divFour' : 'd-none'} style={{ paddingLeft: '20px' }}>
                //         <>	
                //             Eight floors. The lot and level 1 and 2 are reserved for patients and visitors parking.
                //         </>
                //     </div>
                // </div>
                // <div>
                //     <button id='divFive' className="btn btn-link" onClick={(e) => {
                //         hideShowDiv(e)
                //     }}>
                //         Does the garage have gates at entrances and exits?
                //     </button>
                // </div>
                // <div>
                //     <div className={isShown.id === 'divFive' ? 'divFive' : 'd-none'} style={{ paddingLeft: '20px' }}>
                //         <>	
                //             Yes, to enter you either take a ticket or scan your access card.
                //         </>
                //     </div>
                // </div>
                // <div>
                //     <button id='divSix' className="btn btn-link" onClick={(e) => {
                //         hideShowDiv(e)
                //     }}>
                //         Where can I park?
                //     </button>
                // </div>
                // <div>
                //     <div className={isShown.id === 'divSix' ? 'divSix' : 'd-none'} style={{ paddingLeft: '20px' }}>
                //         <>  Parking levels starting from the third floor and through the eighth are designated for employees. 
                //             Lot, first, and second levels are reserved for patients and visitors.
                //         </>
                //     </div>
                // </div>
                // <div>
                //     <button id='divSeven' className="btn btn-link" onClick={(e) => {
                //         hideShowDiv(e)
                //     }}>
                //         Where are the Exits
                //     </button>
                // </div>
                // <div>
                //     <div className={isShown.id === 'divSeven' ? 'divSeven' : 'd-none'} style={{ paddingLeft: '20px' }}>
                //         <>	Fort Washington or Riverside. 
                //             To exit onto Riversire, drive down to lowest level and exit from the parking lot.
                //         </>
                //     </div>
                // </div>
                // <div>
                //     <button id='divEight' className="btn btn-link" onClick={(e) => {
                //         hideShowDiv(e)
                //     }}>
                //         Who issues the access cards?
                //     </button>
                // </div>
                // <div>
                //     <div className={isShown.id === 'divEight' ? 'divEight' : 'd-none'} style={{ paddingLeft: '20px' }}>
                //         <>
                //             Proxy cards (access cards) issued by the Parking Office or the Manager’s Office.
                //         </>
                //     </div>
                // </div>
                // <div>
                //     <button id='divNine' className="btn btn-link" onClick={(e) => {
                //         hideShowDiv(e)
                //     }}>
                //         What do I do when I arrive or exit?
                //     </button>
                // </div>
                // <div>
                //     <div className={isShown.id === 'divNine' ? 'divNine' : 'd-none'} style={{ paddingLeft: '20px' }}>
                //         <>	
                //             Scan your access card (proxy card) against the card reader at the entrance or exit.
                //         </>
                //     </div>
                // </div>
                // <div>
                //     <button id='divTen' className="btn btn-link" onClick={(e) => {
                //         hideShowDiv(e)
                //     }}>
                //         Can I park my own car, or do I have to valet?
                //     </button>
                // </div>
                // <div>
                //     <div className={isShown.id === 'divTen' ? 'divTen' : 'd-none'} style={{ paddingLeft: '20px' }}>
                //         <>
                //             If self-parking spaces are available on the third through eighth floors, 
                //             you can self-park your car and take the keys. If all self-parking spaces are occupied, 
                //             valet service will be offered on certain levels, typically the fifth through seventh. 
                //             Please look for valet signs as you are traversing the floors
                //         </>
                //     </div>
                // </div>
                // <div>
                //     <button id='div11' className="btn btn-link" onClick={(e) => {
                //         hideShowDiv(e)
                //     }}>
                //         Can I park than one car at the same time?
                //     </button>
                // </div>
                // <div>
                //     <div className={isShown.id === 'div11' ? 'div11' : 'd-none'} style={{ paddingLeft: '20px' }}>
                //         <>
                //             No, you can only park one car at a time. You must exit the garage and then enter. 
                //             Two consecutive exits or entrances will be rejected. In other words, 
                //             you must exit before you enter again, and you must enter before you can exit. 
                //             A cycle of Exit followed by another Exit or Enter by followed by another Enter is not allowed.
                //         </>
                //     </div>
                // </div>
                // <div>
                //     <button id='div12' className="btn btn-link" onClick={(e) => {
                //         hideShowDiv(e)
                //     }}>
                //         Can I have more than one access card?
                //     </button>
                // </div>
                // <div>
                //     <div className={isShown.id === 'div12' ? 'div12' : 'd-none'} style={{ paddingLeft: '20px' }}>
                //         <>
                //             No, only one card can be assigned to an account.
                //         </>
                //     </div>
                // </div>
                // <div>
                //     <button id='div13' className="btn btn-link" onClick={(e) => {
                //         hideShowDiv(e)
                //     }}>
                //         What do I do if I lose my card?
                //     </button>
                // </div>
                // <div>
                //     <div className={isShown.id === 'div13' ? 'div13' : 'd-none'} style={{ paddingLeft: '20px' }}>
                //         <>
                //             Contact the parking office to request a new card. There is a charge for a new card.
                //         </>
                //     </div>
                // </div>
                // <div>
                //     <button id='div14' className="btn btn-link" onClick={(e) => {
                //         hideShowDiv(e)
                //     }}>
                //         Can I store my car in the garage?
                //     </button>
                // </div>
                // <div>
                //     <div className={isShown.id === 'div14' ? 'div14' : 'd-none'} style={{ paddingLeft: '20px' }}>
                //         <>
                //             No storage parking allowed. Parking is for commuters only and you must exit 
                //             the garage after your shift ends
                //         </>
                //     </div>
                // </div>
                // <div>
                //     <button id='div15' className="btn btn-link" onClick={(e) => {
                //         hideShowDiv(e)
                //     }}>
                //         Can I store my car in the garage?
                //     </button>
                // </div>
                // <div>
                //     <div className={isShown.id === 'div15' ? 'div15' : 'd-none'} style={{ paddingLeft: '20px' }}>
                //         <>
                //             No storage parking allowed. Parking is for commuters only and you must exit
                //             the garage after your shift ends
                //         </>
                //     </div>
                // </div> */}
    //         </div>
    //     </div>
    // );

//}

export default SouthProp;