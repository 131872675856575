import React from "react";
import { Link } from "react-router-dom";
import mainNavStyle from "../css/mainNavStyle.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBars,
  faX,
  faCaretRight
} from "@fortawesome/free-solid-svg-icons";
library.add(faBars, faX, faCaretRight);

function NestedNavBar() {
  return (
    <header className={mainNavStyle.mainHeader}>
      <input className={mainNavStyle.check} id="navToggle" type="checkbox" />
      <label for="navToggle" className={mainNavStyle.checkLabel}>
        <FontAwesomeIcon
          icon="fa-solid fa-bars"
          className="icons"
          size="2xl"
          id={mainNavStyle.openMenu}
        />
        <FontAwesomeIcon icon="fa-solid fa-x" size="xl" id={mainNavStyle.closeMenu} />
      </label>
      {/* Brand */}
      <Link to="/" className={mainNavStyle.imgLink}>
        <img
          src={require("../images/Parking Logo.jpg")}
          alt="Parking"
          className="logo"
        />
      </Link>

      <nav className="headerNav">
        <ul className="navUL">
          <li className="navItem">
            <Link id="navLink" className="navLink" to="/">
              Home
            </Link>
          </li>
          <li className="navItem">
            <Link id="navLink" className="navLink" to="./Policies">
              General Policies
            </Link>
          </li>
          <li className="navItem">
            <Link id="navLink" className="navLink" to="./ColumbiaLocTab">
              Columbia
            </Link>
          </li>
          <li className="navItem">
            <Link id="navLink" className={"navLink"} to="./AllenLocTab">
              Allen
            </Link>
          </li>
          <li className="navItem">
            <Link id="navLink" className="navLink" to="./CornellLocTab">
              Cornell
            </Link>
          </li>
          <li className="navItem">
            <Link id="navLink" className="navLink" to="./MethodistLocTab">
              Methodist
            </Link>
          </li>
          <li className="navItem">
            <Link id="navLink" className="navLink" to="./SpruceLocTab">
              Lower Manhattan
            </Link>
          </li>
          <li className="navItem">
            <Link id="navLink" className="navLink" to="./Applications">
              Applications
            </Link>
          </li>
          <li className="navItem">
            <Link id="navLink" className="navLink" to="./AltParkingTab">
              Alternate Parking
            </Link>
          </li>
          <li className={mainNavStyle.navItemRates}>
            <Link id={mainNavStyle.navLink} className={mainNavStyle.navLinkRates} to="/NestedNav">
              Rates{" "}
              <Link className={mainNavStyle.dropbtn}>
                <FontAwesomeIcon
                  icon={faCaretRight}
                  className="caret"
                  size="lg"
                />
              </Link>
              <ul className={mainNavStyle.subUl}>
                <li className={mainNavStyle.colLink}>
                  <Link to="./ColumbiaRateTable">Columbia Rates</Link>
                </li>
                <li className="allenLink">
                  <Link to="./AllenRateTable">Allen Rates</Link>
                </li>
                <li>
                  <Link to="CornellRateTable">
                    Cornell Rates
                  </Link>
                </li>
                <li>
                  <Link to="MethodistRateTable">
                    Methodist Rates
                  </Link>
                </li>
                <li>
                  <Link to="LowerManhattanRateTable">
                    Lower Manhattan Rates
                  </Link>
                </li>
                <li>
                  <Link to="ParkingCost">
                    Daily Rate Calculator
                  </Link>
                </li>
                <li>
                  <Link to="MonthlyProrateForm">
                    Prorate Calculator
                  </Link>
                </li>
              </ul>
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
}
export default NestedNavBar;
