import CardTemp from "./CardTemplate";
import cornellTabStyle from '../css/policiesStyle.module.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core"
import { faParking } from "@fortawesome/free-solid-svg-icons";
library.add(faParking)

function CornellTabContent() {
    return (
      <div className={cornellTabStyle.cardsDiv}>
        <div className={cornellTabStyle.homeCard}>
          <CardTemp
            cardLink="/CornellAnswersList"
            cardIcon={<FontAwesomeIcon icon={faParking} />}
            imgSrc={require("../images/Parking Symbol1.jpg")}
            imgTitle=" Cornell Parking Infomation"
            def1=<span className={cornellTabStyle.defSpan}>
              <span>Locations Count: </span>
              <span style={{ color: "blue" }}>Six</span>
            </span>
            def2=<span className={cornellTabStyle.defSpan}>
              <span>Hours (All Locations): </span>
              <span style={{ color: "blue" }}>24 Hours 7 Days</span>
            </span>
            def3=<span className={cornellTabStyle.defSpan}>
              <span>Waiting List: </span>
              <span style={{ color: "blue" }}>Yes</span>
            </span>
            def4=<span className={cornellTabStyle.defSpan}>
              <span>Wait Time Avg: </span>
              <span style={{ color: "blue" }}>18 Months</span>
            </span>
          />
        </div>

        <div className={cornellTabStyle.homeCard}>
          <CardTemp
            cardLink="/AnnexAnswersList"
            cardIcon={<FontAwesomeIcon icon={faParking} />}
            imgSrc={require("../images/Annex.jpg")}
            imgTitle=" Annex Building Garage"
            def1=<span className={cornellTabStyle.defSpan}>
              <span>Address: </span>
              <span style={{ color: "blue" }}>
                519 East 70th Street New York NY 1002
              </span>
            </span>
            def2=<span className={cornellTabStyle.defSpan}>
              <span>Hours: </span>
              <span style={{ color: "blue" }}>24 Hours 7 Days</span>
            </span>
            def3=<span className={cornellTabStyle.defSpan}>
              <span>Parking: </span>
              <span style={{ color: "blue" }}>Valet and Self-Park</span>
            </span>
          />
        </div>

        <div className={cornellTabStyle.homeCard}>
          <CardTemp
            cardLink="/ColemanAnswersList"
            cardIcon={<FontAwesomeIcon icon={faParking} />}
            imgSrc={require("../images/Coleman.jpg")}
            imgTitle=" Coleman Building Garage"
            def1=<span className={cornellTabStyle.defSpan}>
              <span>Address: </span>
              <span style={{ color: "blue" }}>
                1330 1st Avenue New York NY 10021
              </span>
            </span>
            def2=<span className={cornellTabStyle.defSpan}>
              <span>Hours: </span>
              <span style={{ color: "blue" }}>24 Hours 7 Days</span>
            </span>
            def3=<span className={cornellTabStyle.defSpan}>
              <span>Parking: </span>
              <span style={{ color: "blue" }}>Valet Only</span>
            </span>
          />
        </div>
        <div className={cornellTabStyle.homeCard}>
          <CardTemp
            cardLink="/DHKAnswersList"
            cardIcon={<FontAwesomeIcon icon={faParking} />}
            imgSrc={require("../images/DHK.jpg")}
            imgTitle=" David H Koch (DHK) Garage"
            def1=<span className={cornellTabStyle.defSpan}>
              <span>Address: </span>
              <span style={{ color: "blue" }}>
                445 East 68th Street New York NY 10065
              </span>
            </span>
            def2=<span className={cornellTabStyle.defSpan}>
              <span>Hours: </span>
              <span style={{ color: "blue" }}>24 Hours 7 Days</span>
            </span>
            def3=<span className={cornellTabStyle.defSpan}>
              <span>Parking: </span>
              <span style={{ color: "blue" }}>Valet Only</span>
            </span>
          />
        </div>
        <div className={cornellTabStyle.homeCard}>
          <CardTemp
            cardLink="/GreenbergAnswersList"
            cardIcon={<FontAwesomeIcon icon={faParking} />}
            imgSrc={require("../images/Greenberg.jpg")}
            imgTitle=" Greenberg Hospital Garage"
            def1=<span className={cornellTabStyle.defSpan}>
              <span>Address: </span>
              <span style={{ color: "blue" }}>
                525 East 68th Street New York NY 10021
              </span>
            </span>
            def2=<span className={cornellTabStyle.defSpan}>
              <span>Hours: </span>
              <span style={{ color: "blue" }}>24 Hours 7 Days</span>
            </span>
            def3=<span className={cornellTabStyle.defSpan}>
              <span>Parking: </span>
              <span style={{ color: "blue" }}>Valet Only</span>
            </span>
          />
        </div>

        <div className={cornellTabStyle.homeCard}>
          <CardTemp
            cardLink="/HelmsleyAnswersList"
            cardIcon={<FontAwesomeIcon icon={faParking} />}
            imgSrc={require("../images/Helmsley.jpg")}
            imgTitle=" Helmsley Tower Garage"
            def1=<span className={cornellTabStyle.defSpan}>
              <span>Address: </span>
              <span style={{ color: "blue" }}>
                515 East 70th Street New York NY 10021
              </span>
            </span>
            def2=<span className={cornellTabStyle.defSpan}>
              <span>Hours: </span>
              <span style={{ color: "blue" }}>24 Hours 7 Days</span>
            </span>
            def3=<span className={cornellTabStyle.defSpan}>
              <span>Parking: </span>
              <span style={{ color: "blue" }}>Valet Only</span>
            </span>
          />
        </div>

        <div className={cornellTabStyle.homeCard}>
          <CardTemp
            cardLink="/PaysonAnswersList"
            cardIcon={<FontAwesomeIcon icon={faParking} />}
            imgSrc={require("../images/Payson.jpg")}
            imgTitle=" Payson House Garage"
            def1=<span className={cornellTabStyle.defSpan}>
              <span>Address: </span>
              <span style={{ color: "blue" }}>
                425 East 71th Street New York NY 10021
              </span>
            </span>
            def2=<span className={cornellTabStyle.defSpan}>
              <span>Hours: </span>
              <span style={{ color: "blue" }}>24 Hours 7 Days</span>
            </span>
            def3=<span className={cornellTabStyle.defSpan}>
              <span>Parking: </span>
              <span style={{ color: "blue" }}>Valet Only</span>
            </span>
          />
        </div>
      </div>
    );
}
export default CornellTabContent;