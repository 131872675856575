function CreateSelect(targetVal, targetList,targetId) {
  //Create this var if you are creating select from scratch
    // var myParent = document.body;

  //Create and append select list
  // var selectList = document.createElement("select");
  var selectList = document.getElementById(targetId);
  // selectList.id = "parktype";
  //myParent.appendChild(selectList);

  //Clear select options
  document.getElementById(targetId).innerHTML = "";

  //Create and append options
  for (var i = 0; i < targetList.length; i++) {
    if (targetList[i].locations === targetVal && targetList[i].los==="M") {
    //   console.log(targetList.length);
      var optionList = document.createElement("option");
      optionList.value = targetList[i].desc;
      optionList.text = targetList[i].desc;
      selectList.appendChild(optionList);
    }
  }
}
export default CreateSelect;
