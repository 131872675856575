import React from "react";
import { useMemo } from "react";
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
  useFilters
} from "react-table";
import AllenRatesTableData from './AllenRateTableData.json'
import COLUMNS from "./TableColumns";
// tableStyles is a var that will be used in the function to style
import AllenTableStyle from "../css/tableStyle.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowCircleDown,
  faArrowCircleUp
} from "@fortawesome/free-solid-svg-icons";
import { GlobalFilter } from "./GlobalFilter";
// import RatesNavBar from "./NestedNav";


library.add(faArrowCircleDown, faArrowCircleUp);

const AllenBasicRateTable = () => {
  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => AllenRatesTableData, []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    prepareRow,
    pageOptions,
    gotoPage,
    setPageSize,
    pageCount,
    state,
    setGlobalFilter
  } = useTable(
    {
      columns,
      data
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { globalFilter, pageSize } = state;
  const { pageIndex } = state;
  return (
    <>
      <div className="container row" style={{ fontSize: "12px" }}>
        {/* <RatesNavBar bottomDist='10px'/> */}
        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
        {/* <LocationFilter filter={globalFilter} setFilter={setGlobalFilter} /> */}
        <table {...getTableProps}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    {/* Do not filter columns
                    <div>
                      {column.canFilter ? column.render("Filter") : null}{" "}
                    </div> */}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FontAwesomeIcon icon={faArrowCircleDown} />
                        ) : (
                          <FontAwesomeIcon icon={faArrowCircleUp} />
                        )
                      ) : (
                        ""
                      )}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div>
          <span>
            <strong>
              Page {pageIndex + 1} of {pageOptions.length}
            </strong>{" "}
          </span>
          <span>
            | Go to page:{" "}
            <input
              className={AllenTableStyle.navCtrls}
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const pageNumber = e.target.value
                  ? Number(e.target.value) - 1
                  : 0;
                gotoPage(pageNumber);
              }}
            />
          </span>
          <select
            className={AllenTableStyle.navCtrls}
            value={pageSize}
            onChange={(e) => setPageSize(e.target.value)}
          >
            {[10, 25, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </select>
          <button
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
            className={AllenTableStyle.navCtrls}
          >
            {"<<"}
          </button>
          <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className={AllenTableStyle.navCtrls}
          >
            Previous
          </button>
          <button
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className={AllenTableStyle.navCtrls}
          >
            Next
          </button>
          <button
            className={AllenTableStyle.navCtrls}
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {">>"}
          </button>
        </div>
        <br />
        <br />
        <br />
      </div>
    </>
  );
};
export default AllenBasicRateTable;
