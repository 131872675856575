import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
//import { useState } from "react";
import SouthProp from './SP';
// import { Typography } from "@mui/material";
//import LocalParkingSharpIcon from '@mui/icons-material/LocalParkingSharp';
//import { Key } from "@mui/icons-material";
// import BuildBootStrapMenu from "./DisplayMenu";
//import CreateButtons from "../components/MenuButtons";
// import * as Icon from 'react-bootstrap-icons';
import LocAnswerFooter from './AnswersFooter';
import LocGoogleMap from './LocGoogleMap';
import LocPageFooter from './PageFooter';

function LocAnswersAllen() {
	// <BuildBootStrapMenu />
	const FacList = [
		{
			id: 'div5',
			quest: 'How many floors?',
			answer: 'Surface Lot.'
		},

		{
			id: 'div6',
			quest: 'Does the lot have gates at entrances and exits?',
			answer: 'Yes, to enter and exit you must scan your access card.'
		},
		{
			id: 'div7',
			quest: 'Does the lot have cars stackers or lifts?',
			answer: 'No.'
		},
		{
			id: 'div8',
			quest: 'Can I choose where I park?',
			answer:
				'Valet staff will park the car. If perimeter space is available, you can self park and take your keys.'
		},
		{
			id: 'div9',
			quest: ' Who issues the access cards?',
			answer:
				'Proxy cards (access cards) issued by the Parking Office. Cards can be picked up from the Manager’s Office.'
		},
		{
			id: 'div10',
			quest: ' What do I do when I arrive or exit?',
			answer:
				'Scan your access card (proxy card) against the card reader at the entrance or exit.'
		},
		{
			id: 'div11',
			quest: ' What do I do when I arrive or exit?',
			answer:
				'Scan your access card (proxy card) against the card reader at the entrance or exit.'
		},
		{
			id: 'div12',
			quest: 'How do I retrieve my car?',
			answer:
				'Hand over the ticket that was issued when you entered to the parking location staff, and they will retrieve the car.'
		},
		{
			id: 'div13',
			quest: 'Can I have more than one car at the same time in the lot?',
			answer:
				'No, you can only park one car at a time. You must exit the lot and then enter. Two consecutive exits or entrances will be rejected. In other words, you must exit before you enter again, and you must enter before you can exit. A cycle of Exit followed by another Exit or Enter by followed by another Enter is not allowed.'
		}
	];

	const opsList = [
		{
			id: 'div12',
			quest: 'Can I have more than one access card?',
			answer: 'No, only one card can be assigned to an account.'
		},
		{
			id: 'div13',
			quest: 'What do I do if I lose my card or my card stops working?',
			answer:
				'Contact the manager immediately. There is a charge for a new card.'
		},
		{
			id: 'div14',
			quest: 'Can I store my car in the lot?',
			answer:
				' No storage parking allowed. Parking is for commuters only and you must exit the lot after your shift ends?'
		},
		{
			id: 'div15',
			quest:
				'Can I park my car if I live in NYP housing or neighboring zip codes?',
			answer:
				'No. NYP housing residents are not allowed to use NYP parking. This restriction also includes employees living in neighboring zip codes.'
		},
		{
			id: 'div16',
			quest: 'Is there space available for new monthly accounts?',
			answer: 'Yes. Click Forms -> New Account Application to apply.'
		},
		{
			id: 'div17',
			quest: 'What do I do when my card does not open the gate?',
			answer:
				'If you have an active account and your account is in good standing, the attendant will let you in. You must contact the manager before you exit.'
		},
		{
			id: 'div18',
			quest: "Can I park if I don't have a monthly account?",
			answer:
				'Yes. public parking spaces are available across from the hospital entrance. Please note that public parking spaces are reserved for patients and visitors.'
		},
		{
			id: 'div19',
			quest: 'Does the lot accept SUVs and small vans?',
			answer: 'Yes.'
		},
		{
			id: 'div20',
			quest: 'Does the lot accept shuttle size vans?',
			answer: 'No.'
		},
		{
			id: 'div22',
			quest: 'Do you have discounted daily rates for employees?',
			answer:
				'	No. Employees who do not have an active monthly account must pay the full transient rate'
		}
	];
	// const [isShown, setIsShown] = useState(false, { id: 'divOne' });
	// const hideShowDiv = (e) => {
	//     setIsShown({ id: e.target.id }, !isShown);
	//     divName = e.target.id;
	//     console.log (divName);

	//  };
	const FinalList = FacList.map((targetDivs, divId) => (
		<SouthProp key={targetDivs.id} targetDivs={targetDivs} divId={divId} />
	));
	const OperationList = opsList.map((targetDivs, divId) => (
		<SouthProp key={targetDivs.id} targetDivs={targetDivs} divId={divId} />
	));

	var targetLocLat = 40.87249341731169;
	var targetLocLng = -73.9118175180286;
	return (
		<div style={{ backgroundColor: 'white' }} id="topDiv">
			<div className="container-fluid">
				<div className="row">
					{/* <div className="col-auto"> */}
					{/* <BuildBootStrapMenu /> */}
					{/* <CreatSidebar /> */}
					{/* < CreateButtons /> */}
					{/* </div> */}
					<div>
						<LocGoogleMap locLat={targetLocLat} locLng={targetLocLng} />
					</div>
					<div>
						<table
							className="table table-striped table-bordered"
							style={{ border: 'ridge', marginLeft: '-0px' }}
						>
							<thead>
								<tr>
									<th id="thID" colSpan="2">
										<span style={{ color: 'blue' }}>
											Allen Employees Lot - 4168 9th Avenue New NY 10034
										</span>
									</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td className="text-start" style={{ fontWeight: 'bold' }}>
										Lot Address:
									</td>
									<td className="text-start">
										<span style={{ color: 'blue' }}>
											4168 9th Avenue New NY 10034. For those using a navigation
											system, you can also input 5140 Broadway New York NY
											10034.
										</span>
									</td>
								</tr>
								<tr>
									<td className="text-start" style={{ fontWeight: 'bold' }}>
										Lot Name:
									</td>
									<td className="text-start">
										<span style={{ color: 'blue' }}>
											Allen Hospital Employees Lot{' '}
										</span>
									</td>
								</tr>
								<tr>
									<td className="text-start" style={{ fontWeight: 'bold' }}>
										Also Known As:
									</td>
									<td className="text-start">
										<span style={{ color: 'blue' }}>
											Allen Hospital Employees Lot
										</span>
									</td>
								</tr>
								<tr>
									<td className="text-start" style={{ fontWeight: 'bold' }}>
										License:
									</td>
									<td className="text-start">
										<span style={{ color: 'blue' }}>
											Employees only. No public parking allowed.
										</span>
									</td>
								</tr>
								<tr>
									<td className="text-start" style={{ fontWeight: 'bold' }}>
										Hours:
									</td>
									<td className="text-start">
										<span style={{ color: 'blue' }}>
											Lot is open 24 hours 7 days a week
										</span>
									</td>
								</tr>
								<tr>
									<td className="text-start" style={{ fontWeight: 'bold' }}>
										Entrance:
									</td>
									<td className="text-start">
										<span style={{ color: 'blue' }}>
											9th Avenue. Turn right on Broadway.
										</span>
									</td>
								</tr>
								<tr>
									<td className="text-start" style={{ fontWeight: 'bold' }}>
										Exits:
									</td>
									<td className="text-start">
										<span style={{ color: 'blue' }}>9th Avenue.</span>
									</td>
								</tr>
								<tr>
									<td className="text-start" style={{ fontWeight: 'bold' }}>
										Operation Type:
									</td>
									<td className="text-start">
										<span style={{ color: 'blue' }}>
											Valet. Self-Park around the perimeter is allowed, if space
											is available.
										</span>
									</td>
								</tr>
							</tbody>
						</table>
						<LocPageFooter list1={FinalList} />
						<LocAnswerFooter list1={OperationList} />
					</div>
				</div>
			</div>
		</div>
	);
}

export default LocAnswersAllen;
