import React from "react";
import { Link, Outlet } from "react-router-dom";
import ratesStyle from  "../css/ratesTableNav.module.css";
// import { LocationFilter } from "./LocationRateFilter";
// import { useState } from "react";

function RatesNavBar(props) {
  return (
    <header className={ratesStyle.ratesHeader}>
      <nav className={ratesStyle.ratesNav}>
        <ul className={ratesStyle.nestedNav}>
          <li>
            <Link to="./">
              All Rates
            </Link>
          </li>
          <li>
            <Link to="./ColumbiaRateTable">
              Columbia Rates
            </Link>
          </li>
          <li>
            <Link  to="AllenRateTable">
              Allen Rates
            </Link>
          </li>
          <li>
            <Link to="CornellRateTable">
              Cornell Rates
            </Link>
          </li>
          <li>
            <Link to="MethodistRateTable">
              Methodist Rates
            </Link>
          </li>
          <li>
            <Link to="LowerManhattanRateTable">
              Lower Manhattan Rates
            </Link>
          </li>
          <li>
            <Link to="ParkingCost">
              Daily Rate Calculator
            </Link>
          </li>
          <li>
            <Link to="MonthlyProrateForm">
              Prorate Calculator
            </Link>
          </li>
        </ul>
      </nav>
      {/* Needed for nested bar to work */}
      <Outlet />
    </header>
  );
}
export default RatesNavBar;
