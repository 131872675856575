import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
//import { useState } from "react";
import SouthProp from './SP';
import { Typography } from "@mui/material";
//import LocalParkingSharpIcon from '@mui/icons-material/LocalParkingSharp'; 
//import { Key } from "@mui/icons-material";
// import BuildBootStrapMenu from "./DisplayMenu";
//import CreateButtons from "../components/MenuButtons";
// import * as Icon from 'react-bootstrap-icons';
import LocAnswerFooter from "./AnswersFooter";
import LocPageFooter from "./PageFooter";
import LocGoogleMap from "./LocGoogleMap";
import { Link } from 'react-router-dom'

function LocAnswersHelmsley (){
    // <BuildBootStrapMenu />
    const FacList = [
    
         {
            id: 'div5',
            quest: 'How many floors?',
            answer: 'Three levels.'
        },
        {
            id: 'div18',
            quest: 'Does the garage have a passenger elevator?',
            answer: 'No.'
        },
        {
            id: 'div6',
            quest: 'Does the garage have gates at entrances and exits?',
            answer: 'No.'
        },
        {
            id: 'div18',
            quest: 'What do I need to access the garage?',
            answer: 'Cars will be issued an adhesive RFID tag that should be affixed to the rear-view mirror. The tag will be read on entry and exit.'
        },
        {
            id: 'div18',
            quest: 'How are the parking floors accessed?',
            answer: 'The floors are ramp accessible. No elevators.'
        },
        
        {
            id: 'div21',
            quest: 'Does the garage have car stackers or lifts?',
            answer: 'No lifts or stackers.'
        },
        {
            id: 'div9',
            quest: 'How do I retrieve my car?',
            answer: 'Hand over the ticket that was issued when you entered to the parking location staff, and they will retrieve the car.'
        },
        {
            id: 'div10',
            quest: 'Can I park my own car, or do I have to valet?',
            answer: 'No. The garage offers valet service only.'
        },
        {
            id: 'div10',
            quest: 'Can I choose where I park?',
            answer: 'Valet staff parks the car based on space availability and departure time.'
        },
        {
            id: 'div11',
            quest: 'Can I have more than one car at the same time in the garage?',
            answer: 'No, you can only park one car at a time.'
        },
        {
            id: 'div8',
            quest: 'Who issues the RFID tag?',
            answer: 'RFID tags (acces tags) are issued by the Parking Office.'
        }
    ]

    const opsList = [
        {
            id: 'div12',
            quest: 'Can I have more than one access tag (RFID tag)?',
            answer: 'Yes. No more than 2 tags can be issued. Please contact the parking office for more details?'
        },
        {
            id: 'div13',
            quest: 'What if I lose my RFID tag, change my car or the tag stops working?',
            answer: 'Contact the parking office to request a new access tag. There is a charge for a tag replacement.'
        },
        {
            id: 'div14',
            quest: 'Can I store my car in the garage (storage parking is parking a car in the garage for more than 24 hours)?',
            answer: 'NYP Housing buildings residents who have storage monthly accounts are permitted to store their cars. Commuters must exit the garage after their work shift ends.'
        },
        {
            id: 'div15',
            quest: 'Can I apply for parking if I live in neighboring zip codes?',
            answer: 'No. Employees who reside in buildings located in 10103, 10020, 10112, 10111,  10152, 10154, 10029, 10128, 10028, 10075, 10162, 10021, 10022, 10065 and 10153 zip codes are not allowed to park in NYP parking garages.'
        },
        {
            id: 'div17',
            quest: 'What do I do when my RFID tag (access tag) does not work?',
            answer: 'If your tag is valid and your account in good standing, the staff will issue you a replacement ticket. You will be required to provide a contact number, if one is not available.'
        },
        {
            id: 'div16',
            quest: 'Is there space available for new monthly accounts?',
            answer: 'No. Currenlty we have a waiting list and the average wait is over 1 year. You must apply for parking to be added to the waiting list,'
        },
        {
            id: 'div18',
            quest: 'Can I park if I don\'t have a monthly account?',
            answer: 'Yes. You must take a ticket. Transient daily charges will apply.'
        },
        {
            id: 'div19',
            quest: 'Does the garage accept SUVs and small vans?',
            answer: 'Yes, but there is height restriction. Please remove all racks from your car’s roof before entering the garage.'
        },
        {
            id: 'div20',
            quest: 'Does the garage accept shuttle size vans?',
            answer: 'No. Large vans cannot be accommodated due to height limits and parking space sizes.'
        },  
        {
            id: 'div22',
            quest: 'Do you have discounted daily rates for employees?',
            answer: 'No. Contact the garage or the parking office for rates.'
        },
    ]
    
    const FinalList = FacList.map((targetDivs,divId) => (
        <SouthProp key={targetDivs.id}  targetDivs={targetDivs} divId={divId} />
    ));
    const OperationList = opsList.map((targetDivs) => (
        <SouthProp key={targetDivs.id} targetDivs={targetDivs} />
    ));

    var targetLat = 40.76557276703977; 
    var targetLng = -73.95400684686884;

    return (
      <div style={{ backgroundColor: "white" }} id="topDiv">
        <div className="container-fluid">
          <div>
            <Typography
              variant="h6"
              style={{ backgroundColor: "white", textAlign: "start" }}
              sx={{ marginTop: "2px", paddingTop: "2px", paddingBottom: "5px" }}
            >
              Click <Link to="/CornellAnswersList">here</Link> for information
              that applies to all Cornell Campus parking facilities.
            </Typography>
          </div>
          <div className="row">
            <div>
              <LocGoogleMap locLat={targetLat} locLng={targetLng} />
            </div>
            <div>
              <table
                className="table table-striped table-bordered"
                style={{ border: "ridge", marginLeft: "-0px" }}
              >
                <thead>
                  <tr>
                    <th id="thID" colSpan="2">
                      <span style={{ color: "blue" }}>
                        Helmsley Medical Tower Garage - 515 East 70th Street New
                        York NY 10021
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      className="firsttd text-start"
                      style={{ fontWeight: "bold" }}
                    >
                      Garage Address:
                    </td>
                    <td className="text-start">
                      <span style={{ color: "blue" }}>
                        515 East 70th Street New York NY 10021
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-start" style={{ fontWeight: "bold" }}>
                      Garage Name:
                    </td>
                    <td className="text-start">
                      <span style={{ color: "blue" }}>
                        Helmsley Medical Tower Garage{" "}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-start" style={{ fontWeight: "bold" }}>
                      Also Known As:
                    </td>
                    <td className="text-start">
                      <span style={{ color: "blue" }}>Helmsley Garage</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-start" style={{ fontWeight: "bold" }}>
                      License:
                    </td>
                    <td className="text-start">
                      <span style={{ color: "blue" }}>
                        Public. Garage accepts patients and visitors.
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-start" style={{ fontWeight: "bold" }}>
                      Hours:
                    </td>
                    <td className="text-start">
                      <span style={{ color: "blue" }}>
                        24 hours 7 days a week.
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-start" style={{ fontWeight: "bold" }}>
                      Entrance:
                    </td>
                    <td className="text-start">
                      <span style={{ color: "blue" }}>
                        Entrance on 70th Street between York and FDR Drive.
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-start" style={{ fontWeight: "bold" }}>
                      Exits:
                    </td>
                    <td className="text-start">
                      <span style={{ color: "blue" }}>
                        Exit on 71th Street between York and FDR Drive.
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-start" style={{ fontWeight: "bold" }}>
                      Contact Infomation:
                    </td>
                    <td className="text-start">
                      <span style={{ color: "blue" }}>(212) 746-1974</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-start" style={{ fontWeight: "bold" }}>
                      Operation Type:
                    </td>
                    <td className="text-start">
                      <span style={{ color: "blue" }}>Valet.</span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <LocPageFooter list1={FinalList} />
              <LocAnswerFooter list1={OperationList} />
            </div>
          </div>
        </div>
      </div>
    );
}

export default LocAnswersHelmsley;