

function ProrateWeeklyCalc(
  date1,
  ratesList,
  selectCampus,
  selectLocation,
  selectParkType
) {
  var rateCur = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
  });

  // const midMonthStartDay = 15;

  var dateOptions = {
    weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric"
  };

  var startDate = 0;
  var firstPeriodCost = 0;
  var firstPeriodEndDate;
  var secondPeriodEndDate;
  var secondPeriodStartDate;
  var secondPeriodCost = 0;
  var dayAvgRate = 0;
  var finalCost = 0;

  const wklyPeriodDays = 7;
  const maxDaysInMonth = 31;
  //const maxDaysInPeriod = 9;
  const wklyDivider = 28;

  date1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate() + 1);

  var endOfMonthDay = new Date(date1.getFullYear(), date1.getMonth() + 1, 0);
  // console.log(endOfMonthDay, "End of Month");

  secondPeriodStartDate =
    date1.getDate() <= wklyPeriodDays //start date is from 1st to 7th
      ? new Date(date1.getFullYear(), date1.getMonth(), wklyPeriodDays + 1)
      : date1.getDate() > wklyPeriodDays &&
        date1.getDate() <= wklyPeriodDays * 2 //start date is from 8th to 14th
      ? new Date(date1.getFullYear(), date1.getMonth(), wklyPeriodDays * 2 + 1)
      : date1.getDate() > wklyPeriodDays * 2 &&
        date1.getDate() <= wklyPeriodDays * 3 //start date is from 15ths to 21st
      ? new Date(date1.getFullYear(), date1.getMonth(), wklyPeriodDays * 3 + 1)
      : new Date(
          endOfMonthDay.getFullYear(),
          endOfMonthDay.getMonth(),
          endOfMonthDay.getDate() + 1
        );

  
  console.log(maxDaysInMonth % date1.getDate(),"End of First Period");
  firstPeriodEndDate =
  date1.getDate() <= 21
  ? new Date(
    secondPeriodStartDate.getFullYear(),
    secondPeriodStartDate.getMonth(),
    secondPeriodStartDate.getDate() - 1)
    : endOfMonthDay
  ;


  secondPeriodEndDate =
    date1.getDate() <= wklyPeriodDays*2 //start date before ar at the 21st
      ? new Date(
          firstPeriodEndDate.getFullYear(),
          firstPeriodEndDate.getMonth(),
          firstPeriodEndDate.getDate() + wklyPeriodDays
        )
      : date1.getDate() > wklyPeriodDays*2 && date1.getDate() <= wklyPeriodDays*3 //start date from the 15th and the 21st
      ? endOfMonthDay
      : new Date(secondPeriodStartDate.getFullYear(),secondPeriodStartDate.getMonth(), wklyPeriodDays) //Ends the following month on the 7th

  //to get days diff between end of month and start date
  var firstPeriodDays =
    date1.getDate() > 21 && date1.getDate() <=28 //Date in after the 21st of the month
      ? parseInt(wklyDivider - date1.getDate() + 1) // / 1000 / 60 / 60 / 24 + 1)
      : date1.getDate() > 14 && date1.getDate() <= 21 //date is between the 15th and the 21st 
      ? (wklyPeriodDays * 3 + 1) -  date1.getDate() 
      : date1.getDate() > 7 && date1.getDate() <= 14 //start date is between the 8th and the 14th
      ? (wklyPeriodDays*2 + 1) - date1.getDate()
      : date1.getDate() > 28 
      ? (endOfMonthDay.getDate() - date1.getDate()) + 1  


      : wklyPeriodDays - date1.getDate() + 1; //start date is between the 1st and the 7th

      console.log(firstPeriodDays,date1.getDate(),"First period days")
  // console.log(midMonthStartDay, date1.getDate());
  for (let i = 0; i < ratesList.length; i++) {
    // console.log(endOfMonthDay);

    if (
      ratesList[i].los === "M" &&
      ratesList[i].campus === selectCampus &&
      ratesList[i].locations === selectLocation &&
      ratesList[i].desc === selectParkType
    ) {
      //   console.log(selectCampus, selectLocation, selectParkType);
      dayAvgRate = ratesList[i].rate / wklyDivider; //28 days

      firstPeriodCost =
        date1.getDate() > wklyPeriodDays * 4 //start after the 28th
          ? (ratesList[i].rate / endOfMonthDay.getDate()) *
            (endOfMonthDay.getDate() - wklyPeriodDays * 4)
          : dayAvgRate * firstPeriodDays;

      secondPeriodCost =ratesList[i].rate /4

      startDate = new Date(
        date1.getFullYear(),
        date1.getMonth(),
        date1.getDate()
      );
      finalCost =
        "Start on " +
        new Date(startDate).toLocaleString("en-US", dateOptions) +
        "." +
        "\n" +
        "There are " +
        (firstPeriodDays) +
        " prorated days in the first period starting " +
        startDate.toLocaleString("en-US", dateOptions) +
        " and ending " +
        firstPeriodEndDate.toLocaleDateString("en-US", dateOptions) +
        "." +
        "\n" +
        "First period cost is " +
        rateCur.format(firstPeriodCost) +
        "." +
        "\n" +
        "Second period starts on " +
        secondPeriodStartDate.toLocaleString("en-US", dateOptions) +
        " and ends on " +
        secondPeriodEndDate.toLocaleDateString("en-US", dateOptions) +
        "." +
        "\n" +
        "There are " +
        (secondPeriodEndDate.getDate() - secondPeriodStartDate.getDate() + 1) +
        " days in the second period." +
        "\n" +
        "Second period cost is " +
        rateCur.format(secondPeriodCost) +
        "." +
        "\n" +
        "Total prorate to be paid before account is activated: " +
        rateCur.format(secondPeriodCost + firstPeriodCost);
    }
  }
  return (
    // <div>
    //   <table className="table table-bordered">
    //     <thead>
    //       <tr>
    //         <th>Descrition</th>
    //         <th>Result</th>
    //       </tr>
    //     </thead>
    //     <tbody>
    //       <tr>
    //         <td>Parking starts</td>
    //         <td>new Date(startDate).toLocaleString("en-US", dateOptions)</td>
    //       </tr>
    //       <tr>
    //         <td>First period start date</td>
    //         <td>startDate.toLocaleString("en-US", dateOptions)</td>
    //       </tr>
    //       <tr>
    //         <td>First period end date</td>
    //         <td>firstPeriodEndDate.toLocaleDateString("en-US", dateOptions)</td>
    //       </tr>
    //       <tr>
    //         <td>Amount due for first period</td>
    //         <td> rateCur.format(firstPeriodCost)</td>
    //       </tr>
    //       <tr>
    //         <td>Second period start date</td>
    //         <td>nextPeriodStartDate.toLocaleString("en-US", dateOptions)</td>
    //       </tr>
    //       <tr>
    //         <td>Second period end date</td>
    //         <td>
    //           {" "}
    //           secondPeriodEndDate.toLocaleDateString("en-US", dateOptions)
    //         </td>
    //       </tr>
    //       <tr>
    //         <td>Amount due for second period</td>
    //         <td> rateCur.format(secondPeriodCost)</td>
    //       </tr>
    //       <tr>
    //         <td>Total prorate due</td>
    //         <td> rateCur.format(secondPeriodCost + firstPeriodCost)</td>
    //       </tr>
    //     </tbody>
    //   </table>
    // </div>
    finalCost
  );
}

export default ProrateWeeklyCalc;
