import PaperTemp from "./PaperTemplate";
import knowpage from "../css/knowFirst.module.css";

function MainTable() {
  return (
    <div className={knowpage.topDiv}>
      <section className={knowpage.headingSection}>
        <h2 className={knowpage.heading}>
          What you need to know before applying for a parking account.
          <h4>General rules that apply to all campuses.</h4>
        </h2>
      </section>

      <div className={knowpage.paper}>
        <PaperTemp
          rule1="1) All NYP employees are eligible to apply for a parking monthly account.
                  Space availability is not guaranteed due to the presence of waiting lists.
                  Assignments from the waiting lists are determined based on the date we received
                  the monthly application."
        />
      </div>
      <div className={knowpage.paper}>
        <PaperTemp
          rule1="2) Parking Department only accepts the online monthly application.
            All other monthly account applications are no longer accepted."
        />
        <a href="https://app.smartsheet.com/b/form/481e30a27a234286a8d952537fba8e39">
          Click here to access the online monthly application form.
        </a>
      </div>

      <div className={knowpage.paper}>
        <PaperTemp
          rule1='3) The NYP Parking Department follows the "One Employee, One Account" rule.
                    Only the account registered owner is permitted to park in the garage to which the employee has been assigned.'
        />
      </div>
      <div className={knowpage.paper}>
        <PaperTemp
          rule1='4) The "One Account, One Garage" policy is also followed by the NYP Parking Department.
                    Employees are only permitted to park in the garage to which they have been assigned.
                    If an employee parks in a location other than the one to which he or she has been assigned, 
                    transient rates will apply.
'
        />
      </div>
      <div className={knowpage.paper}>
        <PaperTemp
          rule1="5) Money is deducted from the paychecks of NYP employees who elect to participate in
                  the NYP payroll deduction program on a weekly or biweekly basis. 
                  If an employee parks at least one day during the period, the Parking Department 
                  will withhold a complete period from their paycheck."
        />
      </div>

      <div className={knowpage.paper}>
        <PaperTemp
          rule1="6) Parking is available at NYP for patients, visitors, and employees. 
                  Before a parking account can be opened for contractors, travel nurses,
                  or vendors, their parking applications must be approved by the Parking 
                  Operations Director."
        />
      </div>
      <div className={knowpage.paper}>
        <a href="https://app.smartsheet.com/b/form/6fe537dde0794a30ac39536e0ccd3a5a">
          <PaperTemp
            rule1="7) If the employee decides to cancel the monthly parking account, it is the employee
                    responsibility to notify the Parking Office. Click here to  
                    to access the cancellation form."
          />
        </a>
      </div>
      <div className={knowpage.paper}>
        <a href="https://app.smartsheet.com/b/form/3e25fe741ade4a9587749f1c9c87cdae">
          <PaperTemp
            rule1="8) It is also the employee responsibility to notify the parking office of 
                    any changes to the parking account or vehicle information. Click here 
                    to access the account change form."
          />
        </a>
      </div>
    </div>
  );
}
export default MainTable;
