import { library } from '@fortawesome/fontawesome-svg-core';
import {
	faCircleInfo,
	faCircleQuestion,
	faMessage,
	faParagraph,
	faQuestion
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';
import stylePolicy from '../css/policiesStyle.module.css';
import CardTemp from './CardTemplate';
import SouthProp from './SP';
//import PaperTemp from "./PaperTempllate";
library.add(faQuestion, faMessage, faParagraph, faCircleInfo, faCircleQuestion);

const GeneralList = [
	{
		quest:
			' - When I return from LOA, do I resume my parking at the same parking facility that I used before my LOA began?',
		answer:
			'Yes, employees who return from LOA park at the same parking facility where they parked before going on LOA. Moreover, their parking access and restrictions will remain unchanged.',
		id: 'div1'
	},
	{
		quest: 'Is there a prorate amount that I must pay when I open an account?',
		answer:
			'Yes, a prorated amount must be paid upon opening an account. The amount due is shown on a rate breakdown sheet that the parking team must email to you. Prorate calculations differ based on whether the employee gets paid weekly or biweekly.',
		id: 'div2'
	},

	{
		quest: 'How do I pay my parking fee each month?',
		answer:
			'It is recommended that all employees participate in the NYP payroll deduction program. An employee can request that he or she be billed each month. Employees who prefer to be billed must pay their parking fee before the fifth business day of each month.',
		id: 'div3'
	},
	{
		quest: 'Can I suspend my account?',
		answer:
			'Employees who are not planning to take a leave of absence (LOA) may cancel their accounts at any time, but the cancellation will take effect on the 15th or at the end of the month. In other words, if you park for at least one day between the 1st and 15th of the month, or from the 16th until the end of the month, you will be charged for the whole period. Accounts can only be suspended for employees who are going on LOA. Parking Office personnel may request paperwork to confirm whether a LOA has been authorized.',
		id: 'div4'
	},
	{
		quest:
			'If I park at one campus, do I have access to other campuses’ parking',
		answer:
			'No. Because of waiting lists, we do not grant access to other campuses. Your access is limited to the Campus and location to which you have been assigned.',
		id: 'div5'
	}
];

function GeneralRules() {
	const GenList = GeneralList.map((targetDivs, divId) => (
		<SouthProp key={targetDivs.id} targetDivs={targetDivs} divId={divId} />
	));

	return (
		<div className={stylePolicy.topCardDiv}>
			{/* <BuildBootStrapMenu /> */}
			<div className={stylePolicy.divNotice}>
				<p className={stylePolicy.heading}>
					What you should know after your account has been activated.
					<p className={stylePolicy.heading}>
						General policies that apply to all campuses
					</p>
				</p>
			</div>
			<div className={stylePolicy.cardsDiv} id="cards">
				<CardTemp
					imgShow="false"
					// imgSrc={require("./FAQ.jpg")}
					imgTitle=<span>{''}</span>
					def1=<span>
						{' '}
						<h5 className={stylePolicy.question}>
							<FontAwesomeIcon icon={faCircleQuestion} /> When I return from
							LOA, do I resume my parking at the same parking facility that I
							used before my LOA began?
						</h5>
						<h5 className={stylePolicy.cardText}>
							<FontAwesomeIcon icon={faCircleInfo} /> Yes, employees who return
							from LOA park at the same parking facility where they parked
							before going on LOA. Moreover, their parking access and
							restrictions will remain unchanged.
						</h5>
					</span>
				/>
				<CardTemp
					// cardIcon={<FontAwesomeIcon icon={faCircleQuestion} />}
					imgTitle=<span>{''}</span>
					imgShow="false"
					def1=<span>
						{' '}
						<h5 className={stylePolicy.question}>
							<FontAwesomeIcon icon={faCircleQuestion} /> Is there a prorate
							amount that I must pay when I open an account?
						</h5>
						<h5 className={stylePolicy.cardText}>
							{<FontAwesomeIcon icon={faCircleInfo} />} Yes, a prorated amount
							must be paid upon opening an account. The amount due is shown on a
							rate breakdown sheet that the parking team must email to you.
							Prorate calculations differ based on whether the employee gets
							paid weekly or biweekly.{' '}
						</h5>
						<Link to="/MonthlyProrateForm" className={stylePolicy.cardLink}>
							Click here to calculate the prorate amount.
						</Link>
					</span>
				/>
				<CardTemp
					// cardIcon={<FontAwesomeIcon icon={faCircleQuestion} />}
					className={stylePolicy.card}
					imgTitle=<span></span>
					imgShow="false"
					def1=<span>
						{' '}
						<h5 className={stylePolicy.question}>
							{' '}
							<FontAwesomeIcon icon={faCircleQuestion} />{' '}
							<span> How do I pay my parking fee each month?</span>
						</h5>
						<h5 className={stylePolicy.cardText}>
							{<FontAwesomeIcon icon={faCircleInfo} />} It is recommended that
							all employees participate in the NYP payroll deduction program. An
							employee can request that he or she be billed each month.
							Employees who prefer to be billed must pay their parking fee
							before the fifth business day of each month.
						</h5>
					</span>
				/>
				<CardTemp
					imgShow="false"
					// cardIcon={<FontAwesomeIcon icon={faCircleQuestion} />}
					imgTitle=<span></span>
					def1=<span>
						{' '}
						<h5 className={stylePolicy.question}>
							<FontAwesomeIcon icon={faCircleQuestion} /> Can I cancel my
							account?
						</h5>
						<h5 className={stylePolicy.cardText}>
							{<FontAwesomeIcon icon={faCircleInfo} />} Employees who are not on
							LOA may cancel their accounts at any time. Cancellation will take
							effect on the 15th or at the end of the month. If you park for at
							least one day between the 1st and 15th or from the 16th until the
							end of the month, you will be charged for a full period.{' '}
						</h5>
					</span>
				/>
				<CardTemp
					// cardIcon={<FontAwesomeIcon icon={faCircleQuestion} />}
					// imgTitle=<span> Can I suspend my account?</span>
					imgShow="false"
					def1=<span>
						{' '}
						<h5 className={stylePolicy.question}>
							<FontAwesomeIcon icon={faCircleQuestion} />{' '}
							<span> Can I suspend my account?</span>
						</h5>
						<h5 className={stylePolicy.cardText}>
							{<FontAwesomeIcon icon={faCircleInfo} />} Accounts can only be
							suspended for employees who are on LOA. Parking Office may request
							paperwork to confirm whether an LOA has been authorized. It is the
							employee responsibility to inform the parking office before they
							begin his/her LOA.
						</h5>
					</span>
				/>
				<CardTemp
					// cardIcon={<FontAwesomeIcon icon={faCircleQuestion} />}
					// imgTitle=<span>
					//   {" "}
					//   If I park at one campus, do I have access to other campuses’
					//   parking?
					// </span>
					imgShow="false"
					def1=<span>
						{' '}
						<h5 className={stylePolicy.question}>
							<FontAwesomeIcon icon={faCircleQuestion} />
							<span>
								{' '}
								If I park at one campus, do I have access to other campuses’
								parking?
							</span>
						</h5>
						<h5 className={stylePolicy.cardText}>
							{<FontAwesomeIcon icon={faCircleInfo} />} No. Because of waiting
							lists, we do not grant access to other campuses. Your access is
							limited to the Campus and location to which you have been
							assigned.
						</h5>
					</span>
				/>
				<CardTemp
					// cardIcon={<FontAwesomeIcon icon={faCircleQuestion} />}
					// imgTitle=<span> Is my access card or RFID tag transferrable?</span>
					imgShow="false"
					def1=<span>
						{' '}
						<h5 className={stylePolicy.question}>
							<FontAwesomeIcon icon={faCircleQuestion} />
							<span> Is my access card or RFID tag transferrable?</span>
						</h5>
						<h5 className={stylePolicy.cardText}>
							{<FontAwesomeIcon icon={faCircleInfo} />} No, Parking Department
							follows "One Employee One Account" policy. Access cards can only
							be used by the employee whose name is on the monthly account.
						</h5>
					</span>
				/>
				<CardTemp
					// cardIcon={<FontAwesomeIcon icon={faCircleQuestion} />}
					// imgTitle=<span>
					//   {" "}
					//   Is there a charge for RFID tags or access cards?
					// </span>
					imgShow="false"
					def1=<span>
						{' '}
						<h5 className={stylePolicy.question}>
							<FontAwesomeIcon icon={faCircleQuestion} />
							<span> Is there a charge for RFID tags or access cards?</span>
						</h5>
						<h5 className={stylePolicy.cardText}>
							{<FontAwesomeIcon icon={faCircleInfo} />} Yes, there is a charge
							for new, damaged, lost RFID tag or access card. Please contact the
							parking office immediately when the RFID tag is damaged or access
							card is lost.
						</h5>
					</span>
				/>
			</div>
			<br />
			<br />
		</div>
	);
}
export default GeneralRules;
