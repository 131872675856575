import { library } from '@fortawesome/fontawesome-svg-core';
import {
	faClipboardList,
	faDollarSign,
	faInfoCircle,
	faSquareParking,
	faTable
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import homeCardStyle from '../css/policiesStyle.module.css';
import CardTemp from './CardTemplate';
library.add(
	faInfoCircle,
	faClipboardList,
	faSquareParking,
	faDollarSign,
	faTable
);

function MainTabContent() {
	return (
		<div className={homeCardStyle.cardsDiv}>
			<section className={homeCardStyle.homeCard}>
				<div>
					<CardTemp
						paperShow="false"
						cardLink="./TopInfo"
						imgSrc={require('../images/Knowledge.jpg')}
						// cardIcon={<FontAwesomeIcon icon={faInfoCircle} />}
						imgTitle=<h5 className={homeCardStyle.cardTitle}>
							{' '}
							{<FontAwesomeIcon icon={faInfoCircle} />} What you need to know
							before applying!
						</h5>
					/>
				</div>
			</section>
			<div className={homeCardStyle.homeCard}>
				<CardTemp
					paperShow="false"
					cardLink="./Policies"
					imgSrc={require('../images/Rules2.jpg')}
					imgTitle=<h5 className={homeCardStyle.cardTitle}>
						{<FontAwesomeIcon icon={faClipboardList} />} What you need to know
						after you sign up!
					</h5>
				/>
			</div>

			<div className={homeCardStyle.homeCard}>
				<CardTemp
					paperShow="false"
					cardLink="./AltParkingTab"
					imgSrc={require('../images/AltLocations.jpg')}
					// cardIcon={<FontAwesomeIcon icon={faSquareParking} />}
					imgTitle=<h5 className={homeCardStyle.cardTitle}>
						{<FontAwesomeIcon icon={faSquareParking} />} Alternate Parking
						Locations
					</h5>
				/>
			</div>

			<div className={homeCardStyle.homeCard}>
				<CardTemp
					paperShow="false"
					cardLink="./Applications"
					imgSrc={require('../images/Forms.jpg')}
					// cardIcon={<FontAwesomeIcon icon={faTable} />}
					imgTitle=<h5 className={homeCardStyle.cardTitle}>
						{<FontAwesomeIcon icon={faTable} />} Applications and Forms
					</h5>
				/>
			</div>

			<div className={homeCardStyle.homeCard}>
				<CardTemp
					paperShow="false"
					cardLink="./NestedNav"
					imgSrc={require('../images/Rate.jpg')}
					// cardIcon={<FontAwesomeIcon icon={faDollarSign} />}
					imgTitle=<h5 className={homeCardStyle.cardTitle}>
						{<FontAwesomeIcon icon={faDollarSign} />} Locations Parking Rates
					</h5>
				/>
			</div>
		</div>
	);
}
export default MainTabContent;
