import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
// import { useState } from "react";
import SouthProp from './SP';
// import { Typography } from "@mui/material";
// import LocalParkingSharpIcon from '@mui/icons-material/LocalParkingSharp';
//import { Key } from "@mui/icons-material";
//import BuildBootStrapMenu from "./DisplayMenu";
// import CreateButtons from "../components/MenuButtons";
// import * as Icon from 'react-bootstrap-icons';
import LocAnswerFooter from './AnswersFooter';
import LocGoogleMap from './LocGoogleMap';
import LocPageFooter from './PageFooter';

function CrescentLocAnswers() {
	// <BuildBootStrapMenu />
	const FacList = [
		{
			id: 'div5',
			quest: 'How many floors?',
			answer: 'Surface lot'
		},
		{
			id: 'div6',
			quest: 'Does the lot have gates at entrances and exits?',
			answer: 'Yes. Employees must scan their NYP ID cards to access the lot.'
		},
		{
			id: 'div21',
			quest: 'Does the lot have car stackers or lifts?',
			answer:
				'No lifts or stackers at this time. Stackers may later be installed as demand increases.'
		},
		{
			id: 'div21',
			quest: 'Will my vehicle be secure??',
			answer:
				'The parking lot will be staffed with parking atendants when open and is securely fenced.'
		},
		{
			id: 'div7',
			quest: 'What do I do when I arrive?',
			answer:
				'You can either park in any of the available non-reserved self-park spaces or the valet team will issue you a ticket and valet your car.'
		},
		{
			id: 'div9',
			quest: 'How do I retrieve my car?',
			answer:
				'If you were issued a ticket, hand over the ticket to the attendant and he/she will retrieve your car. If you self-park your car, the attendant will assist you.'
		},
		{
			id: 'div10',
			quest: 'Can I park my own car, or do I have to valet?',
			answer:
				'Self- Park spaces are available. When self-park spaces are occupied, the lot operation will convert to full valet service.When car stackers are installed, no self- park spaces will be available, and the lot’s operation will convert to full valet service'
		},
		{
			id: 'div10',
			quest: 'Can I choose where I park?',
			answer: 'Yes, only if non-reserved self-park spaces are available.'
		},
		{
			id: 'div11',
			quest: 'Can I have more than one car at the same time in the lot?',
			answer: 'No, you can only park one car at a time.'
		},
		{
			id: 'div11',
			quest: 'What do I need to access the lot?',
			answer:
				'NYP Employees must have their NYP ID card activated. All others will be issued an access card.'
		},
		{
			id: 'div8',
			quest: 'Who issues the access cards or activates an NYP ID card?',
			answer:
				'NYP ID cards are activated at the lot. Parking Manager will issue access cards.'
		}
	];

	const opsList = [
		{
			id: 'div12',
			quest: 'Is there a shuttle available?',
			answer: 'Yes.'
		},
		{
			id: 'div12',
			quest: 'Where will the shutle bus be located and how open will it run?',
			answer:
				'Shuttle buses will run Monday through Friday between the Crescent Lot on 9th Avenue and the CUIMC Emergency Department entrance on Broadway during the morning(5:00am to 10:00 am) and evening (1:00 pm to 10:00 pm) periods. They will leave every half hour (e.g., 5:00 am, 5: 30 am, 6:00 am). The last bus in the morning will leave the Crescent Lot at 10:00 am, and the last bus in the evening will leave Parking Lot Entrance Will be Marked CUIMC at 10:00 pm. Depending on local traffic conditions, a one-way trip may take approximately 30 minutes.'
		},
		{
			id: 'div12',
			quest: 'Can I have more than one access card?',
			answer: 'No, only one access card can be assigned to an account.'
		},
		{
			id: 'div13',
			quest: 'What if I lose my access card or my card does not work?',
			answer:
				'NYP employees, activate your card at the lot after a new NYP ID card is issued. All others, contact the lot manager or the parking office.'
		},
		{
			id: 'div14',
			quest:
				'Can I store my car on the lot (storage parking is parking a car on the lot for more than 24 hours)?',
			answer:
				'No, storage parking allowed. Parking is for commuters only and you must exit the lot after your shift ends.'
		},
		{
			id: 'div15',
			quest:
				'Can I park my car if I live in NYP housing or neighboring zip codes?',
			answer:
				'No. NYP housing residents are not allowed to use NYP parking. This restriction also includes employees living in neighboring zip codes.'
		},

		{
			id: 'div16',
			quest: 'Is there space available for new monthly accounts?',
			answer: 'Yes, all new applicants are assigned to the Crescent Lot.'
		},
		{
			id: 'div18',
			quest: "Can I park if I don't have a monthly account?",
			answer: 'No. Parking is for employees only with active monthly account.'
		},
		{
			id: 'div19',
			quest: 'Does the lot accept SUVs and small vans?',
			answer: 'Yes.'
		},
		{
			id: 'div20',
			quest: 'Does the lot accept shuttle size vans?',
			answer:
				'No. Large vans cannot be accommodated due the entrance and exit lanes width.'
		},

		{
			id: 'div22',
			quest: 'Do you have discounted daily rates for employees?',
			answer:
				'No. Employees who do not have an active monthly account cannot access the lot.'
		}
	];

	const FinalList = FacList.map((targetDivs, divId) => (
		<SouthProp key={targetDivs.id} targetDivs={targetDivs} divId={divId} />
	));
	const OperationList = opsList.map((targetDivs) => (
		<SouthProp key={targetDivs.id} targetDivs={targetDivs} />
	));

	var targetLat = 40.87192610629359;
	var targetLng = -73.91124351802867;

	return (
		<div style={{ backgroundColor: 'white' }} id="topDiv">
			<div className="container-fluid">
				<div className="row">
					<div>
						<LocGoogleMap locLat={targetLat} locLng={targetLng} />
					</div>
					<div>
						<table
							className="table table-striped table-bordered"
							style={{ border: 'ridge', marginLeft: '-0px' }}
						>
							<thead>
								<tr>
									<th id="thID" colSpan="2">
										<span style={{ color: 'blue' }}>
											Allen Hospital Crescent Lot - 4141 9th Avenue New York NY
											10034{' '}
										</span>
									</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td className="firsttd" style={{ fontWeight: 'bold' }}>
										Lot Address:
									</td>
									<td>
										<span style={{ color: 'blue' }}>
											4141 9th Avenue New York NY 10034{' '}
										</span>
									</td>
								</tr>
								<tr>
									<td style={{ fontWeight: 'bold' }}>Lot Name:</td>
									<td>
										<span style={{ color: 'blue' }}>
											Allen Hospital Crescent Lot{' '}
										</span>
									</td>
								</tr>
								<tr>
									<td style={{ fontWeight: 'bold' }}>Also Known As:</td>
									<td>
										<span style={{ color: 'blue' }}>Crescent lot</span>
									</td>
								</tr>
								<tr>
									<td style={{ fontWeight: 'bold' }}>License:</td>
									<td>
										<span style={{ color: 'blue' }}>
											Employees only. No public parking allowed.
										</span>
									</td>
								</tr>
								<tr>
									<td style={{ fontWeight: 'bold' }}>Hours:</td>
									<td>
										<span style={{ color: 'blue' }}>
											24 Hours 7 Days a week accessible. After 10P, you can
											retrieve from the NYP-Allen Hospital Employees lot located
											across the street from the Crescent Lot. The lot will be
											accessible 24/7. Parking staff dedicated to the Crescent
											Lot will be on-site Mondays through Fridays between the
											hours of 5:00 a.m. and 10:00 pm. Outside of those hours
											access to the lot will be provided by staff at the
											NYP-Allen Employee Lot across 9th Avenue. The NYP-Allen
											Employee Lot may be reached at 212-304-4560.
										</span>
									</td>
								</tr>
								<tr>
									<td style={{ fontWeight: 'bold' }}>Entrance:</td>
									<td>
										<span style={{ color: 'blue' }}>
											Entrance is on 9th Avenue across from 220th Street.
										</span>
									</td>
								</tr>
								<tr>
									<td style={{ fontWeight: 'bold' }}>Exits:</td>
									<td>
										<span style={{ color: 'blue' }}>
											Exit on 9th Avenue across from 220th Street. Use caution
											as you are making a left turn onto 9th Avenue.
										</span>
									</td>
								</tr>
								<tr>
									<td style={{ fontWeight: 'bold' }}>Operation Type:</td>
									<td>
										<span style={{ color: 'blue' }}>Self-Park and valet.</span>
									</td>
								</tr>
							</tbody>
						</table>
						<LocPageFooter list1={FinalList} />
						<LocAnswerFooter list1={OperationList} />
					</div>
				</div>
			</div>
		</div>
	);
}

export default CrescentLocAnswers;
