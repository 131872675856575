import React from 'react';
// import { CaretDown } from "react-bootstrap-icons";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import altStyles from '../css/altParking.module.css';
import CardTemp from './CardTemplate';
library.add(faCircleInfo);

function CardView() {
	document.getElementById('lastcard').scrollIntoView();
}

function AltParkingTabContent() {
	document.onload = function () {
		CardView();
	};
	return (
		<div id="mainframe" className={altStyles.mainHeader}>
			<section>
				<p className={altStyles.topParag}>
					<h2 className={altStyles.heading}>
						Please Read Before You Use Any of The Website Listed Below!
					</h2>
					The Parking Department has no affiliation with any of the parking
					facilities that may appear when you visit the websites listed on this
					page. Before you park, we advise you to contact the parking facility
					to confirm the rates, space availability, and operating hours.
				</p>
			</section>

			<div className={altStyles.cardsDiv}>
				<CardTemp
					cardLink="#"
					imgShow="false"
					imgTitle=<h5 className={altStyles.title}>
						Columbia Campus Alternate Parking
					</h5>
					def1=<button
						className={altStyles.defBtn}
						id="hero"
						type="button"
						onClick={() => {
							window.open(
								'https://spothero.com/search?kind=address&latitude=40.8399581&longitude=-73.9420394&search_string=New+York+Presbyterian+Hospital-Columbia%3A+Cancer+Center%2C+West+168th+Street%2C+New+York%2C+NY%2C+USA'
							);
						}}
					>
						SpotHero Listed Locations
					</button>
					def2=<button
						className={altStyles.defBtn}
						id="wiz"
						type="button"
						onClick={() => {
							window.open(
								'https://www.parkwhiz.com/destination/115-Fort-Washington-Avenue-New-York-NY/?lat=40.84005837470821&lng=-73.94164290345077'
							);
						}}
					>
						ParkWiz Listed Locations
					</button>
					def3=<button
						className={altStyles.defBtn}
						id="hero"
						type="button"
						onClick={() => {
							window.open(
								'https://www.bestparking.com/destination/115-Fort-Washington-Ave-New-York-NY/?lat=40.83796329345279&lng=-73.94235503613328'
							);
						}}
					>
						BestParking Listed Locations
					</button>
					def4=<button
						className={altStyles.defBtn}
						id="hero"
						type="button"
						onClick={() => {
							window.open(
								'https://en.parkopedia.com/parking/locations/115_fort_washington_avenue_new_york_city_new_york_10032_united_states_i93ddr72m6eqntc1e0/?arriving=202309031030&leaving=202309031230'
							);
						}}
					>
						ParkOpedia Listed Locations
					</button>
				/>
				<CardTemp
					cardLink="#"
					imgShow="false"
					imgTitle=<h5>Cornell Campus Alternate Parking</h5>
					def1=<button
						className={altStyles.defBtn}
						id="hero"
						type="button"
						onClick={() => {
							window.open(
								'https://spothero.com/destination/nyc/new-york-weill-cornell-medical-center-parking?utm_source=google&utm_medium=cpc&utm_term=keyword_::matchtype_&utm_content=::adpos_::network_x::device_c::a_campaign_15029161829&ad_grp_id=&gclid=CjwKCAjw29ymBhAKEiwAHJbJ8gkpzdfrDen8-ghfTtWpWMXp5c6svu9dc7Ya9ey-dybluSs_nOPGMxoClDgQAvD_BwE'
							);
						}}
					>
						SpotHero Listed Locations
					</button>
					def2=<button
						className={altStyles.defBtn}
						id="wiz"
						type="button"
						onClick={() => {
							window.open(
								'https://www.parkwhiz.com/destination/435-East-68th-Street-New%20York-NY/?lat=40.764351&lng=-73.955745'
							);
						}}
					>
						ParkWiz Listed Locations
					</button>
					def3=<button
						className={altStyles.defBtn}
						id="hero"
						type="button"
						onClick={() => {
							window.open(
								'https://www.bestparking.com/ny-ny-parking/destinations/new-york-presbyterian-hospital-parking/?daily=1'
							);
						}}
					>
						BestParking Listed Locations
					</button>
					def4=<button
						className={altStyles.defBtn}
						id="hero"
						type="button"
						onClick={() => {
							window.open(
								'https://en.parkopedia.com/parking/locations/525_east_68th_street_new_york_city_new_york_10065_united_states_e47cdr5rvhgvxdnc79/?country=us&arriving=202309031130&leaving=202309031330'
							);
						}}
					>
						ParkOpedia Listed Locations
					</button>
				/>
				<CardTemp
					cardLink="#"
					imgShow="false"
					imgTitle=<h5>Methodist Campus Alternate Parking</h5>
					def1=<button
						className={altStyles.defBtn}
						id="hero"
						type="button"
						onClick={() => {
							window.open(
								'https://spothero.com/search?kind=address&latitude=40.6686663&longitude=-73.98035349999999&search_string=500+6th+Street%2C+Brooklyn%2C+NY%2C+USA&starts=2023-08-15T09%3A00&ends=2023-08-15T12%3A00'
							);
						}}
					>
						SpotHero Listed Locations
					</button>
					def2=<button
						className={altStyles.defBtn}
						id="wiz"
						type="button"
						onClick={() => {
							window.open(
								'https://www.parkwhiz.com/destination/500-6th-Street-New-York-NY/?lat=40.668772891719755&lng=-73.97640366247418'
							);
						}}
					>
						ParkWiz Listed Locations
					</button>
					def3=<button
						className={altStyles.defBtn}
						id="hero"
						type="button"
						onClick={() => {
							window.open(
								'https://www.bestparking.com/destination/500-6th-Street-New-York-NY/?lat=40.668787056833224&lng=-73.98140533051998'
							);
						}}
					>
						BestParking Listed Locations
					</button>
					def4=<button
						className={altStyles.defBtn}
						id="hero"
						type="button"
						onClick={() => {
							window.open(
								'https://en.parkopedia.com/parking/locations/500_6th_avenue_brooklyn_new_york_11215_united_states_999hdr5rk7j0etd7ia/?country=us&arriving=202309031130&leaving=202309031330'
							);
						}}
					>
						ParkOpedia Listed Locations
					</button>
				/>
				<CardTemp
					cardLink="#"
					imgShow="false"
					imgTitle=<h5>Lower Manhattan Hospital Alternate Parking</h5>
					def1=<button
						className={altStyles.defBtn}
						id="hero"
						type="button"
						onClick={() => {
							window.open(
								'https://spothero.com/search?kind=address&latitude=40.7106783&longitude=-74.00575649999999&search_string=8+Spruce+Street%2C+New+York%2C+NY%2C+USA'
							);
						}}
					>
						SpotHero Listed Locations
					</button>
					def2=<button
						className={altStyles.defBtn}
						id="wiz"
						type="button"
						onClick={() => {
							window.open(
								'https://www.parkwhiz.com/destination/8-Spruce-Street-New-York-NY/?lat=40.711431492924504&lng=-74.0054452652937'
							);
						}}
					>
						ParkWiz Listed Locations
					</button>
					def3=<button
						className={altStyles.defBtn}
						id="hero"
						type="button"
						onClick={() => {
							window.open(
								'https://www.bestparking.com/destination/25-Beekman-Street-New-York-NY/?lat=40.71089935642857&lng=-74.00505157312818'
							);
						}}
					>
						BestParking Listed Locations
					</button>
					def4=<button
						className={altStyles.defBtn}
						id="hero"
						type="button"
						onClick={() => {
							window.open(
								'https://en.parkopedia.com/parking/locations/8_spruce_street_8_spruce_st_new_york_city_new_york_10038_united_states_44gddr5regq8k6hhhb/?country=us&arriving=202309031130&leaving=202309031330'
							);
						}}
					>
						ParkOpedia Listed Locations
					</button>
				/>
			</div>
		</div>
	);
}
export default AltParkingTabContent;
