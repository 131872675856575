import React, { useMemo } from 'react';
// import "../css/mainNavStyle.css";
// import BuildReactTable from "./BuildReactTableTemplate.js";
import {
	useFilters,
	useGlobalFilter,
	usePagination,
	useSortBy,
	useTable
} from 'react-table';
import RatesData from './RatesData.json';
import COLUMNS from './TableColumns';
// MaintableStyles is a var
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MainTableStyle from '../css/tableStyle.module.css';
import { GlobalFilter } from './GlobalFilter';
// import RatesNavBar from "./NestedNav";
import { library } from '@fortawesome/fontawesome-svg-core';
import {
	faArrowCircleDown,
	faArrowCircleUp
} from '@fortawesome/free-solid-svg-icons';

library.add(faArrowCircleDown, faArrowCircleUp);

const BasicTable = () => {
	const columns = useMemo(() => COLUMNS, []);
	const data = useMemo(() => RatesData, []);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		nextPage,
		previousPage,
		canNextPage,
		canPreviousPage,
		prepareRow,
		pageOptions,
		gotoPage,
		setPageSize,
		pageCount,
		state,
		setGlobalFilter
	} = useTable(
		{
			columns,
			data
		},
		useFilters,
		useGlobalFilter,
		useSortBy,
		usePagination
	);

	const { globalFilter, pageSize } = state;
	const { pageIndex } = state;
	return (
		<div className={MainTableStyle.tableTemplate}>
			<div>
				{/* <RatesNavBar bottomDist="10px" /> */}
				<GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
				{/* <LocationFilter filter={globalFilter} setFilter={setGlobalFilter} /> */}
				<table {...getTableProps}>
					<thead>
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => (
									<th {...column.getHeaderProps(column.getSortByToggleProps())}>
										{column.render('Header')}
										{/* Below code shows seach boxes in each column. 
                    Disabled so the page is responsive when rendered on smaller sceens */}
										{/* <div>
                      {column.canFilter ? column.render("Filter") : null}{" "}
                    </div> */}
										<span>
											{column.isSorted ? (
												column.isSortedDesc ? (
													<FontAwesomeIcon icon={faArrowCircleDown} />
												) : (
													<FontAwesomeIcon icon={faArrowCircleUp} />
												)
											) : (
												''
											)}
										</span>
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{page.map((row) => {
							prepareRow(row);
							return (
								<tr {...row.getRowProps()}>
									{row.cells.map((cell) => {
										return (
											<td {...cell.getCellProps()}>{cell.render('Cell')}</td>
										);
									})}
								</tr>
							);
						})}
					</tbody>
				</table>
				<div>
					<span>
						<strong>
							Page {pageIndex + 1} of {pageOptions.length}
						</strong>{' '}
					</span>
					<span>
						| Go to page:{'  '}
						<input
							type="number"
							className={MainTableStyle.navCtrls}
							defaultValue={pageIndex + 1}
							onChange={(e) => {
								const pageNumber = e.target.value
									? Number(e.target.value) - 1
									: 0;
								gotoPage(pageNumber);
							}}
						/>
					</span>
					<select
						value={pageSize}
						className={MainTableStyle.navCtrls}
						onChange={(e) => setPageSize(e.target.value)}
					>
						{[10, 25, 50].map((pageSize) => (
							<option key={pageSize} value={pageSize}>
								Show {pageSize}
							</option>
						))}
					</select>
					<button
						className={MainTableStyle.navCtrls}
						onClick={() => gotoPage(0)}
						disabled={!canPreviousPage}
					>
						{'<<'}
					</button>
					<button
						onClick={() => previousPage()}
						disabled={!canPreviousPage}
						className={MainTableStyle.navCtrls}
					>
						Previous
					</button>
					<button
						onClick={() => nextPage()}
						disabled={!canNextPage}
						className={MainTableStyle.navCtrls}
					>
						Next
					</button>
					<button
						className={MainTableStyle.navCtrls}
						onClick={() => gotoPage(pageCount - 1)}
						disabled={!canNextPage}
					>
						{'>>'}
					</button>
				</div>
				<br />
				<br />
				<br />
			</div>
		</div>
	);
};
export default BasicTable;
