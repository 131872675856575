import React from "react";
import { Typography } from "@mui/material";
import * as Icon from 'react-bootstrap-icons';
import { useState } from "react";
// import HideQuestions from "./ShowHideDivs";


function HideQuestions2() {
    var hideDiv2 = document.getElementById('questsDiv2');
    if (hideDiv2.style.display === 'none') {
        hideDiv2.style.display = 'block';
    } else {
        hideDiv2.style.display = 'none';
    }
}

function LocAnswerFooter({ list1 }) {
    const [isClicked2, setIsClicked2] = useState(false);
    return (
        <div style={{ marginBottom: '15px',width:'90%' }} className="col-10" >

            <div onClick={() => { setIsClicked2(!isClicked2) }}>
                <Typography variant="h6" sx={{ marginTop: '10px', backgroundColor: 'lightgreen'}}>
                    <button className="btn btn-link" onClick={HideQuestions2}>
                        Click for Answers to Operations Questions
                        {isClicked2 ? <Icon.CaretUpFill /> : <Icon.CaretDownFill />}
                    </button>
                </Typography>        
            </div>
            <div id="questsDiv2" style={{ marginLeft: '-20px', display: 'none' }}>
                {list1}
            </div>
            <br />
            <br />
        </div>
    );
}

export default LocAnswerFooter;