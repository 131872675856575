import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
// import { useState } from "react";
import SouthProp from './SP';
// import { Typography } from "@mui/material";
//import LocalParkingSharpIcon from '@mui/icons-material/LocalParkingSharp';
// import { Key } from "@mui/icons-material";
// import BuildBootStrapMenu from "./DisplayMenu";
//import CreateButtons from "../components/MenuButtons";
// import * as Icon from 'react-bootstrap-icons';
import LocAnswerFooter from './AnswersFooter';
import LocGoogleMap from './LocGoogleMap';
import LocPageFooter from './PageFooter';

function LocAnswers166() {
	// <BuildBootStrapMenu />
	const FacList = [
		{
			id: 'div5',
			quest: 'How many floors?',
			answer: 'Six floors, including the cellar'
		},
		{
			id: 'div18',
			quest: 'How are the parking floors accessed?',
			answer:
				'The upper floors are accessed by two freight elevators. Only the cellar is accessible by a ramp.'
		},
		{
			id: 'div6',
			quest: 'Does the garage have gates at entrances and exits?',
			answer:
				'No. A ticket is issued on entry. All cars must have a valid RFID tag'
		},
		{
			id: 'div21',
			quest: 'Does the garage have car stackers or lifts?',
			answer: 'No lifts or stackers.'
		},
		{
			id: 'div7',
			quest: 'What do I do when I arrive?',
			answer:
				'Drive into the garage and the parking team will issue a ticket and valet your car.'
		},
		{
			id: 'div9',
			quest: 'How do I retrieve my car?',
			answer:
				'Hand over the ticket that was issued when you entered to the parking location staff, and they will retrieve the car.'
		},
		{
			id: 'div10',
			quest: 'Can I park my own car, or do I have to valet?',
			answer:
				'No. The garage offers valet service only. Please do not park your car and/or take the ignition keys.'
		},
		{
			id: 'div15',
			quest: 'Can I drive in with a car other than the one with an RFID tag?',
			answer:
				'Yes. The staff will issue a replacement ticket. You may be asked to provide your telephone number.'
		},
		{
			id: 'div10',
			quest: 'Can I choose where I park?',
			answer: 'No. Valet staff will valet  and park your car.'
		},
		{
			id: 'div11',
			quest: 'Can I have more than one car at the same time in the garage?',
			answer: 'No, you can only park one car at a time.'
		},
		{
			id: 'div11',
			quest: 'What do I need to access the garage?',
			answer:
				'Cars will be issued an adhesive RFID tag (access tag) that should be affixed to the rear-view mirror'
		},
		{
			id: 'div8',
			quest: 'Who issues the RFID tag?',
			answer:
				'RFID tags (access tags) are issued by the Parking Office or the Manager’s Office.'
		}
	];

	const opsList = [
		{
			id: 'div12',
			quest: 'Can I have more than one access tag (RFID tag)?',
			answer: 'No, only one access tag can be assigned to an account.'
		},
		{
			id: 'div13',
			quest:
				'What if I lose my RFID tag, change my car or the tag stops working?',
			answer:
				'Contact the parking office to request a new access tag. There is a charge for a tag replacement.'
		},
		{
			id: 'div14',
			quest:
				'Can I store my car in the garage (storage parking is parking a car in the garage for more than 24 hours)?',
			answer:
				'No storage parking allowed. Parking is for commuters only and you must exit the garage after your shift ends.'
		},
		{
			id: 'div15',
			quest:
				'Can I park my car if I live in NYP housing or neighboring zip codes?',
			answer:
				'No. NYP housing residents are not allowed to use NYP parking. This restriction also includes employees living in neighboring zip codes.'
		},
		{
			id: 'div16',
			quest: 'Is there space available for new monthly accounts?',
			answer:
				'No, new monthly accounts will be assigned to a remote location (offsite) that may require shuttle service.'
		},
		{
			id: 'div17',
			quest: 'What do I do when my RFID tag (access tag) does not work?',
			answer:
				'If your tag is valid and your account in good standing, the staff will issue you replacement ticket.'
		},
		{
			id: 'div18',
			quest: "Can I park if I don't have a monthly account?",
			answer:
				'Yes. Important to note, however, that public parking spaces are reserved for patients and visitors.'
		},
		{
			id: 'div19',
			quest: 'Does the garage accept SUVs and small vans?',
			answer:
				'Yes, but there is height restriction. Please remove all racks from your car’s roof before entering the garage.'
		},
		{
			id: 'div20',
			quest: 'Does the garage accept shuttle size vans?',
			answer:
				'No. Large vans cannot be accommodated due to height limits and parking space sizes.'
		},

		{
			id: 'div22',
			quest: 'Do you have discounted daily rates for employees?',
			answer:
				'No. Employees who do not have an active monthly account cannot access the garage.'
		}
	];
	// const [isShown, setIsShown] = useState(false, { id: 'divOne' });
	// const hideShowDiv = (e) => {
	//     setIsShown({ id: e.target.id }, !isShown);
	//     divName = e.target.id;
	//     console.log (divName);

	//  };
	const FinalList = FacList.map((targetDivs, divId) => (
		<SouthProp key={targetDivs.id} targetDivs={targetDivs} divId={divId} />
	));
	const OperationList = opsList.map((targetDivs) => (
		<SouthProp key={targetDivs.id} targetDivs={targetDivs} />
	));

	var targetLocLat = 40.83923454282662;
	var targetLocLng = -73.93837231802993;

	// const [isClicked, setIsClicked] = useState(false);
	// const [isClicked2, setIsClicked2] = useState(false);

	return (
		<div style={{ backgroundColor: 'white' }} id="topDiv">
			<div className="container-fluid">
				<div className="row">
					<div>
						<LocGoogleMap locLat={targetLocLat} locLng={targetLocLng} />
					</div>
					<div>
						<table
							className="table table-striped table-bordered"
							style={{ border: 'ridge', marginLeft: '-0px' }}
						>
							<thead>
								<tr>
									<th id="thID" colSpan="2">
										<span style={{ color: 'blue' }}>
											166th Street Lot - 503 West 166th Street Garage New York
											NY 10032{' '}
										</span>
									</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td className="text-start" style={{ fontWeight: 'bold' }}>
										Garage Address:
									</td>
									<td className="text-start">
										<span style={{ color: 'blue' }}>
											503 West 166th Street Garage New York NY 10032{' '}
										</span>
									</td>
								</tr>
								<tr>
									<td
										className="text-start firsttd"
										style={{ fontWeight: 'bold' }}
									>
										Garage Name:
									</td>
									<td className="text-start">
										<span style={{ color: 'blue' }}>166th Street Garage </span>
									</td>
								</tr>
								<tr>
									<td className="text-start" style={{ fontWeight: 'bold' }}>
										Also Known As:
									</td>
									<td className="text-start">
										<span style={{ color: 'blue' }}>Audobun Garage</span>
									</td>
								</tr>
								<tr>
									<td className="text-start" style={{ fontWeight: 'bold' }}>
										License:
									</td>
									<td className="text-start">
										<span style={{ color: 'blue' }}>
											Employees only. No public parking allowed.
										</span>
									</td>
								</tr>
								<tr>
									<td className="text-start" style={{ fontWeight: 'bold' }}>
										Hours:
									</td>
									<td className="text-start">
										<span style={{ color: 'blue' }}>
											Monday through Friday 5:45A to 1A. Closed on weekends and
											NYP holidays. On weekends, after 3P on weekdays and during
											NYP holidays, employees can park at 115 Fort Washington
											Avenue garage.
										</span>
									</td>
								</tr>
								<tr>
									<td className="text-start" style={{ fontWeight: 'bold' }}>
										Entrance:
									</td>
									<td className="text-start">
										<span style={{ color: 'blue' }}>
											Entrance is on 166th Street between Audobun and Amsterdam
										</span>
									</td>
								</tr>
								<tr>
									<td className="text-start" style={{ fontWeight: 'bold' }}>
										Exits:
									</td>
									<td className="text-start">
										<span style={{ color: 'blue' }}>
											Exit on 166th Street between Audobun and Amsterdam
										</span>
									</td>
								</tr>
								<tr>
									<td
										className="text-start"
										style={{ fontWeight: 'bold', width: '175px' }}
									>
										Operation Type:
									</td>
									<td className="text-start">
										<span style={{ color: 'blue' }}>Valet Only</span>
									</td>
								</tr>
								<tr>
									<td className="text-start" style={{ fontWeight: 'bold' }}>
										Contact:
									</td>
									<td className="text-start">
										<span style={{ color: 'blue' }}>(212) 305-2718</span>
									</td>
								</tr>
							</tbody>
						</table>
						<LocPageFooter list1={FinalList} />
						<LocAnswerFooter list1={OperationList} />
					</div>
				</div>
			</div>
		</div>
	);
}

export default LocAnswers166;
