import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
//import { useState } from "react";
import SouthProp from './SP';
// import { Typography } from "@mui/material";
//import LocalParkingSharpIcon from '@mui/icons-material/LocalParkingSharp';
// import { Key } from "@mui/icons-material";
// import BuildBootStrapMenu from "./DisplayMenu";
//import CreateButtons from "../components/MenuButtons";
// import * as Icon from 'react-bootstrap-icons';
import LocPageFooter from './PageFooter';

function LocAnswersCornell() {
	// <BuildBootStrapMenu />
	const CampusList = [
		{
			id: 'div1',
			quest:
				'How do I apply for a parking account, cancel my account, or update account information?',
			answer:
				'Select Applications from the above menu and click any of the blue buttons or scan the QR codes.'
		},
		{
			id: 'div2',
			quest: 'Is there a waiting list?',
			answer:
				'Yes, employees who apply for parking are placed on the waiting list. Inquire with the parking staff about the average waiting list wait time.'
		},
		{
			id: 'div3',
			quest: 'Can I have more than one car at the same time in the garage?',
			answer: 'No, you can only park one car at a time.'
		},
		{
			id: 'div4',
			quest:
				'What if I lose my RFID tag or access card, change my car or the RFID tag stops working?',
			answer:
				'Contact the parking office to request a new tag. There is a charge for a new tag.'
		},
		{
			id: 'div5',
			quest: 'Can I remove the RFID tag and place on another car?',
			answer:
				'No, when the tag is peeled off, it will immediately stop working. Contact the parking office staff when you need to add a second car to your account or when you change your car'
		},
		{
			id: 'div6',
			quest: 'Can I store my car in the garage?',
			answer:
				'NYP Housing buildings residents who have storage monthly accounts are permitted to store their cars. Commuters must exit the garage after their work shift ends'
		},
		{
			id: 'div7',
			quest:
				'Can I park my car if I live in buildings located in neighboring zip codes?',
			answer:
				'No. Employees who reside in buildings located in 10103, 10020, 10112, 10111, 10152, 10154, 10029, 10128, 10028, 10075, 10162, 10021, 10022, 10065 and 10153 zip codes are not allowed to park in NYP parking garages.'
		},
		{
			id: 'div8',
			quest: 'Do the garages accept SUVs and small vans?',
			answer:
				'Yes, but there is height restriction. Please remove all racks from your car’s roof before entering the garage. Please inquire with the parking location manager and the parking office staff regarding the additional cost and whether your vehicle can access the garage.'
		},
		{
			id: 'div9',
			quest: 'Do the garages accept shuttle size vans?',
			answer:
				'No. Large vans are not accepted due to height limits and parking space sizes.'
		}
	];
	const MainList = CampusList.map((targetDivs, divId) => (
		<SouthProp key={targetDivs.id} targetDivs={targetDivs} divId={divId} />
	));
	return (
		<div style={{ backgroundColor: 'white' }} id="topDiv">
			<div className="container-fluid">
				<LocPageFooter list1={MainList} />
			</div>
			<br />
			<br />
		</div>
	);
}

export default LocAnswersCornell;
