import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
//import { useState } from "react";
import SouthProp from './SP';
import { Typography } from "@mui/material";
//import LocalParkingSharpIcon from '@mui/icons-material/LocalParkingSharp'; 
//import { Key } from "@mui/icons-material";
// import BuildBootStrapMenu from "./DisplayMenu";
//import CreateButtons from "../components/MenuButtons";
//import * as Icon from 'react-bootstrap-icons';
import LocAnswerFooter from "./AnswersFooter";
import LocPageFooter from "./PageFooter";
import LocGoogleMap from "./LocGoogleMap";
import { Link } from 'react-router-dom'

function LocAnswersSixSt (){
    // <BuildBootStrapMenu />
    const FacList = [
    
         {
            id: 'div5',
            quest: 'How many floors?',
            answer: 'Four floors.'
        },
        {
            id: 'div18',
            quest: 'Is there an elevator?',
            answer: 'There is one passenger elevator that is accessed from the Medical Pavilion, the building across from the garage entrance. It can also be accessed from levels C1, C2 and C3.'
        },
        {
            id: 'div6',
            quest: 'Does the garage have gates at entrances and exits?',
            answer: 'Yes, to enter you either take a ticket or scan your access card.'
        },
        {
            id: 'div7',
            quest: ' Where can I park?',
            answer: 'Parking levels starting from the first floor (C1) and through the fourth (C3). Do not park in spaces marked reserved.'
        },
        {
            id: 'div8',
            quest: ' Who issues the access cards?',
            answer: 'Proxy cards (access cards) issued by the Parking Office or the Manager’s Office.'
        },
        {
            id: 'div9',
            quest: ' What do I do when I arrive or exit?',
            answer: 'Scan your access card (proxy card) against the card reader at the entrance or exit. You must exit before you enter again, and you must enter before you can exit. A cycle of Exit followed by another Exit or Enter by followed by another Enter is not allowed.'
        },
        {
            id: 'div10',
            quest: 'Can I park my own car, or do I have to valet?',
            answer: 'If self-parking spaces are available, you can self-park your car and take the keys. If all self-parking spaces are occupied, valet service will be offered on certain levels, typically on C1, C2 and C3.'
        },
        {
            id: 'div11',
            quest: 'Can I have more than one car at the same time in the garage?',
            answer: 'No, you can only park one car at a time. You must exit the garage and then enter. Two consecutive exits or entrances will be rejected. In other words, you must exit before you enter again, and you must enter before you can exit. A cycle of Exit followed by another Exit or Enter by followed by another Enter is not allowed.'
        },
    ]

    const opsList = [
        {
            id: 'div12',
            quest: 'Can I have more than one access card?',
            answer: 'No, only one card can be assigned to an account.'
        },
        {
            id: 'div13',
            quest: 'What do I do if I lose my card?',
            answer: 'Contact the manager\'s office to request a new card. There is a charge for a new card.'
        },
        {
            id: 'div14',
            quest: 'Can I store my car in the garage?',
            answer: 'No storage parking allowed. Parking is for commuters only and you must exit the garage after your shift ends.'
        },
        {
            id: 'div15',
            quest: 'Can I park my car if I live in NYP housing or neighboring zip codes?',
            answer: 'No. NYP housing residents are not allowed to use NYP parking. This restriction also includes employees living in neighboring zip codes.'
        },
        {
            id: 'div16',
            quest: 'Is there space available for new monthly accounts?',
            answer: 'All new applicants will be placed on the wai�ng list.'
        },
        {
            id: 'div17',
            quest: 'What do I do when my card does not open the gate?',
            answer: "Take a ticket as you enter, and before you leave, stop by the manager's office.Do not attempt to leave the garage until you have stopped by the parking office or the manager's office. Parking and Manager’s offices are located on the third floor of the garage (street level) next to the elevator"
        },
        {
            id: 'div18',
            quest: 'Can I park if I don\'t have a monthly account?',
            answer: 'Yes. Take a transient ticket. Daily posted rates will apply.'
        },
        {
            id: 'div19',
            quest: 'Does the garage accept SUVs and small vans?',
            answer: 'Yes, but there is height restriction. Please remove all racks from your car’s roof before entering the garage.'
        },
        {
            id: 'div20',
            quest: 'Does the garage accept shuttle size vans?',
            answer: 'No. Large vans cannot be accommodated due to height limits and parking space sizes.'
        },
        {
            id: 'div21',
            quest: 'If I take a ticket, where do I park?',
            answer: 'If you take a ticket and all self-park spaces are occupied, please stop by the valet areas and the staff will park your car.'
        },
        {
            id: 'div22',
            quest: 'Do you have discounted daily rates for employees?',
            answer: 'No. Employees who do not have an active monthly account must pay the full transient rate.'
        },
    ]

        
    //  };
    const FinalList = FacList.map((targetDivs,divId) => (
        <SouthProp key={targetDivs.id}  targetDivs={targetDivs} divId={divId} />
    ));
    const OperationList = opsList.map((targetDivs) => (
        <SouthProp key={targetDivs.id} targetDivs={targetDivs} />
    ));

    var targetLat = 40.66866947704607;
    var targetLng = -73.97953884502117;

    return (
      <div className="container" id="topDiv">
        <div className="container-fluid">
          <div>
            <Typography
              variant="h6"
              style={{ backgroundColor: "white", textAlign: "start" }}
              sx={{ marginTop: "2px", paddingTop: "2px", paddingBottom:'5px' }}
            >
              Click <Link to="/MethodistAnswersList">here</Link> for information that
              applies to all Methodist Campus parking facilities.
            </Typography>
          </div>
          <div className="row">
            <div>
              <LocGoogleMap locLat={targetLat} locLng={targetLng} />
            </div>
            <div>
              <table
                className="table table-striped table-bordered"
                style={{ border: "ridge", marginLeft: "-0px" }}
              >
                <thead>
                  <tr>
                    <th id="thID" colSpan="2">
                      <span style={{ color: "blue" }}>
                        6th Street Garage - 500 5th Street or 501 6th Street
                        Brooklyn NY 11215{" "}
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="firsttd text-start" style={{ fontWeight: "bold" }}>
                      Garage Address:
                    </td>
                    <td className="text-start">
                      <span style={{ color: "blue" }}>
                        500 5th Street or 501 6th Street Brooklyn NY 11215{" "}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-start" style={{ fontWeight: "bold" }}>
                      Garage Name:
                    </td>
                    <td className="text-start">
                      <span style={{ color: "blue" }}>6th Street Garage</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-start" style={{ fontWeight: "bold" }}>
                      Also Known As:
                    </td>
                    <td className="text-start">
                      <span style={{ color: "blue" }}>
                        Wesley’s House Garage
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-start" style={{ fontWeight: "bold" }}>
                      License:
                    </td>
                    <td className="text-start">
                      <span style={{ color: "blue" }}>
                        Public License. Accepts vendors, patinets and visitors.
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-start" style={{ fontWeight: "bold" }}>
                      Hours:
                    </td>
                    <td className="text-start">
                      <span style={{ color: "blue" }}>
                        Garage is open 24 hours 7 days a week
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-start" style={{ fontWeight: "bold" }}>
                      Entrance:
                    </td>
                    <td className="text-start">
                      <span style={{ color: "blue" }}>
                        Entrance is on 6th Street between 7th and 8th Avenues.
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-start" style={{ fontWeight: "bold" }}>
                      Exits:
                    </td>
                    <td className="text-start">
                      <span style={{ color: "blue" }}>
                        Drive up the ramp and exit onto either 6th or 5th
                        between 7th and 8th Avenues.
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-start" style={{ fontWeight: "bold" }}>
                      Contact:
                    </td>
                    <td className="text-start">
                      <span style={{ color: "blue" }}>(718) 369-8185</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-start" style={{ fontWeight: "bold" }}>
                      Operation Type:
                    </td>
                    <td className="text-start">
                      <span style={{ color: "blue" }}>
                        Self-Park and Valet. Valet is available on levels C1, C2
                        and C3.
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <LocPageFooter list1={FinalList} />
              <LocAnswerFooter list1={OperationList} />
            </div>
          </div>
        </div>
      </div>
    );
}

export default LocAnswersSixSt;