import CardTemp from "./CardTemplate";
import methodistTabStyle from "../css/policiesStyle.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faParking } from "@fortawesome/free-solid-svg-icons";
library.add(faParking);

function MethodistTabContent() {
  return (
    <div className={methodistTabStyle.cardsDiv}>
      <div className={methodistTabStyle.homeCard}>
        <CardTemp
          cardLink="/MethodistAnswersList"
          cardIcon={<FontAwesomeIcon icon={faParking} />}
          imgSrc={require("../images/Parking Symbol1.jpg")}
          imgTitle=" Methodist Parking Infomation"
          def1=<span className={methodistTabStyle.defSpan}>
            <span>Locations Count: </span>
            <span style={{ color: "blue" }}>Tow</span>
          </span>
          def2=<span className={methodistTabStyle.defSpan}>
            <span>Hours: </span>
            <span style={{ color: "blue" }}>24 Hours 7 Days</span>
          </span>
          def3=<span className={methodistTabStyle.defSpan}>
            <span>Waiting List: </span>
            <span style={{ color: "blue" }}>Yes</span>
          </span>
          def4=<span className={methodistTabStyle.defSpan}>
            <span>Waiting List Wait Time: <br /> </span>
            <span style={{ color: "blue" }}>12 Months (Avg)</span>
          </span>
        />
      </div>
      <div className={methodistTabStyle.homeCard}>
        <CardTemp
          cardLink="/SixStAnswersList"
          cardIcon={<FontAwesomeIcon icon={faParking} />}
          imgSrc={require("../images/6th Street.jpg")}
          imgTitle=" Six Street (Wesley House) Garage"
          def1=<span className={methodistTabStyle.defSpan}>
            <span>Address: </span>
            <span style={{ color: "blue" }}>
              501 6th Street Brooklyn NY 11215
            </span>
          </span>
          def2=<span className={methodistTabStyle.defSpan}>
            <span>Hours: </span>
            <span style={{ color: "blue" }}>24 Hours 7 Days</span>
          </span>
          def3=<span className={methodistTabStyle.defSpan}>
            <span>Parking: </span>
            <span style={{ color: "blue" }}>Valet and Self-Park</span>
          </span>
        />
      </div>
      <div className={methodistTabStyle.homeCard}>
        <CardTemp
          cardLink="/CCHAnswersList"
          cardIcon={<FontAwesomeIcon icon={faParking} />}
          imgSrc={require("../images/CCH.jpg")}
          imgTitle=" Center of Community Health (CCH) Garage"
          def1=<span className={methodistTabStyle.defSpan}>
            <span>Address: </span>
            <span style={{ color: "blue" }}>
              515 6th Street Brooklyn NY 11215
            </span>
          </span>
          def2=<span className={methodistTabStyle.defSpan}>
            <span>Hours: </span>
            <span style={{ color: "blue" }}>24 Hours Acccessible</span>
          </span>
          def3=<span className={methodistTabStyle.defSpan}>
            <span>Parking: </span>
            <span style={{ color: "blue" }}>Valet Only</span>s
          </span>
        />
      </div>
    </div>
  );
}
export default MethodistTabContent;
