function ProrateCalc(
  date1,
  ratesList,
  selectCampus,
  selectLocation,
  selectParkType
) {
  var rateCur = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
  });

  const midMonthStartDay = 15;

  var dateOptions = {
    weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric"
  };

  var startDate = 0;
  var firstPeriodCost = 0;
  var firstPeriodEndDate;
  var secondPeriodEndDate;
  var nextPeriodStartDate;
  var secondPeriodCost = 0;
  var dayAvgRate = 0;
  var finalCost = 0;

  date1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate() + 1);

  var endOfMonthDay = new Date(date1.getFullYear(), date1.getMonth() + 1, 0);
  // console.log(endOfMonthDay, "End of Month");

  nextPeriodStartDate =
    date1.getDate() > midMonthStartDay
      ? new Date(date1.getFullYear(), date1.getMonth() + 1, 1)
      : new Date(date1.getFullYear(), date1.getMonth(), midMonthStartDay + 1);

  firstPeriodEndDate =
    date1.getDate() > midMonthStartDay
      ? endOfMonthDay
      : new Date(date1.getFullYear(), date1.getMonth(), midMonthStartDay);

  secondPeriodEndDate =
    date1.getDate() > midMonthStartDay
      ? new Date(
          nextPeriodStartDate.getFullYear(),
          nextPeriodStartDate.getMonth(),
          midMonthStartDay
        )
      : endOfMonthDay;

  //to get days diff between end of month and start date
  var firstPeriodDays =
    date1.getDate() > midMonthStartDay
      ? parseInt((endOfMonthDay - date1) / 1000 / 60 / 60 / 24 + 1)
      : midMonthStartDay - date1.getDate() + 1; //Start day is included in the count
  // console.log(midMonthStartDay, date1.getDate());
  for (let i = 0; i < ratesList.length; i++) {
    // console.log(endOfMonthDay);

    if (
      ratesList[i].los === "M" &&
      ratesList[i].campus === selectCampus &&
      ratesList[i].locations === selectLocation &&
      ratesList[i].desc === selectParkType
    ) {
      //   console.log(selectCampus, selectLocation, selectParkType);
      dayAvgRate = ratesList[i].rate / endOfMonthDay.getDate();

      firstPeriodCost = dayAvgRate * firstPeriodDays;

      secondPeriodCost =
        date1.getDate() > midMonthStartDay
          ? ratesList[i].rate / 2
          : (endOfMonthDay.getDate() - nextPeriodStartDate.getDate() + 1) *
            dayAvgRate;

      //   console.log(
      //     "Cost is: " + rateCur.format(dayAvgRate * firstPeriodDays),
      //     firstPeriodDays,
      //     ratesList[i].rate,dayAvgRate
      //   );

      startDate = new Date(
        date1.getFullYear(),
        date1.getMonth(),
        date1.getDate()
      );
      finalCost =
        "Monthly Rate: " + 
        rateCur.format(ratesList[i].rate)  +
        "." + 
        "\n" + 
        "Start date: " +
        new Date(startDate).toLocaleString("en-US", dateOptions) + 
        "." +
        "\n" +
        "Number of days in first period: " +
        firstPeriodDays +
        "\n" +
        "First period starts: " +
        startDate.toLocaleString("en-US", dateOptions) +
        "\n" +
        "First period ends: " +
        firstPeriodEndDate.toLocaleDateString("en-US", dateOptions) +
        "." +
        "\n" +
        "First period prorate: " +
        rateCur.format(firstPeriodCost) +
        "." +
        "\n" +
        "Second period starts: " +
        nextPeriodStartDate.toLocaleString("en-US", dateOptions) +
        "\n" +
        "Second period ends: " +
        secondPeriodEndDate.toLocaleDateString("en-US", dateOptions) +
        "." +
        "\n" +
        "Number of days in second period: " +
        (secondPeriodEndDate.getDate() - nextPeriodStartDate.getDate() + 1) +
        "\n" +
        "Second period prorate: " +
        rateCur.format(secondPeriodCost) +
        "." +
        "\n" +
        "Total prorate to be paid before account is activated: " +
        rateCur.format(secondPeriodCost + firstPeriodCost);
    }
  }
  return (
    // <div>
    //   <table className="table table-bordered">
    //     <thead>
    //       <tr>
    //         <th>Descrition</th>
    //         <th>Result</th>
    //       </tr>
    //     </thead>
    //     <tbody>
    //       <tr>
    //         <td>Parking starts</td>
    //         <td>new Date(startDate).toLocaleString("en-US", dateOptions)</td>
    //       </tr>
    //       <tr>
    //         <td>First period start date</td>
    //         <td>startDate.toLocaleString("en-US", dateOptions)</td>
    //       </tr>
    //       <tr>
    //         <td>First period end date</td>
    //         <td>firstPeriodEndDate.toLocaleDateString("en-US", dateOptions)</td>
    //       </tr>
    //       <tr>
    //         <td>Amount due for first period</td>
    //         <td> rateCur.format(firstPeriodCost)</td>
    //       </tr>
    //       <tr>
    //         <td>Second period start date</td>
    //         <td>nextPeriodStartDate.toLocaleString("en-US", dateOptions)</td>
    //       </tr>
    //       <tr>
    //         <td>Second period end date</td>
    //         <td>
    //           {" "}
    //           secondPeriodEndDate.toLocaleDateString("en-US", dateOptions)
    //         </td>
    //       </tr>
    //       <tr>
    //         <td>Amount due for second period</td>
    //         <td> rateCur.format(secondPeriodCost)</td>
    //       </tr>
    //       <tr>
    //         <td>Total prorate due</td>
    //         <td> rateCur.format(secondPeriodCost + firstPeriodCost)</td>
    //       </tr>
    //     </tbody>
    //   </table>
    // </div>
    finalCost
  );
}

export default ProrateCalc;
