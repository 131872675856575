import React from "react";
import "bootstrap/dist/css/bootstrap.css";
// import { useState } from "react";
import SouthProp from "./pages/SP";
import LocPageFooter from "./pages/PageFooter";
import LocAnswerFooter from "./pages/AnswersFooter";
// import PaperTemp from "./pages/PaperTempllate";
// import CardTemp from "./pages/CardTemplate";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faParking } from "@fortawesome/free-solid-svg-icons";
// import { Typography } from "@mui/material";
//import LocalParkingSharpIcon from '@mui/icons-material/LocalParkingSharp';
//import { Key } from "@mui/icons-material";
// import BuildBootStrapMenu from "./DisplayMenu";
//import CreateButtons from "../components/MenuButtons";
// import * as Icon from 'react-bootstrap-icons';
import LocGoogleMap from "./pages/LocGoogleMap";
import { library } from "@fortawesome/fontawesome-svg-core";
library.add(faParking);

function LocAnswers() {
  // <BuildBootStrapMenu />
  const FacList = [
    {
      id: "div5",
      quest: "How many floors?",
      answer:
        "Eight floors. The lot and level 1 and 2 are reserved for patients and visitors parking."
    },
    {
      id: "div18",
      quest: "Is there an elevator?",
      answer: "There is one passenger elevator."
    },
    {
      id: "div6",
      quest: "Does the garage have gates at entrances and exits?",
      answer: "Yes, to enter you either take a ticket or scan your access card."
    },
    {
      id: "div7",
      quest: " Where can I park?",
      answer:
        " Parking levels starting from the third floor and through the eighth are designated for employees. Lot, first, and second levels are reserved for patients and visitors."
    },
    {
      id: "div8",
      quest: " Who issues the access cards?",
      answer:
        "Proxy cards (access cards) issued by the Parking Office or the Manager’s Office. To exit onto Riverside, you must drive down to level one and drive through the surface lot."
    },
    {
      id: "div9",
      quest: " What do I do when I arrive or exit?",
      answer:
        "Scan your access card (proxy card) against the card reader at the entrance or exit."
    },
    {
      id: "div10",
      quest: "Can I park my own car, or do I have to valet?",
      answer:
        "If self-parking spaces are available on the third through eighth floors, you can self-park your car and take the keys. If all self-parking spaces are occupied, valet service will be offered on certain levels, typically the fifth through seventh. Please look for valet signs as you are traversing the floors."
    },
    {
      id: "div11",
      quest: "Can I have more than one car at the same time in the garage?",
      answer:
        "No, you can only park one car at a time. You must exit the garage and then enter. Two consecutive exits or entrances will be rejected. In other words, you must exit before you enter again, and you must enter before you can exit. A cycle of Exit followed by another Exit or Enter by followed by another Enter is not allowed."
    }
  ];

  const opsList = [
    {
      id: "div12",
      quest: "Can I have more than one access card?",
      answer: "No, only one card can be assigned to an account."
    },
    {
      id: "div13",
      quest: "What do I do if I lose my card?",
      answer:
        "Contact the parking office to request a new card. There is a charge for a new card."
    },
    {
      id: "div14",
      quest: "Can I store my car in the garage?",
      answer:
        "No storage parking allowed. Parking is for commuters only and you must exit the garage after your shift ends."
    },
    {
      id: "div15",
      quest:
        "Can I park my car if I live in NYP housing or neighboring zip codes?",
      answer:
        "No. NYP housing residents are not allowed to use NYP parking. This restriction also includes employees living in neighboring zip codes."
    },
    {
      id: "div16",
      quest: "Is there space available for new monthly accounts?",
      answer:
        "No, new monthly accounts will be assigned to a remote location (offsite) that may require shuttle service."
    },
    {
      id: "div17",
      quest: "What do I do when my card does not open the gate?",
      answer:
        "Take a ticket as you enter, and before you leave, stop by the manager's office.Do not attempt to leave the garage until you have stopped by the parking office or the manager's office. Parking and Manager’s offices are located on the third floor of the garage (street level) next to the elevator"
    },
    {
      id: "div18",
      quest: "Can I park if I don't have a monthly account?",
      answer:
        "Yes. Important to note, however, that paublic parking spaces are reserved for patients and visitors."
    },
    {
      id: "div19",
      quest: "Does the garage accept SUVs and small vans?",
      answer:
        "Yes, but there is height restriction. Please remove all racks from your car’s roof before entering the garage."
    },
    {
      id: "div20",
      quest: "Does the garage accept shuttle size vans?",
      answer:
        "No. Large vans cannot be accommodated due to height limits and parking space sizes."
    },
    {
      id: "div21",
      quest: "If I take a ticket, where do I park?",
      answer:
        "If you get a ticket, you will be directed to park on the lot. You must drive down the ramp and the staff will valet your car. No self-parking allowed on the lot."
    },
    {
      id: "div22",
      quest: "Do you have discounted daily rates for employees?",
      answer:
        "	No. Employees who do not have an active monthly account must pay the full transient rate"
    }
  ];

  const FinalList = FacList.map((targetDivs, divId) => (
    <SouthProp key={targetDivs.id} targetDivs={targetDivs} divId={divId} />
  ));
  const OperationList = opsList.map((targetDivs) => (
    <SouthProp key={targetDivs.id} targetDivs={targetDivs} />
  ));

  var targetLocLat = 40.83986640840297 
  var targetLocLng = -73.94352001617837;

  return (
    <div style={{ backgroundColor: "white" }} id="topDiv">
      <div className="container-fluid">
        <div className="row">
          {/* <CardTemp
                        imgShow='false'
                        cardIcon={<FontAwesomeIcon icon={faParking} />}
                        imgTitle=' South Property Garage Address'
                        locAddress='115 Fort Washington Avenue New York NY 10032'
                        showName='false'
                        showLicense='false'
                        showAKA='false'
                        showOps='false'
                        showHours='false'
                        showEnt='false'
                        showExit='false'
                        showDetails='false'
                    />
                    <CardTemp
                        imgShow='false'
                        cardIcon={<FontAwesomeIcon icon={faParking} />}
                        imgTitle=' South Property Garage Also Known As'
                        showAddress='false'
                        showName='false'
                        showLicense='false'
                        aka=' Main or 165th Street Garage'
                        showOps='false'
                        showHours='false'
                        showEnt='false'
                        showExit='false'
                        showDetails='false'
                    />
                    <CardTemp
                        imgShow='false'
                        cardIcon={<FontAwesomeIcon icon={faParking} />}
                        imgTitle=' South Property Garage Hours'
                        showAddress='false'
                        showName='false'
                        showLicense='false'
                        showAKA='false'
                        locHours='24 Hours 7 Days'
                        showOps='false'
                        showEnt='false'
                        showExit='false'
                        showDetails='false'
                    />
                    <CardTemp
                        imgShow='false'
                        cardIcon={<FontAwesomeIcon icon={faParking} />}
                        imgTitle=' South Property Garage Entrance'
                        showAddress='false'
                        showName='false'
                        showLicense='false'
                        showAKA='false'
                        showHours='false'
                        showOps='false'
                        locEnt='Entrance is on Fort Washington Avenue between 165th and 164th Streets'
                        showExit='false'
                        showDetails='false'
                    />
                    <CardTemp
                        imgShow='false'
                        cardIcon={<FontAwesomeIcon icon={faParking} />}
                        imgTitle=' South Property Garage Exit'
                        showAddress='false'
                        showName='false'
                        showLicense='false'
                        showAKA='false'
                        showHours='24 Hours 7 Days'
                        showOps='false'
                        showEnt='false'
                        locExit='Fort Washington Avenue or Riverside. To exit onto Riverside, drive down to lowest level (level 1) and exit from the parking lot.'
                        showDetails='false'
                    /> */}
          <div>
            <LocGoogleMap locLat={targetLocLat} locLng={targetLocLng} />
          </div>
          <div>
            <table
              className="table table-striped table-bordered"
              style={{ border: "ridge",marginLeft:'-0px' }}
            >
              <thead>
                <tr>
                  <th id="thID" colSpan="2">
                    <span style={{ color: "blue" }}>
                      South Proeprty Garage - 115 Fort Washington Avenue New
                      York NY 10032{" "}
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    className="firsttd text-start"
                    style={{ fontWeight: "bold" }}
                  >
                    Garage Address:
                  </td>
                  <td className="text-start">
                    <span style={{ color: "blue" }}>
                      115 Fort Washington Avenue New York NY 10032{" "}
                    </span>
                  </td>
                </tr>

                <tr>
                  <td className="text-start" style={{ fontWeight: "bold" }}>
                    Garage Name:
                  </td>
                  <td className="text-start">
                    <span style={{ color: "blue" }}>
                      South Proeprty Garage{" "}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="text-start" style={{ fontWeight: "bold" }}>
                    Also Known As:
                  </td>
                  <td className="text-start">
                    <span style={{ color: "blue" }}>
                      South Proeprty Garage, Main Garage or 165th Street Garage
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="text-start" style={{ fontWeight: "bold" }}>
                    License:
                  </td>
                  <td className="text-start">
                    <span style={{ color: "blue" }}>
                      Public License. Accepts vendors, patinets and visitors.
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="text-start" style={{ fontWeight: "bold" }}>
                    Hours:
                  </td>
                  <td className="text-start">
                    <span style={{ color: "blue" }}>
                      Garage is open 24 hours 7 days a week
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="text-start" style={{ fontWeight: "bold" }}>
                    Entrance:
                  </td>
                  <td className="text-start">
                    <span style={{ color: "blue" }}>
                      Entrance is on Fort Washington Avenue between 165th and
                      164th Streets
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="text-start" style={{ fontWeight: "bold" }}>
                    Exits:
                  </td>
                  <td className="text-start">
                    <span style={{ color: "blue" }}>
                      Fort Washington Avenue or Riverside. To exit onto
                      Riverside, drive down to lowest level (level 1) and exit
                      from the parking lot.
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className="text-start" style={{ fontWeight: "bold" }}>
                    Operation Type:
                  </td>
                  <td className="text-start">
                    <span style={{ color: "blue" }}>Self-Park and Valet.</span>
                  </td>
                </tr>
                <tr>
                  <td className="text-start" style={{ fontWeight: "bold" }}>Contact:</td>
                  <td className="text-start">
                    <span style={{ color: "blue" }}>
                      (212) 305-2718
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <LocPageFooter list1={FinalList} />
        <LocAnswerFooter list1={OperationList} />
      </div>
    </div>
  );
}

export default LocAnswers;
