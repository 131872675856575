import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
//import { useState } from "react";
import { Typography } from '@mui/material';
import SouthProp from './SP';
//import LocalParkingSharpIcon from '@mui/icons-material/LocalParkingSharp';
//import { Key } from "@mui/icons-material";
// import BuildBootStrapMenu from "./DisplayMenu";
//import CreateButtons from "../components/MenuButtons";
// import * as Icon from 'react-bootstrap-icons';
import { LocalGroceryStore } from '@mui/icons-material';
import LocAnswerFooter from './AnswersFooter';
import LocGoogleMap from './LocGoogleMap';
import LocPageFooter from './PageFooter';

function LocAnswersCCH() {
	// <BuildBootStrapMenu />
	const FacList = [
		{
			id: 'div5',
			quest: 'How many floors?',
			answer: 'Two levels..'
		},
		{
			id: 'div18',
			quest: 'Does the garage have a passenger elevator?',
			answer: 'Yes. Accessed from the outside driveway.'
		},
		{
			id: 'div6',
			quest: 'Does the garage have gates at entrances and exits?',
			answer:
				'No. Patient and visitors cars are staged in the driveway and valet to the garage. Employees must drive down to the subcellar. Please follow posted signs.'
		},
		{
			id: 'div18',
			quest: 'What do I need to access the garage?',
			answer:
				'Cars will be issued an adhesive car identification tag that should be affixed to the rear driver side window. Staff will use a handheld device to read the barcode and issue a ticket.'
		},
		{
			id: 'div18',
			quest: 'How are the parking floors accessed?',
			answer: 'Ramp access. Cars are valet from the Hospital entrance.'
		},
		{
			id: 'div18',
			quest: 'What do I do when I arrive?',
			answer:
				'Drive down the ramp to the subcellar (follow the signs) and an attendant will issue you a ticket and park your car. Do not park in any open spaces on any floor'
		},

		{
			id: 'div21',
			quest: 'Does the garage have car stackers or lifts?',
			answer: 'Yes, lifts or stackers are used to park cars.'
		},
		{
			id: 'div9',
			quest: 'How do I retrieve my car?',
			answer:
				'Scan your ticket at the hospital lobby kiosk or take the elevator (accessible from the driveway outside the hospital) to the subcellar and hand the ticket to one of the valet employees. You can also text your ticket number to have your car ready to go.'
		},
		{
			id: 'div10',
			quest: 'Can I park my own car, or do I have to valet?',
			answer: 'No. The garage offers valet service only.'
		},
		{
			id: 'div10',
			quest: 'Can I choose where I park?',
			answer:
				'No. Valet staff parks the car based on space availability and departure time.'
		},

		{
			id: 'div8',
			quest: 'Who issues the Barcode tag?',
			answer: 'The parking office issues the access or VIP brooded tags.'
		}
	];

	const opsList = [
		{
			id: 'div14',
			quest:
				'Can I store my car in the garage (storage parking is parking a car in the garage for more than 24 hours)?',
			answer:
				'No. No storage parking at CCH garage. Garage is reserved for commuters only.'
		},

		{
			id: 'div14',
			quest: 'Can I drive in with a car other than the one with an RFID tag?',
			answer: 'Yes. The staff will issue a replacement ticket.'
		},
		{
			id: 'div15',
			quest:
				'Can I apply for parking if I live in NYP housing or in neighboring zip codes?',
			answer:
				'No. No. NYP housing residents are not allowed to use NYP parking. This restriction also includes employees living in neighboring zip codes. Contact the parking office regarding the restricted zip codes.'
		},

		{
			id: 'div16',
			quest: 'Is there space available for new monthly accounts?',
			answer: 'No. New applicants are placed on the waiting list.'
		},
		{
			id: 'div18',
			quest: "Can I park if I don't have a monthly account?",
			answer: 'Yes. You must take a ticket. Transient daily charges will apply.'
		},
		{
			id: 'div19',
			quest: 'Does the garage accept SUVs and small vans?',
			answer:
				"Yes, but there is height restriction. Please remove all racks from your car's roof before entering the garage."
		},
		{
			id: 'div20',
			quest: 'Does the garage accept shuttle size vans?',
			answer:
				'No. Large vans cannot be accommodated due to height limits and parking space sizes.'
		},
		{
			id: 'div22',
			quest: 'Do you have discounted daily rates for employees?',
			answer: 'No. Contact the garage or the parking office for rates.'
		}
	];

	// const [isShown, setIsShown] = useState(false, { id: 'divOne' });
	// const hideShowDiv = (e) => {
	//     setIsShown({ id: e.target.id }, !isShown);
	//     divName = e.target.id;
	//     console.log (divName);

	//  };
	const FinalList = FacList.map((targetDivs, divId) => (
		<SouthProp key={targetDivs.id} targetDivs={targetDivs} divId={divId} />
	));
	const OperationList = opsList.map((targetDivs) => (
		<SouthProp key={targetDivs.id} targetDivs={targetDivs} />
	));

	var targetLat = 40.66841821556038;
	var targetLng = -73.97883470269306;

	return (
		<div className="container" id="topDiv">
			<div className="container-fluid">
				<div>
					<Typography
						variant="h6"
						style={{ backgroundColor: 'white', textAlign: 'start' }}
						sx={{ marginTop: '2px', paddingTop: '2px', paddingBottom: '5px' }}
					>
						Click <a href="MethodistAnswersList">here</a> for information that
						applies to all Methodist Campus parking facilities.
					</Typography>
				</div>
				<div className="row">
					<div>
						<LocGoogleMap locLat={targetLat} locLng={targetLng} />
					</div>
					<div>
						<table
							className="table table-striped table-bordered"
							style={{ border: 'ridge', marginLeft: '-0px' }}
						>
							<thead>
								<tr>
									<th id="thID" colSpan="2">
										<span style={{ color: 'blue' }}>
											CCH Garage - 515 6th Street Brooklyn NY 11215
										</span>
									</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td
										className="firsttd text-start"
										style={{ fontWeight: 'bold' }}
									>
										Garage Address:
									</td>
									<td className="text-start">
										<span style={{ color: 'blue' }}>
											515 6th Street Brooklyn NY 11215
										</span>
									</td>
								</tr>
								<tr>
									<td className="text-start" style={{ fontWeight: 'bold' }}>
										Garage Name:
									</td>
									<td className="text-start">
										<span style={{ color: 'blue' }}>
											Center for Community Health Garage (CCH){' '}
										</span>
									</td>
								</tr>
								<tr>
									<td className="text-start" style={{ fontWeight: 'bold' }}>
										Also Known As:
									</td>
									<td className="text-start">
										<span style={{ color: 'blue' }}>CCH Garage</span>
									</td>
								</tr>
								<tr>
									<td className="text-start" style={{ fontWeight: 'bold' }}>
										License:
									</td>
									<td className="text-start">
										<span style={{ color: 'blue' }}>
											Public. Garage accepts patients and visitors.
										</span>
									</td>
								</tr>
								<tr>
									<td className="text-start" style={{ fontWeight: 'bold' }}>
										Hours:
									</td>
									<td className="text-start">
										<span style={{ color: 'blue' }}>
											24 hours 7 days a week accessible.
										</span>
									</td>
								</tr>
								<tr>
									<td className="text-start" style={{ fontWeight: 'bold' }}>
										Entrance:
									</td>
									<td className="text-start">
										<span style={{ color: 'blue' }}>
											Entrance on 6th Street between 7th and 8th Avenues.
										</span>
									</td>
								</tr>
								<tr>
									<td className="text-start" style={{ fontWeight: 'bold' }}>
										Exits:
									</td>
									<td className="text-start">
										<span style={{ color: 'blue' }}>
											Exit on 6th Street between 7th and 8th Avenues.
										</span>
									</td>
								</tr>
								<tr>
									<td className="text-start" style={{ fontWeight: 'bold' }}>
										Operation Type:
									</td>
									<td className="text-start">
										<span style={{ color: 'blue' }}>Valet.</span>
									</td>
								</tr>
								<tr>
									<td className="text-start" style={{ fontWeight: 'bold' }}>
										Contact:
									</td>
									<td className="text-start">
										<span style={{ color: 'blue' }}>(929) 470-9101</span>
									</td>
								</tr>
							</tbody>
						</table>
						<LocPageFooter list1={FinalList} />
						<LocAnswerFooter list1={OperationList} />
					</div>
				</div>
			</div>
		</div>
	);
}

export default LocAnswersCCH;
