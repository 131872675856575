import CardTemp from "./CardTemplate";
import lmhTabStyle from "../css/policiesStyle.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faParking } from "@fortawesome/free-solid-svg-icons";
library.add(faParking);

function SpruceTabContent() {
  return (
    <div className={lmhTabStyle.cardsDiv}>
      <div className={lmhTabStyle.homeCard}>
        <CardTemp
          cardLink="/SpruceAnswersList"
          imgSrc={require("../images/8 Spruce.jpg")}
          cardIcon={<FontAwesomeIcon icon={faParking} />}
          imgTitle=" Eight Spruce Street Garage"
          def1=<span className={lmhTabStyle.defSpan}>
            <span>Address: </span>
            <span style={{ color: "blue" }}>
              52 Beekman Street New York NY 10038
            </span>
          </span>
          def2=<span className={lmhTabStyle.defSpan}>
            <span>Hours: </span>
            <span style={{ color: "blue" }}>24 Hours 7 Days</span>
          </span>
          def3=<span className={lmhTabStyle.defSpan}>
            <span>Parking: </span>
            <span style={{ color: "blue" }}>Valet Only</span>
          </span>
        />
      </div>
      <br />
      <br />
    </div>
  );
}
export default SpruceTabContent;
