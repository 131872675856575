
import ProrateCalc from "./ProrateBiWeeklyResult";

function BiWeeklyResult(inDate,ratesData,campusName,loc,parkType) {
  var result;
  
  if (campusName === "Columbia") {
    result = ProrateCalc(
      inDate,
      ratesData,
      campusName,
      loc,
      parkType
    );
  } else if (campusName === "Cornell") {
    result = ProrateCalc(
      inDate,
      ratesData,
      campusName,
      loc,
      parkType
    );
  } else if (campusName === "Methodist") {
    result = ProrateCalc(
      inDate,
      ratesData,
      campusName,
      loc,
      parkType
    );
  } else if (campusName === "Lower Manhattan") {
     result = ProrateCalc(
      inDate,
      ratesData,
      campusName,
      loc,
      parkType
    );
  } else if (campusName === "Allen") {
     result = ProrateCalc(
      inDate,
      ratesData,
      campusName,
      loc,
      parkType
    );
  }

  return result;
}
export default BiWeeklyResult
