import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import {Modal,Button} from 'react-bootstrap'
import { useState } from "react";
import RateStructure from "./RateCalculator";
// import ColunbiaRatesList from "./ColumbiaDailyRates";
import ColumbiaRateTableData from "./ColumbiaRateTableData.json";
import CornellRateTableData from './CornellRateTableData.json';
//import CornellDailyRatesList from "./CornellDailyRates";
//import MethodistRatesList from "./MethodistDailyRates";
//import SpruceDailyRatesList from "./SpruceDailyRates";
// import LowerManhattanBasicTable from "./LowerManhattanRateTable";
import MethodistRateTableData from "./MethodistRateTableData.json";
import LowerManhattanRateTableData from './LowerManhattanRateTableData.json'
import AllenRateTableData from './AllenRateTableData.json'

const CalcUI  = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    var curr = new Date();
    curr.setDate(curr.getDate())
    var currDate = curr.toISOString().substring(0, 10)

    var inTime = new Date();
    inTime.setTime(inTime.getTime()) 
    var outTime = new Date()
    outTime.setTime(outTime.getTime() + 1000*60*120) //2 hours
    // console.log(inTime.toLocaleTimeString())
    var currTime = inTime.toTimeString().substring(0,5);
    var currOutTime = outTime.toTimeString().substring(0,5)
    

    return (
        <div className="container col">
            <Button variant="primary" onClick={handleShow} style={{marginLeft:'10px'}}>
                Launch Daily Rate Calculator
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>Daily Parking Fee Calculator</Modal.Title>
                    <button type="button" className="btn-close" onClick={handleClose} aria-label="Close"></button>
                </Modal.Header>
                <Modal.Body>
                    <div className="row input-group mb-3">
                        <div className="col input-group mb-3 col">
                            {/* <label for='loc' className="form-label px-3">Location Name: </label> */}
                            <span className="input-group-text" id="basic-addon1">Lcoation</span>
                            <select className="form-select float-start" id="campus" name="campus" placeholder="Campus" aria-label="Select Campus">
                                <option value='1'>Columbia</option>
                                <option value='2'>Cornell</option>
                                <option value='3'>Methodist</option>
                                <option value='4'>Lower Manhattan</option>
                                <option value='5'>Allen Hospital</option>
                            </select>
                        </div>
                        <div>
                            <div className="input-group mb-3 col">
                                {/* <label for='daeteIn' className="px-3 form-label">Date In </label> */}
                                <span className="input-group-text" id="basic-addon1">Date In</span>
                                <input className="form-control float-start text-start" type="date" defaultValue={currDate} id="dateIn" name='dateIn' />
                            </div>
                            <div className="col input-group mb-3 col">
                                {/* <label for='timeIn' className="form-label px-3">Time In </label> */}
                                <span className="input-group-text" id="basic-addon1">Time In</span>
                                <input className="form-control float-start text-start" type="time" id="timeIn" defaultValue={currTime} name='timeIn'/>
                            </div>
                        </div>
                        <div>
                            <div className="col input-group mb-3 col">
                                {/* <label for='dateOut' className="form-label px-3">Date Out </label> */}
                                <span className="input-group-text" id="basic-addon1">Date Out</span>
                                <input className="form-control float-start text-start" type="date" defaultValue={currDate} id="dateOut" name='dateOut' />
                            </div>
                            <div className="col input-group mb-3 col">
                                {/* <label for='timeOut' className="form-label px-3">Time Out </label> */}
                                <span className="input-group-text" id="basic-addon1">Time Out</span>
                                <input className="form-control float-start text-start" type="time" defaultValue={currOutTime} id="timeOut" name='timeIn' />
                            </div>
                        </div>
                        <div className="col input-group mb-3" >
                            <textarea className='form-control float-start text-start' rows={5} id='result' name="result" disabled></textarea>
                        </div>  
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" style={{marginRight:'30px'}} onClick={() =>{
                        var d1 = new Date(document.getElementById('dateIn').value + ' ' + document.getElementById('timeIn').value)
                        // var t1 = document.getElementById('timeIn').value
                        var d2 = new Date(document.getElementById('dateOut').value + ' ' + document.getElementById('timeOut').value)
                        // var t2 = document.getElementById('timeOut').value
                        var campusName = document.getElementById('campus').value
                        console.log(campusName)
                        if (campusName === "1") {
                          document.getElementById("result").value =
                            RateStructure(d2, d1, ColumbiaRateTableData);
                        } else if (campusName === "2") {
                          document.getElementById("result").value =
                            RateStructure(d2, d1, CornellRateTableData);
                        } else if (campusName === "3") {
                          document.getElementById("result").value =
                            RateStructure(d2, d1, MethodistRateTableData);
                        } else if (campusName === "4") {
                          document.getElementById("result").value =
                            RateStructure(d2, d1, LowerManhattanRateTableData);
                        } else if (campusName === "5") {
                          document.getElementById("result").value =
                            RateStructure(d2, d1, AllenRateTableData);
                        }
                        
                        }}>
                        Calculate
                    </Button>            
                </Modal.Footer>
            </Modal>
        </div>
    );
} 

export default CalcUI;