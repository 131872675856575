import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faTicket, faAdd,faPlusCircle,faMinusCircle, faTable, faTicketAlt } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
library.add(faTicket,faAdd,faMinusCircle,faTable)

function Applications(){
    return (
        <div className="row container">
            <div className="card" style={{ width: '17rem', marginLeft: '15px', marginTop: '10px', alignItems: 'center' }}>
                <img className='card-img-top' src={require('../images/NewMonthly.jpg')} alt='Apply' style={{width:'170px', height:'209px'}}/>
                <div className='card-body'>
                    <h6 className='card-title' style={{ textAlign: 'center' }}>Monthly Parking Account Application</h6>
                    <p className='card-text'>
                        <button className='btn btn-primary'
                            onClick={() => { window.open('https://app.smartsheet.com/b/form/481e30a27a234286a8d952537fba8e39') }}>
                            <FontAwesomeIcon icon={faPlusCircle} /> <span> Click Here for Monthly Parking Account Application</span>
                        </button>
                    </p>
                </div>
            </div>
            <div className="card" style={{ width: '17rem', marginLeft: '15px', marginTop: '10px', alignItems: 'center' }}>
                <img className='card-img-top' src={require('../images/ActCancellationQR.png')} alt='Cancel' style={{ width: '175px', height: '200px',marginTop:'10px' }} />
                <div className='card-body'>
                    <h6 className='card-title' style={{textAlign:'center'}}>Monthly Parking Cancellation Form</h6>
                    <p className='card-text'>
                        <button className='btn btn-primary'
                            onClick={() => { window.open('https://app.smartsheet.com/b/form/6fe537dde0794a30ac39536e0ccd3a5a') }}>
                            <FontAwesomeIcon icon={faMinusCircle} /> <span> Click Here to Cancel Your Monthly Parking Account</span>
                        </button>
                    </p>
                </div>
            </div>
            <div className='card' style={{ width: '17rem', marginLeft: '15px', marginTop: '10px', alignItems: 'center' }} >
                <img className='card-img-top' src={require('../images/ActUpdateQR.png')} alt='Update' style={{width: '175px', height: '200px', marginTop: '10px'}} />
                <div className='card-body'>
                    <h6 className='card-title' style={{ textAlign: 'center' }}>Monthly Account Information Update Form</h6>
                    <p className='card-text'>
                        <button className='btn btn-primary' onClick={() => { window.open("https://app.smartsheet.com/b/form/3e25fe741ade4a9587749f1c9c87cdae")}}>
                            <FontAwesomeIcon icon={faTable} /> <span> Click Here to Update Your Monthly Account Infomation</span>
                        </button>
                    </p>
                </div>
            </div >
            <div style={{ width: '17rem', marginLeft: '15px', marginTop: '10px', alignItems: 'center' }}  className='card' >
                <img src={require('../images/OnlineVals.jpg')} alt='Online Vals' style={{ width: '175px', height: '200px', marginTop: '10px' }} />
                <div className='card-body'>
                    <h6 className='card-title' style={{ textAlign: 'center' }}>Online Validations Account Application</h6>
                    <p className='card-text'>
                        <button className='btn btn-primary' onClick={() => { window.open('https://app.smartsheet.com/b/form/aea4ebb8e6774824b8567b2a38edcf48')}}>
                            <FontAwesomeIcon icon={faTicket} /> <span> Click Here for an Online Validation Account Application</span>
                        </button>
                    </p>
                </div>
            </div>
            <div className='card' style={{ width: '17rem', marginLeft: '15px', marginTop: '10px', alignItems: 'center' }} >
                <img src={require('../images/PaperVals.jpg')} alt='Paper Voucher' style={{ width: '175px', height: '200px', marginTop: '10px' }} />
                <div className='card-body'>
                    <h6 className='card-titel' style={{ textAlign: 'center' }}>Emergency Paper Validations Request</h6>
                    <p className='card-text'>
                        <button className='btn btn-primary' onClick={() => { window.open('https://app.smartsheet.com/b/form/236bb164d0014a7a902b0ceddeddd725')}}>
                            <FontAwesomeIcon icon={faTicketAlt} /><span> Clcik Here for Emergency Paper Vouchers Request</span>
                        </button>
                        
                    </p>
                </div>
            </div>
        </div>
    )    
}
export default Applications ;