import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
//import { useState } from "react";
import SouthProp from './SP';
// import { Typography } from "@mui/material";
//import LocalParkingSharpIcon from '@mui/icons-material/LocalParkingSharp'; 
//import { Key } from "@mui/icons-material";
// import BuildBootStrapMenu from "./DisplayMenu";
//import CreateButtons from "../components/MenuButtons";
// import * as Icon from 'react-bootstrap-icons';
import LocPageFooter from "./PageFooter";
import LocAnswerFooter from "./AnswersFooter";
import LocGoogleMap from "./LocGoogleMap";

// function HideQuestions() {
//     var hideDiv = document.getElementById('questsDiv');
//     if (hideDiv.style.display === 'none') {
//         hideDiv.style.display = 'block';
//     } else {
//         hideDiv.style.display = 'none';
//     }
// }

// function HideQuestions2() {
//     var hideDiv2 = document.getElementById('questsDiv2');
//     if (hideDiv2.style.display === 'none') {
//         hideDiv2.style.display = 'block';
//     } else {
//         hideDiv2.style.display = 'none';
//     }
// }

function LocAnswersSpruce() {
    //<BuildBootStrapMenu />
    const FacList = [

        {
            id: 'div5',
            quest: 'How many floors?',
            answer: 'One level.'
        },
        {
            id: 'div18',
            quest: 'Does the garage have a passenger elevator?',
            answer: 'Yes, but not accessible to the public.'
        },
        {
            id: 'div6',
            quest: 'Does the garage have gates at entrances and exits?',
            answer: 'No. Cars are staged in the driveway and valet to the garage.'
        },
        {
            id: 'div18',
            quest: 'What do I need to access the garage?',
            answer: 'Cars will be issued an adhesive RFID tag that should be affixed to the rear-view mirror. The tag will be read on entry and exit.'
        },
        {
            id: 'div18',
            quest: 'How are the parking floors accessed?',
            answer: 'Ramp access. Cars are valet from the Hospital entrance.'
        },
        {
            id: 'div18',
            quest: 'What do I do when I arrive?',
            answer: 'Enter the garage staging plaza (intersecion of Beekman and Williams Streets) and the parking staff will issue a ticket and valet your car. Please wait for the ticket to be issued and don\'t leave your car unattended.'
        },

        {
            id: 'div21',
            quest: 'Does the garage have car stackers or lifts?',
            answer: 'Yes, lifts or stackers are used to park cars.'
        },
        {
            id: 'div9',
            quest: 'How do I retrieve my car?',
            answer: 'Scan your ticket at the kiosk located in the garage office lobby or hand your ticket to one of the valet staff. Do not walk down to the Garage.'
        },
        {
            id: 'div10',
            quest: 'Can I park my own car, or do I have to valet?',
            answer: 'No. The garage offers valet service only.'
        },
        {
            id: 'div10',
            quest: 'Can I choose where I park?',
            answer: 'No. Valet staff parks the car based on space availability and departure time.'
        },

        {
            id: 'div8',
            quest: 'Who issues the RFID tag?',
            answer: 'Parking manager issues the RFID tags. Please note that monthly accounts are reserved for 8 Spruce Street building residents. All other accounts must be approved by the Parking Operation Director.'
        }
    ]

    const opsList = [

        {
            id: 'div14',
            quest: 'Can I store my car in the garage (storage parking is parking a car in the garage for more than 24 hours)?',
            answer: 'Storage parking is available exclusively to residents of the 8 Spruce Street Building. Hospital employees who have been granted permission to open a monthly account are not allowed to store their vehicles. After their work shift ends, all employees must leave the garage.'
        },
        {
            id: 'div15',
            quest: 'Can I apply for parking if I live in neighboring zip codes?',
            answer: 'The garage is reserved for residents of the 8 Spruce Street building, patients, their families, and visitors. The parking operation director must approve all other monthly accounts.'
        },

        {
            id: 'div16',
            quest: 'Is there space available for new monthly accounts?',
            answer: 'To sign up for a monthly account, you must be a resident of 8 Spruce Street building. The parking operation director must approve all other monthly accounts.'
        },
        {
            id: 'div18',
            quest: 'Can I park if I don\'t have a monthly account?',
            answer: 'Yes. You must take a ticket. Transient daily charges will apply.'
        },
        {
            id: 'div19',
            quest: 'Does the garage accept SUVs and small vans?',
            answer: 'Yes, but there is height restriction. Please remove all racks from your car\'s roof before entering the garage.'
        },
        {
            id: 'div20',
            quest: 'Does the garage accept shuttle size vans?',
            answer: 'No. Large vans cannot be accommodated due to height limits and parking space sizes.'
        },
        {
            id: 'div22',
            quest: 'Do you have discounted daily rates for employees?',
            answer: 'Yes. Contact the garage or the parking office for rates.'
        },
    ]

    // const [isShown, setIsShown] = useState(false, { id: 'divOne' });
    // const hideShowDiv = (e) => {
    //     setIsShown({ id: e.target.id }, !isShown);
    //     divName = e.target.id;
    //     console.log (divName);

    //  };
    const FinalList = FacList.map((targetDivs, divId) => (
        <SouthProp key={targetDivs.id} targetDivs={targetDivs} divId={divId} />
    ));
    const OperationList = opsList.map((targetDivs) => (
        <SouthProp key={targetDivs.id} targetDivs={targetDivs} />
    ));

    var targetLat = 40.710589156332325;
    var targetLng = -74.00555135851165;

    return (
      <div className="container row" id="topDiv">
        {/* <div className="container-fluid">
          <div className="row"> */}
          <div>
            <LocGoogleMap locLat={targetLat} locLng={targetLng} />
          </div>
            <div>
              <table
                className="table table-striped table-bordered"
                style={{ border: "ridge",marginLeft:'-0px' }}
              >
                <thead>
                  <tr>
                    <td className="firsttd" style={{ fontWeight: "bold" }}>
                      Garage Address:
                    </td>
                    <td className="text-start">
                      <span style={{ color: "blue" }}>
                        8 Spruce Street New York NY 10038. For GPS, enter 37
                        Beekman Street or Beekman and Williams Streets
                        intersecion.
                      </span>
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>Garage Name:</td>
                    <td className="text-start">
                      <span style={{ color: "blue" }}>8 Spruce Garage </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>Also Known As:</td>
                    <td className="text-start">
                      <span style={{ color: "blue" }}>
                        Spruce Street Garage or LMH Garage
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>License:</td>
                    <td className="text-start">
                      <span style={{ color: "blue" }}>
                        Public. Garage accepts patients and visitors.
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>Hours:</td>
                    <td className="text-start">
                      <span style={{ color: "blue" }}>
                        24 hours 7 days a week.
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>Entrance:</td>
                    <td className="text-start">
                      <span style={{ color: "blue" }}>
                        Entrance on Beekman Street (intersecion of Beekman and
                        Williams Streets). .
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>Exits:</td>
                    <td className="text-start">
                      <span style={{ color: "blue" }}>
                        Exit on Beekman Street (intersecion of Beekman and
                        Williams Streets).
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>Operation Type:</td>
                    <td className="text-start">
                      <span style={{ color: "blue" }}>Valet.</span>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "bold" }}>Contact:</td>
                    <td className="text-start">
                      <span style={{ color: "blue" }}>
                        (212) 602-9980 or (212) 602-9981
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <LocPageFooter list1={FinalList} />
              <LocAnswerFooter list1={OperationList} />
            </div>
          {/* </div>
        </div> */}
      </div>
    );
}

export default LocAnswersSpruce;