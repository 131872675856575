import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
//import { useState } from "react";
import SouthProp from './SP';
import { Typography } from "@mui/material";
//import LocalParkingSharpIcon from '@mui/icons-material/LocalParkingSharp'; 
//import { Key } from "@mui/icons-material";
// import BuildBootStrapMenu from "./DisplayMenu";
//import CreateButtons from "../components/MenuButtons";
// import * as Icon from 'react-bootstrap-icons';
import LocPageFooter from "./PageFooter";
import LocAnswerFooter from "./AnswersFooter";
import LocGoogleMap from "./LocGoogleMap";
import { Link } from 'react-router-dom'


function LocAnswersDHK (){
    // <BuildBootStrapMenu />
    const FacList = [
    
         {
            id: 'div5',
            quest: 'How many floors?',
            answer: 'One level.'
        },
        {
            id: 'div18',
            quest: 'Does the garage have a passenger elevator?',
            answer: 'No.'
        },
        {
            id: 'div6',
            quest: 'Does the garage have gates at entrances and exits?',
            answer: 'No. Cars are staged in the driveway and valet to the garage.'
        },
        {
            id: 'div18',
            quest: 'What do I need to access the garage?',
            answer: 'All cars must take transient tickets. Daily rates apply. This garage is for patients and visitors only.'
        },
        {
            id: 'div18',
            quest: 'How are the parking floors accessed?',
            answer: 'Ramp access. Cars are valet from the Hospital entrance.'
        },
        {
            id: 'div18',
            quest: 'What do I do when I arrive?',
            answer: 'Drive to the valet lane across from DHK Hospital entrance and the parking staff will issue a ticket and valet your car. Please wait for the ticket to be issued and don\'t leave your car unattended.'
        },
        
        {
            id: 'div21',
            quest: 'Does the garage have car stackers or lifts?',
            answer: 'Yes, lifts or stackers are used to park cars.'
        },
        {
            id: 'div9',
            quest: 'How do I retrieve my car?',
            answer: 'Scan your ticket at the kiosk located in the hospital lobby or hand your ticket over to one of the valet staff. Do not walk down to the Garage.'
        },
        {
            id: 'div10',
            quest: 'Can I park my own car, or do I have to valet?',
            answer: 'No. The garage offers valet service only.'
        },
        {
            id: 'div10',
            quest: 'Can I choose where I park?',
            answer: 'No. Valet staff parks the car based on space availability and departure time.'
        },
    
        {
            id: 'div8',
            quest: 'Who issues the Barcode tag?',
            answer: 'Not applicable. No monthly (employee) parking allowed.'
        }
    ]

    const opsList = [
        
        {
            id: 'div14',
            quest: 'Can I store my car in the garage (storage parking is parking a car in the garage for more than 24 hours)?',
            answer: 'No. No storage parking at DHK garage. Select Cornell Campus from the top menu and click Cornell Campus Garage General Information for assistance.'
        },
        {
            id: 'div15',
            quest: 'Can I apply for parking if I live in neighboring zip codes?',
            answer: 'No. Garage is for patients, their families and visitors only.'
        },
    
        {
            id: 'div16',
            quest: 'Is there space available for new monthly accounts?',
            answer: 'No. Garage is for patients, their families and visitors only.'
        },
        {
            id: 'div18',
            quest: 'Can I park if I don\'t have a monthly account?',
            answer: 'Yes. You must take a ticket. Transient daily charges will apply.'
        },
        {
            id: 'div19',
            quest: 'Does the garage accept SUVs and small vans?',
            answer: 'Yes, but there is height restriction. Please remove all racks from your car\'s roof before entering the garage.'
        },
        {
            id: 'div20',
            quest: 'Does the garage accept shuttle size vans?',
            answer: 'No. Large vans cannot be accommodated due to height limits and parking space sizes.'
        },  
        {
            id: 'div22',
            quest: 'Do you have discounted daily rates for employees?',
            answer: 'No. Contact the garage or the parking office for rates.'
        },
    ]
        
    const FinalList = FacList.map((targetDivs,divId) => (
        <SouthProp key={targetDivs.id}  targetDivs={targetDivs} divId={divId} />
    ));
    const OperationList = opsList.map((targetDivs) => (
        <SouthProp key={targetDivs.id} targetDivs={targetDivs} />
    ));

    var targetLat = 40.7651084866064;
    var targetLng = -73.95564420268913;

    return (
      <div style={{ backgroundColor: "white" }} id="topDiv">
        <div className="container-fluid">
          <div>
            <Typography
              variant="h6"
              style={{ backgroundColor: "white", textAlign: "start" }}
              sx={{ marginTop: "2px", paddingTop: "2px", paddingBottom: "5px" }}
            >
              Click <Link to="/CornellAnswersList">here</Link> for information
              that applies to all Cornell Campus parking facilities.
            </Typography>
          </div>
          <div className="row">
            <div>
              <LocGoogleMap locLat={targetLat} locLng={targetLng} />
            </div>
            <div>
              <table
                className="table table-striped table-bordered"
                style={{ border: "ridge", marginLeft: "-0px" }}
              >
                <thead>
                  <tr>
                    <th id="thID" colSpan="2">
                      <span style={{ color: "blue" }}>
                        David H. Koch (DHK) Garage - 1283 York Avenue New York
                        NY 10065
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="text-start" style={{ fontWeight: "bold" }}>
                      Garage Address:
                    </td>
                    <td className="text-start">
                      <span style={{ color: "blue" }}>
                        1283 York Avenue New York NY 10065. For those using GPS,
                        enter 444 East 69th Street.
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-start" style={{ fontWeight: "bold" }}>
                      Garage Name:
                    </td>
                    <td className="text-start">
                      <span style={{ color: "blue" }}>
                        David H. Koch Garage{" "}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-start" style={{ fontWeight: "bold" }}>
                      Also Known As:
                    </td>
                    <td className="text-start">
                      <span style={{ color: "blue" }}>
                        DHK Garage or DHK Valet
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-start" style={{ fontWeight: "bold" }}>
                      License:
                    </td>
                    <td className="text-start">
                      <span style={{ color: "blue" }}>
                        Public. Garage accepts patients and visitors.
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-start" style={{ fontWeight: "bold" }}>
                      Hours:
                    </td>
                    <td className="text-start">
                      <span style={{ color: "blue" }}>
                        24 hours 7 days a week.
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-start" style={{ fontWeight: "bold" }}>
                      Entrance:
                    </td>
                    <td className="text-start">
                      <span style={{ color: "blue" }}>
                        Entrance on 69th Street between York and 1st Avenue.
                        Cars are valet from the hospital entrance.
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-start" style={{ fontWeight: "bold" }}>
                      Exits:
                    </td>
                    <td className="text-start">
                      <span style={{ color: "blue" }}>
                        Exit on 68th or 69th Streets between York and 1st
                        Avenue. 68th Street exit heading East. 69th Street exit
                        heading West.
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-start" style={{ fontWeight: "bold" }}>
                      Operation Type:
                    </td>
                    <td className="text-start">
                      <span style={{ color: "blue" }}>Valet.</span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-start" style={{ fontWeight: "bold" }}>
                      Contact:
                    </td>
                    <td className="text-start">
                      <span style={{ color: "blue" }}>(212) 746-1866</span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <LocPageFooter list1={FinalList} />
              <LocAnswerFooter list1={OperationList} />
            </div>
          </div>
        </div>
      </div>
    );
}

export default LocAnswersDHK;