import React from "react";
import { Paper } from "@mui/material";
import { Link } from "react-router-dom";
import cardStyle from '../css/cardTemplateStyle.module.css'

function CardTemp(props) {
  return (
    <div
      id="homecard"
    >
      <Link to={props.cardLink} className={cardStyle.cardLink}>
        <img
          className="card-img-top"
          src={props.imgSrc}
          alt={props.imgAlt}
          hidden={props.imgShow}
          style={{ marginTop: "5px"}}
        />
        {/* <div className={cardStyle.cardBody}> */}
          <div
            className={cardStyle.cardTitle}
          >
            <h5 hidden={props.imgShow}>
              {props.cardIcon}
            </h5>
            <h5 hidden={props.showTitle}>
              {props.imgTitle}
            </h5>
          {/* </div> */}
          <div className={cardStyle.cardText}>
            <Paper className={cardStyle.locPaper} hidden={props.paperShow}>
              {props.answerIcon}
              {props.def1} <br />
              {props.def2} <br />
              {props.def3} <br />
              {props.def4} <br />
            </Paper>
          </div>
        </div>
      </Link>
    </div>
  );
}
export default CardTemp;
