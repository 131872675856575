
function CreateLocSelect(targetVal, targetList,targetId) {
  //Add items to array first and then filter so tha only unique items are added to the dropdown
  var optionsArray = [];

  var uniqueItemsSet = new Set();
  //Loop through the list and add locations that fit the condition below in the if statement
  for (let i = 0; i < targetList.length; i++) {
    if (targetList[i].campus === targetVal && targetList[i].los === "M") {
      //  optionsArray[i]=targetList[i].locations
      uniqueItemsSet.add(targetList[i].locations);
    }
  }
  //Use ES6 Set method to create a unique items in dropdown. I USED add method of Set after declaring new Set
  // var uniqueItems = [...new Set(optionsArray)];
  //The values() method returns an Iterator object containing all the values in a Set
  optionsArray = uniqueItemsSet.values();

  //  console.log(uniqueItemsSet,optionsArray)

  //Create this var if you are creating select from scratch
  // var myParent = document.body;

  //Create and append select list
  // var selectList = document.createElement("select");
  var selectList = document.getElementById(targetId);
  // selectList.id = "parktype";
  //myParent.appendChild(selectList);

  //Clear select options
  document.getElementById(targetId).innerHTML = "";

  //Create and append options
  for (const entry of optionsArray) {
    //if (targetList[i].campus === targetVal && targetList[i].los === 'M') {
    //console.log(entry);
    var optionList = document.createElement("option");
    optionList.value = entry;
    optionList.text = entry;
    selectList.appendChild(optionList);
    //}
  }
}
export default CreateLocSelect;
